import {
  Discord,
  Facebook,
  Instagram,
  Reddit,
  Tiktok,
  Twitch,
  Twitter,
} from "@styled-icons/fa-brands";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { rocket } from "../assets/images";
import { Button } from "../components/Button";
import { Card } from "../components/Card";
import { Checkbox } from "../components/Checkbox";
import { Form } from "../components/Form";
import { Icon } from "../components/Icon";
import { Image } from "../components/Image";
import { Link } from "../components/Link";
import { Page } from "../components/Page";
import { Text } from "../components/Text";
import { TextInput } from "../components/TextInput";
import { Toast } from "../components/Toast";
import { View } from "../components/View";
import { Dimensions } from "../contexts";
import { Auth } from "../contexts/AuthProvider";
import { Firestore } from "../contexts/FirestoreProvider";
import { validateBirthdate } from "../helpers/Validation";

export function Dashboard(props: any) {
  const {
    currentUser,
    sendEmailVerification,
    error,
    success,
    setSuccess,
    earlyAdopter
  } = Auth();
  const {
    accountSetupNeeded,
    currentUserProfile,
    updateCurrentUserProfile,
  } = Firestore();
  const { width } = Dimensions();
  const history = useHistory();
  const [showSuccess, setShowSuccess] = useState<boolean | null>(false);
  const [activeTab, setActiveTab] = useState("gm");
  const [verificationDisabled, setVerificationDisabled] = useState(true);
  const [emailProvider, setEmailProvider] = useState<boolean>(false);
  const [imageAsFile, setImageAsFile] = useState(null);



  const handleImageAsFile = (e: any) => {
    const image = e.target.files[0];
    setImageAsFile((imageFile) => image);
  };



  const [actionText, setActionText] = useState("Send Verification Email");

  useEffect(() => {
    if (currentUser.emailVerified) {
      setVerificationDisabled(false);
    }
  }, [currentUser.emailVerified]);

  const closeSuccess = () => {
    setShowSuccess(false);
    setSuccess(null);
  };


  useEffect(() => {
    if (
      success &&
      success.code === "auth/email-sent" &&
      actionText === "Send Verification Email"
    ) {
      setActionText("Resend Verification Email");
    }
  }, [success]);

  useEffect(() => {
    if (accountSetupNeeded) {
      history.push("/accountsetup");
    }
  }, [accountSetupNeeded]);




  return (
    <Page pageTitle="Dashboard | CRAWLR" pageDescription="Dashboard">
      {success ? (
        <Toast type="success" background="success">
          {success.message}
        </Toast>
      ) : null}
      {error ? (
        <Toast type="error" background="error">
          {error.message}
        </Toast>
      ) : null}
      {!currentUser.emailVerified ? (
        <Toast
          type="Notification"
          actionText={actionText}
          close={false}
          action={true}
          onAction={() => sendEmailVerification(currentUser.email)}
          background="primary"
        >
          Email Verification Required
        </Toast>
      ) : null}

     
        {earlyAdopter ?<View>
          <View padding="3vh 0 0 0">
            <Image
              width="200px"
              src={rocket}
              minWidth="200px"
              alt="Hero image. Little cartoon characters surrounding a phone under construction."
            />
          </View>

          <View padding="3vh 0 0 0" flexFlow="row wrap" justifyContent="center">
            <Text
              fontFamily="Brandon Grotesque"
              fontWeight="700"
              fontSize="1.75rem"
              color="body"
              textAlign="center"
            >
              Thank you for becoming an
            </Text>
            <Text
              padding="0 0 0 .5vw"
              fontFamily="Brandon Grotesque"
              fontWeight="700"
              fontSize="1.75rem"
              gradient
              color="primaryGradient"
              textAlign="center"
            >
              Early Adopter
            </Text>
            <Text
              fontFamily="Brandon Grotesque"
              fontWeight="700"
              fontSize="1.75rem"
              color="body"
              textAlign="center"
            >
              !
            </Text>
          </View>

          <View
            padding="3vh 0 0 0"
            minWidth="275px"
            width="30%"
            margin="0 auto"
            flexFlow="column wrap"
            justifyContent="center"
          >
            <Text textAlign="center" color="body" padding="1vh 0 1vh 0">
              Your support is what makes CRAWLR possible!
            </Text>
            <Text textAlign="center" color="body" padding="1vh 0 1vh 0">
              As a way of showing our appreciation, all Early Adopters will
              receive a full year of our ad-free experience upon release!
            </Text>
            <Text textAlign="center" color="body" padding="1vh 0 1vh 0">
              Follow us on Twitter and our Ko-Fi for exclusive release updates.
            </Text>
          </View>

          <View
            flexFlow="row wrap"
            padding="2vh 0 2vh 0"
            justifyContent="center"
          >
            <Link
              textAlign="center"
              background="primary"
              linkType="fill"
              href="https://ko-fi.com/crawlrapp/posts"
            >
              Visit our Ko-fi
            </Link>
          </View>

          <View
            minWidth="275px"
            width="30%"
            margin="0 auto"
            flexFlow="row wrap"
            padding="3vh 0 0 0"
            justifyContent="space-around"
          >
            <Icon
              size="2rem"
              color="primary"
              to="https://www.facebook.com/crawlrapp/"
            >
              <Facebook href="https://www.facebook.com/crawlrapp/" />
            </Icon>
            <Icon
              size="2rem"
              color="primary"
              to="https://www.instagram.com/crawlr_app/"
            >
              <Instagram />
            </Icon>
            <Icon
              size="2rem"
              color="primary"
              to="https://twitter.com/joincrawlr"
            >
              <Twitter />
            </Icon>
            <Icon
              size="2rem"
              color="primary"
              to="https://discord.gg/YAvcDUJCpk"
            >
              <Discord />
            </Icon>
            <Icon
              size="2rem"
              color="primary"
              to="https://www.reddit.com/r/CRAWLR/"
            >
              <Reddit />
            </Icon>
            <Icon
              size="2rem"
              color="primary"
              to="https://www.twitch.tv/crawlrapp"
            >
              <Twitch />
            </Icon>
            <Icon
              size="2rem"
              color="primary"
              to="https://www.tiktok.com/@crawlr"
            >
              <Tiktok />
            </Icon>
          </View>
        </View> : null}
    </Page>
  );
}

import React, { useState, useEffect, useContext } from "react";
import { View } from "./View";
import { Helmet } from "react-helmet";

interface PageProps {
  flex?: string;
  justifyContent?: string;
  padding?: string;
  margin?: string;
  background?: string;
  id?: string;
  width?: string;
  onClick?: () => void;
  maxWidth?: string;
  minWidth?: string;
  zIndex?: number;
  position?: string;
  borderWidth?: string;
  borderColor?: string;
  cursor?: string;
  to?: string;
  borderRadius?: string;
  height?: string;
  color?: string;
  alignItems?: string;
  top?: number;
  left?: number;
  right?: number;
  style?: any;
  flexFlow?: string;
  border?: string;
  type?: string;
  borderBottom?: string;
  borderTop?: string;
  borderLeft?: string;
  borderRight?: string;
  display?: string;
  pageTitle: string;
  pageDescription: string;
}

export const Page: React.FC<PageProps> = ({
  pageTitle,
  pageDescription,
  children,
  ...rest
}) => {

  return (
    <View padding="75px 0 0 0">
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageTitle} />
      </Helmet>
      {children}
    </View>
  );
};

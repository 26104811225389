// src/components/Navbar/index.tsx
import React from "react";
import styled from "styled-components";
import { View } from "./View";
import { Text } from "./Text";
import { Link } from "./Link";

type CheckboxType = {
  color?: string;
};

const CheckboxContainer = styled.div<CheckboxType>`
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  @import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);
  input + label {
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0px;
      width: 20px;
      height: 20px;
      border: 1px solid #aaa;
      background: white;
      border-radius: 5px;
      box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3);
    }
    &:after {
      font-family: FontAwesome;
      content: "\f00c";
      font-weight: 900;
      font-size: 20px;
      line-height: 20px;
      margin: 0 auto;
      top: 1px;
      left: 1px;
      color: white;
      transition: all 0.2s;
      position: absolute;
      width: 20px;
      height: 20px;
      text-align: center;
      background: ${(props) => props.theme.standardColors.success};
      border-radius: 5px;
      box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3);
    }
  }
  input:not(:checked) + label {
    &:after {
      opacity: 0;
      transform: scale(0);
    }
  }
  input:disabled:not(:checked) + label {
    &:before {
      box-shadow: none;
      border-color: #bbb;
      background-color: #ddd;
    }
  }
  input:checked + label {
    &:after {
      opacity: 1;
      transform: scale(1);
    }
  }
  input:disabled:checked + label {
    &:after {
      color: #999;
    }
  }
  input:disabled + label {
    color: #aaa;
  }
  input:checked:focus + label,
  input:not(:checked):focus + label {
    &:before {
      border: 1px dotted blue;
    }
  }
`;

const Input = styled.input<CheckboxType>`
  opacity: 0;
`;

const Label = styled.label<CheckboxType>``;

interface InputProps {
  value?: any;
  onChange: (e: any) => void;
  required?: boolean;
  disabled?: boolean;
}

export const Checkbox: React.FC<InputProps> = (props) => {
  return (
    <CheckboxContainer onClick={props.disabled ? () => {} : props.onChange}>
      {props.required ? <Text color="placeholder">*</Text> : null}
      <Input
        disabled={props.disabled}
        type="checkbox"
        checked={props.value}
        onChange={props.onChange}
      />
      <Label>{props.children}</Label>
    </CheckboxContainer>
  );
};

import React, { useContext } from "react";
import { View } from "../components/View";
import { Text } from "../components/Text";
import { Link } from "../components/Link";
import { Image } from "../components/Image";
import { Card } from "../components/Card";
import { Button } from "../components/Button";
import { Page } from "../components/Page";
import { Toast } from "../components/Toast";
import { Auth } from "../contexts/AuthProvider";

export function PrivacyPolicy(props: any) {
  return (
    <Page pageTitle="Privacy | CRAWLR" pageDescription="Privacy Policy">
      <View
        padding="5vh"
        background="background"
        flexFlow="column wrap"
        justifyContent="center"
      >
        <Text
          padding="1vh"
          textAlign="left"
          color="body"
          fontFamily="Brandon Grotesque"
          fontSize="1.5rem"
        >
          CRAWLR (“us”, “we”, or “our”) operates the CRAWLR website and the
          CRAWLR mobile application (hereinafter referred to as the “Service”).
          This page informs you of our policies regarding the collection, use
          and disclosure of personal data when you use our Service and the
          choices you have associated with that data. We use your data to
          provide and improve the Service. By using the Service, you agree to
          the collection and use of information in accordance with this policy.
          Unless otherwise defined in this Privacy Policy, the terms used in
          this Privacy Policy have the same meanings as in our Terms of Use.
        </Text>

        <Text
          padding="1vh"
          color="body"
          textAlign="left"
          fontFamily="Brandon Grotesque"
          fontSize="1.5rem"
        >
          Types of Data Collected
        </Text>

        <Text
          padding="1vh"
          color="body"
          textAlign="left"
          fontFamily="Brandon Grotesque"
          fontSize="1.5rem"
        >
          Definitions
        </Text>

        <Text padding="1vh" color="body" textAlign="left">
          SERVICE Service means the CRAWLR website and the CRAWLR mobile
          application operated by CRAWLR. PERSONAL DATA Personal Data means data
          about a living individual who can be identified from those data (or
          from those and other information either in our possession or likely to
          come into our possession). USAGE DATA Usage Data is data collected
          automatically either generated by the use of the Service or from the
          Service infrastructure itself (for example, the duration of a page
          visit). COOKIES Cookies are small files stored on your device
          (computer or mobile device). DATA CONTROLLER Data Controller means the
          natural or legal person who (either alone or jointly or in common with
          other persons) determines the purposes for which and the manner in
          which any personal information are, or are to be, processed. For the
          purpose of this Privacy Policy, we are a Data Controller of your
          Personal Data. DATA PROCESSORS (OR SERVICE PROVIDERS) Data Processor
          (or Service Provider) means any natural or legal person who processes
          the data on behalf of the Data Controller. We may use the services of
          various Service Providers in order to process your data more
          effectively. DATA SUBJECT (OR USER) Data Subject is any living
          individual who is using our Service and is the subject of Personal
          Data. Information Collection and Use We collect several different
          types of information for various purposes to provide and improve our
          Service to you.
        </Text>

        <Text padding="1vh" color="body" textAlign="left">
          Personal Data While using our Service, we may ask you to provide us
          with certain personally identifiable information that can be used to
          contact or identify you (“Personal Data”). Personally identifiable
          information may include, but is not limited to: Email address First
          name and last name Phone number Address, State, Province, ZIP/Postal
          code, City Cookies and Usage Data We may use your Personal Data to
          contact you with newsletters, marketing or promotional materials and
          other information that may be of interest to you. You may opt out of
          receiving any, or all, of these communications from us by following
          the unsubscribe link or instructions provided in any email we send or
          by contacting us. Usage Data We may also collect information that your
          browser sends whenever you visit our Service or when you access the
          Service by or through a mobile device (“Usage Data”). This Usage Data
          may include information such as your computer’s Internet Protocol
          address (e.g. IP address), browser type, browser version, the pages of
          our Service that you visit, the time and date of your visit, the time
          spent on those pages, unique device identifiers and other diagnostic
          data. When you access the Service with a mobile device, this Usage
          Data may include information such as the type of mobile device you
          use, your mobile device unique ID, the IP address of your mobile
          device, your mobile operating system, the type of mobile Internet
          browser you use, unique device identifiers and other diagnostic data.
          Location Data We may use and store information about your location if
          you give us permission to do so (“Location Data”). We use this data to
          provide features of our Service, to improve and customize our Service.
          You can enable or disable location services when you use our Service
          at any time by way of your device settings. Tracking Cookies Data We
          use cookies and similar tracking technologies to track the activity on
          our Service and we hold certain information. Cookies are files with a
          small amount of data which may include an anonymous unique identifier.
          Cookies are sent to your browser from a website and stored on your
          device. Other tracking technologies are also used such as beacons,
          tags and scripts to collect and track information and to improve and
          analyze our Service. You can instruct your browser to refuse all
          cookies or to indicate when a cookie is being sent. However, if you do
          not accept cookies, you may not be able to use some portions of our
          Service. Examples of Cookies we use: Session Cookies. We use Session
          Cookies to operate our Service. Preference Cookies. We use Preference
          Cookies to remember your preferences and various settings. Security
          Cookies. We use Security Cookies for security purposes. Advertising
          Cookies. Advertising Cookies are used to serve you with advertisements
          that may be relevant to you and your interests.
        </Text>

        <Text
          padding="1vh"
          color="body"
          textAlign="left"
          fontFamily="Brandon Grotesque"
          fontSize="1.5rem"
        >
          Use of Data
        </Text>

        <Text padding="1vh" color="body" textAlign="left">
          CRAWLR uses the collected data for various purposes: To provide and
          maintain our Service To notify you about changes to our Service To
          allow you to participate in interactive features of our Service when
          you choose to do so To provide customer support To gather analysis or
          valuable information so that we can improve our Service To monitor the
          usage of our Service To detect, prevent and address technical issues
          To provide you with news, special offers and general information about
          other goods, services and events which we offer that are similar to
          those that you have already purchased or enquired about unless you
          have opted not to receive such information
        </Text>

        <Text
          padding="1vh"
          color="body"
          textAlign="left"
          fontFamily="Brandon Grotesque"
          fontSize="1.5rem"
        >
          Legal Basis for Processing Personal Data under the General Data
          Protection Regulation (GDPR)
        </Text>

        <Text padding="1vh" color="body" textAlign="left">
          If you are from the European Economic Area (EEA), CRAWLR legal basis
          for collecting and using the personal information described in this
          Privacy Policy depends on the Personal Data we collect and the
          specific context in which we collect it. CRAWLR may process your
          Personal Data because: We need to perform a contract with you You have
          given us permission to do so The processing is in our legitimate
          interests and it is not overridden by your rights For payment
          processing purposes To comply with the law Retention of Data CRAWLR
          will retain your Personal Data only for as long as is necessary for
          the purposes set out in this Privacy Policy. We will retain and use
          your Personal Data to the extent necessary to comply with our legal
          obligations (for example, if we are required to retain your data to
          comply with applicable laws), resolve disputes and enforce our legal
          agreements and policies. CRAWLR will also retain Usage Data for
          internal analysis purposes. Usage Data is generally retained for a
          shorter period of time, except when this data is used to strengthen
          the security or to improve the functionality of our Service, or we are
          legally obligated to retain this data for longer periods. Transfer of
          Data Your information, including Personal Data, may be transferred to
          – and maintained on – computers located outside of your state,
          province, country or other governmental jurisdiction where the data
          protection laws may differ from those of your jurisdiction. If you are
          located outside United States and choose to provide information to us,
          please note that we transfer the data, including Personal Data, to
          United States and process it there. Your consent to this Privacy
          Policy followed by your submission of such information represents your
          agreement to that transfer. CRAWLR will take all the steps reasonably
          necessary to ensure that your data is treated securely and in
          accordance with this Privacy Policy and no transfer of your Personal
          Data will take place to an organization or a country unless there are
          adequate controls in place including the security of your data and
          other personal information.
        </Text>

        <Text
          padding="1vh"
          color="body"
          textAlign="left"
          fontFamily="Brandon Grotesque"
          fontSize="1.5rem"
        >
          Disclosure of Data
        </Text>

        <Text padding="1vh" color="body" textAlign="left">
          Disclosure for Law Enforcement Under certain circumstances, CRAWLR may
          be required to disclose your Personal Data if required to do so by law
          or in response to valid requests by public authorities (e.g. a court
          or a government agency). Legal Requirements CRAWLR may disclose your
          Personal Data in the good faith belief that such action is necessary
          to: To comply with a legal obligation To protect and defend the rights
          or property of CRAWLR To prevent or investigate possible wrongdoing in
          connection with the Service To protect the personal safety of users of
          the Service or the public To protect against legal liability Security
          of Data The security of your data is important to us but remember that
          no method of transmission over the Internet or method of electronic
          storage is 100% secure. While we strive to use commercially acceptable
          means to protect your Personal Data, we cannot guarantee its absolute
          security.
        </Text>

        <Text
          padding="1vh"
          color="body"
          textAlign="left"
          fontFamily="Brandon Grotesque"
          fontSize="1.5rem"
        >
          Our Policy on “Do Not Track” Signals under the California Online
          Protection Act (CalOPPA)
        </Text>

        <Text padding="1vh" color="body" textAlign="left">
          We do not support Do Not Track (“DNT”). Do Not Track is a preference
          you can set in your web browser to inform websites that you do not
          want to be tracked. You can enable or disable Do Not Track by visiting
          the Preferences or Settings page of your web browser.
        </Text>

        <Text
          padding="1vh"
          color="body"
          textAlign="left"
          fontFamily="Brandon Grotesque"
          fontSize="1.5rem"
        >
          Your Data Protection Rights under the General Data Protection
          Regulation (GDPR)
        </Text>

        <Text padding="1vh" color="body" textAlign="left">
          If you are a resident of the European Economic Area (EEA), you have
          certain data protection rights. CRAWLR aims to take reasonable steps
          to allow you to correct, amend, delete or limit the use of your
          Personal Data. If you wish to be informed about what Personal Data we
          hold about you and if you want it to be removed from our systems,
          please contact us. In certain circumstances, you have the following
          data protection rights: The right to access, update or delete the
          information we have on you. Whenever made possible, you can access,
          update or request deletion of your Personal Data directly within your
          account settings section. If you are unable to perform these actions
          yourself, please contact us to assist you. Logged in users can easily
          request data deletion at the following form:
          {/* https://crawlr.app/delete-data-request/  */}
          The right of rectification. You have the right to have your
          information rectified if that information is inaccurate or incomplete.
          The right to object. You have the right to object to our processing of
          your Personal Data. The right of restriction. You have the right to
          request that we restrict the processing of your personal information.
          The right to data portability. You have the right to be provided with
          a copy of the information we have on you in a structured,
          machine-readable and commonly used format. The right to withdraw
          consent. You also have the right to withdraw your consent at any time
          where CRAWLR relied on your consent to process your personal
          information. Please note that we may ask you to verify your identity
          before responding to such requests. You have the right to complain to
          a Data Protection Authority about our collection and use of your
          Personal Data. For more information, please contact your local data
          protection authority in the European Economic Area (EEA).
        </Text>

        <Text
          padding="1vh"
          color="body"
          textAlign="left"
          fontFamily="Brandon Grotesque"
          fontSize="1.5rem"
        >
          Service Providers
        </Text>

        <Text padding="1vh" color="body" textAlign="left">
          We may employ third party companies and individuals to facilitate our
          Service (“Service Providers”), provide the Service on our behalf,
          perform Service-related services or assist us in analysing how our
          Service is used. These third parties have access to your Personal Data
          only to perform these tasks on our behalf and are obligated not to
          disclose or use it for any other purpose.
        </Text>

        <Text
          padding="1vh"
          color="body"
          textAlign="left"
          fontFamily="Brandon Grotesque"
          fontSize="1.5rem"
        >
          Analytics
        </Text>

        <Text padding="1vh" color="body" textAlign="left">
          We may use third-party Service Providers to monitor and analyse the
          use of our Service.Google Analytics Google Analytics is a web
          analytics service offered by Google that tracks and reports website
          traffic. Google uses the data collected to track and monitor the use
          of our Service. This data is shared with other Google services.Google
          may use the collected data to contextualise and personalise the ads of
          its own advertising network. For more information on the privacy
          practices of Google, please visit the Google Privacy Terms web page:
          {/* https://policies.google.com/privacy?hl=en  */}
          Advertising We may use third-party Service Providers to show
          advertisements to you to help support and maintain our Service. Google
          AdSense DoubleClick Cookie Google, as a third party vendor, uses
          cookies to serve ads on our Service. Google’s use of the DoubleClick
          cookie enables it and its partners to serve ads to our users based on
          their visit to our Service or other websites on the Internet. You may
          opt out of the use of the DoubleClick Cookie for interest-based
          advertising by visiting the Google Ads Settings web page
          {/* http://www.google.com/ads/preferences/ */}
        </Text>

        <Text
          padding="1vh"
          color="body"
          textAlign="left"
          fontFamily="Brandon Grotesque"
          fontSize="1.5rem"
        >
          Behavioral Remarketing
        </Text>

        <Text padding="1vh" color="body" textAlign="left">
          CRAWLR uses remarketing services to advertise on third party websites
          to you after you visited our Service. We and our third-party vendors
          use cookies to inform, optimize and serve ads based on your past
          visits to our Service. Google Ads (AdWords) Google Ads (AdWords)
          remarketing service is provided by Google Inc. You can opt-out of
          Google Analytics for Display Advertising and customise the Google
          Display Network ads by visiting the Google Ads Settings page:
          {/* http://www.google.com/settings/ads  */}
          Google also recommends installing the Google Analytics Opt-out Browser
          Add-on –{/* https://tools.google.com/dlpage/gaoptout */}– for your web
          browser. Google Analytics Opt-out Browser Add-on provides visitors
          with the ability to prevent their data from being collected and used
          by Google Analytics. For more information on the privacy practices of
          Google, please visit the Google Privacy Terms web page:
          {/* https://policies.google.com/privacy?hl=en  */}
          Facebook Facebook remarketing service is provided by Facebook Inc. You
          can learn more about interest-based advertising from Facebook by
          visiting this page:
          {/* https://www.facebook.com/help/164968693837950  */}
          To opt-out from Facebook’s interest-based ads, follow these
          instructions from Facebook:
          {/* https://www.facebook.com/help/568137493302217  */}
          Facebook adheres to the Self-Regulatory Principles for Online
          Behavioural Advertising established by the Digital Advertising
          Alliance. You can also opt-out from Facebook and other participating
          companies through the Digital Advertising Alliance in the USA
          {/* http://www.aboutads.info/choices/,  */}
          the Digital Advertising Alliance of Canada in Canada
          {/* http://youradchoices.ca/  */}
          or the European Interactive Digital Advertising Alliance in Europe
          {/* http://www.youronlinechoices.eu/,  */}
          or opt-out using your mobile device settings. For more information on
          the privacy practices of Facebook, please visit Facebook’s Data
          Policy:
          {/* https://www.facebook.com/privacy/explanation */}
        </Text>

        <Text
          padding="1vh"
          color="body"
          textAlign="left"
          fontFamily="Brandon Grotesque"
          fontSize="1.5rem"
        >
          Payments
        </Text>

        <Text padding="1vh" color="body" textAlign="left">
          We may provide paid products and/or services within the Service. In
          that case, we use third-party services for payment processing (e.g.
          payment processors). We will not store or collect your payment card
          details. That information is provided directly to our third-party
          payment processors whose use of your personal information is governed
          by their Privacy Policy. These payment processors adhere to the
          standards set by PCI-DSS as managed by the PCI Security Standards
          Council, which is a joint effort of brands like Visa, MasterCard,
          American Express and Discover. PCI-DSS requirements help ensure the
          secure handling of payment information. The payment processors we work
          with are: Apple Store In-App Payments Their Privacy Policy can be
          viewed at
          {/* https://www.apple.com/legal/privacy/en-ww/  */}
          Google Play In-App Payments Their Privacy Policy can be viewed at
          {/* https://www.google.com/policies/privacy/  */}
          PayPal / Braintree Their Privacy Policy can be viewed at
          {/* https://www.paypal.com/webapps/mpp/ua/privacy-full */}
        </Text>

        <Text
          padding="1vh"
          color="body"
          textAlign="left"
          fontFamily="Brandon Grotesque"
          fontSize="1.5rem"
        >
          LinkComponents to Other Sites
        </Text>

        <Text padding="1vh" color="body" textAlign="left">
          Our Service may contain links to other sites that are not operated by
          us. If you click a third party link, you will be directed to that
          third party’s site. We strongly advise you to review the Privacy
          Policy of every site you visit. We have no control over and assume no
          responsibility for the content, privacy policies or practices of any
          third party sites or services.
        </Text>

        <Text
          padding="1vh"
          color="body"
          textAlign="left"
          fontFamily="Brandon Grotesque"
          fontSize="1.5rem"
        >
          Children’s Privacy
        </Text>

        <Text padding="1vh" color="body" textAlign="left">
          Our Service does not address anyone under the age of 18 (“Children”).
          We do not knowingly collect personally identifiable information from
          anyone under the age of 18. If you are a parent or guardian and you
          are aware that your Child has provided us with Personal Data, please
          contact us. If we become aware that we have collected Personal Data
          from children without verification of parental consent, we take steps
          to remove that information from our servers. All CRAWLR members must
          be 18 or older.
        </Text>

        <Text
          padding="1vh"
          color="body"
          textAlign="left"
          fontFamily="Brandon Grotesque"
          fontSize="1.5rem"
        >
          Changes to This Privacy Policy
        </Text>

        <Text padding="1vh" color="body" textAlign="left">
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page. We will
          let you know via email and/or a prominent notice on our Service, prior
          to the change becoming effective and update the “effective date” at
          the top of this Privacy Policy. You are advised to review this Privacy
          Policy periodically for any changes. Changes to this Privacy Policy
          are effective when they are posted on this page.
        </Text>

        <Text
          padding="1vh"
          color="body"
          textAlign="left"
          fontFamily="Brandon Grotesque"
          fontSize="1.5rem"
        >
          Contact Us
        </Text>

        <Text padding="1vh" color="body" textAlign="left">
          If you have any questions about this Privacy Policy, please contact
          us: By email: jackie@crawlr.app By visiting this page on our website:
          {/* https://crawlr.app/contact  */}
          This Privacy Policy is also available in: Dutch French German Italian
          Spanish
        </Text>
      </View>
    </Page>
  );
}

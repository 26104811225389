import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { View } from "./View";
import { Text } from "./Text";
import { Icon } from "./Icon";

import { Dimensions } from "../contexts";
import { Bars, UserCircle, Times } from "@styled-icons/fa-solid";

type ModalType = {
  flex?: string;
  flexFlow?: string;
  justifyContent?: string;
  alignContent?: string;
  padding?: string;
  margin?: string;
  background?: string;
  id?: string;
  wrap?: boolean;
  onClick?: () => void;
  maxWidth?: string;
  minWidth?: string;
  zIndex?: number;
  position?: string;
  borderWidth?: string;
  borderColor?: string;
  cursor?: string;
  to?: string;
  borderRadius?: string;
  height?: string;
  color?: string;
  alignItems?: string;
  top?: number;
  children: any;
  width?: string;
};

const ModalContainer = styled.div<ModalType>`
  display: block;
  position: relative;
  width: 30%;
  margin: 0 auto;
  min-width: 300px;
`;

const ModalContent = styled.div<ModalType>`
  position: absolute;
  border-radius: 20px;
  top: 0%;
  left: 0%;
  right: 0%;
  transform: translateX(0%);
  color: white;
  background: ${(props) => (!props.background ? null : null)};
  background: ${(props) =>
    props.background &&
    props.background !== "body" &&
    props.background !== "background"
      ? props.theme.standardColors[props.background]
      : null};
  background: ${(props) =>
    props.background &&
    (props.background === "body" || props.background === "background")
      ? props.theme.themeColors[props.background]
      : null};
  box-shadow: 1px 1px 3px gray;
  font-size: 1rem;
  z-index: 100;
  margin: 0 auto;
  margin-top: 3vh;
`;

interface ModalProps {
  flex?: string;
  flexFlow?: string;
  justifyContent?: string;
  alignContent?: string;
  padding?: string;
  margin?: string;
  background?: string;
  id?: string;
  wrap?: boolean;
  onClick?: () => void;
  maxWidth?: string;
  minWidth?: string;
  zIndex?: number;
  position?: string;
  borderWidth?: string;
  borderColor?: string;
  cursor?: string;
  to?: string;
  borderRadius?: string;
  height?: string;
  color?: string;
  alignItems?: string;
  top?: number;
  children: any;
  title?: string;
  width?: string;
}

export const Modal: React.FC<ModalProps> = ({
  onClick,
  title,
  background,
  children,
  ...rest
}) => {
  const history = useHistory();

  const { mobile } = Dimensions();

  return (
    <ModalContainer>
      <ModalContent background={background}>
        <View
          flexFlow="row wrap"
          justifyContent="space-between"
          padding="3vh 3vw 3vh 3vw"
        >
          <View flexFlow="row wrap" flex="2" justifyContent="flex-start">
            <Text
              fontSize="1.25rem"
              fontFamily="Brandon Grotesque"
              fontWeight="700"
              color="black"
            >
              {title}
            </Text>
          </View>
          <View flexFlow="row wrap" flex="1" justifyContent="flex-end">
            <Icon size="1rem" color="black" onClick={onClick}>
              <Times />
            </Icon>
          </View>
        </View>

        <View
          flex="1"
          flexFlow="column wrap"
          justifyContent="center"
          padding="2vh 1vw 1vh 1vw"
        >
          {children?.map((child, index) => {
            return (
              <View
                flexFlow="row wrap"
                justifyContent="center"
                key={index}
                padding="1vh 1vw 1vh 1vw"
              >
                {child}
              </View>
            );
          })}
        </View>
      </ModalContent>
    </ModalContainer>
  );
};

import moment from "moment";

export const validateEmail = (email: string) => {
  let valid = false;
  if (email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    valid = re.test(String(email).toLowerCase());
  }

  return valid;
};

export const validatePassword = (password) => {
  let valid = false;
  const re =
    /^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[\w!@#$%^&*]{8,}$/;

  if (password) {
    valid = re.test(password);
  }

  return valid;
};

export const validateBirthdate = (birthdate) => {
  let valid = false;
  if (birthdate) {
    const currentDate = moment(new Date(), "YYYY-MM-DD");
    const birthdateTime = moment(new Date(birthdate), "YYYY-MM-DD");
    const difference = moment
      .duration(currentDate.diff(birthdateTime))
      .asYears();
    if (difference > 18) {
      valid = true;
    }
    return valid;
  }
};

//validate early adopter

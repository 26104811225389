import {
  Discord,
  Facebook,
  Instagram,
  Reddit,
  Tiktok,
  Twitch,
  Twitter,
} from "@styled-icons/fa-brands";
import {
  CaretLeft,
  CaretRight,
  Magic,
  Search,
  Users,
} from "@styled-icons/fa-solid";
import Carousel from "nuka-carousel";
import React from "react";
import {
  alex,
  ben,
  cleric,
  david,
  discord,
  hero,
  jackie,
  jon,
  joshua,
  kofi,
  marisa,
  shop,
  twitch,
  wizard,
} from "../assets/images";
import Iframe from 'react-iframe'
import { Card } from "../components/Card";
import { Footer } from "../components/Footer";
import { Icon } from "../components/Icon";
import { Image } from "../components/Image";
import { Link } from "../components/Link";
import { Page } from "../components/Page";
import { Text } from "../components/Text";
import { View } from "../components/View";
import { Dimensions } from "../contexts";
import { Auth } from "../contexts/AuthProvider";

export function Home(props: any) {
  const { mobile, tablet, desktop, laptop } = Dimensions();

  return (
    <Page pageTitle="Home | CRAWLR" pageDescription="Home">
      <View
        padding="5vh"
        background="primaryGradient"
        flexFlow="row wrap"
        justifyContent="space-around"
      >
        <View flex="1 1 1" flexFlow="column wrap" justifyContent="center">
          <Card background="background">
            <Text
              fontWeight="900"
              fontFamily="Brandon Grotesque"
              fontSize="3rem"
              color="body"
            >
              CRAWLR
            </Text>
            <Text fontSize="1.25rem" color="body" textAlign="center">
              Looking for games, made easy.
            </Text>
            <Link
              textAlign="center"
              linkType="fill"
              href="/getstarted"
              background="primary"
            >
              Get Started
            </Link>
          </Card>
          <View
            flexFlow="row wrap"
            padding="3vh 0 0 0"
            justifyContent="space-around"
          >
            <Icon
              size="2rem"
              color="primaryDark"
              to="https://www.facebook.com/crawlrapp/"
            >
              <Facebook href="https://www.facebook.com/crawlrapp/" />
            </Icon>
            <Icon
              size="2rem"
              color="primaryDark"
              to="https://www.instagram.com/crawlr_app/"
            >
              <Instagram />
            </Icon>
            <Icon
              size="2rem"
              color="primaryDark"
              to="https://twitter.com/joincrawlr"
            >
              <Twitter />
            </Icon>
            <Icon
              size="2rem"
              color="primaryDark"
              to="https://discord.gg/YAvcDUJCpk"
            >
              <Discord />
            </Icon>
            <Icon
              size="2rem"
              color="primaryDark"
              to="https://www.reddit.com/r/CRAWLR/"
            >
              <Reddit />
            </Icon>
            <Icon
              size="2rem"
              color="primaryDark"
              to="https://www.twitch.tv/crawlrapp"
            >
              <Twitch />
            </Icon>
            <Icon
              size="2rem"
              color="primaryDark"
              to="https://www.tiktok.com/@crawlr"
            >
              <Tiktok />
            </Icon>
          </View>
        </View>

        <View flex="1 1 1" flexFlow="column wrap" justifyContent="center">
          <Image
            width="225px"
            src={hero}
            alt="Hero image. Little cartoon characters surrounding a phone under construction."
          />
          <Text
            fontFamily="Brandon Grotesque"
            fontWeight="700"
            fontSize="1.75rem"
            color="primaryDark"
            textAlign="center"
          >
            Mobile Apps Coming Soon!
          </Text>
          <Text
            fontFamily="Brandon Grotesque"
            fontWeight="700"
            fontSize="1rem"
            color="primaryDark"
            textAlign="center"
          >
            (Currently In Beta Test)
          </Text>
        </View>
      </View>
      <View
        padding="5vh"
        background="background"
        flexFlow="row wrap"
        justifyContent="space-evenly"
      >
        <View flex="0 1 400px" flexFlow="column wrap" justifyContent="center">
          <Icon size="3rem" color="body">
            <Magic />
          </Icon>
          <Text
            textAlign="center"
            fontFamily="Brandon Grotesque"
            fontWeight="700"
            fontSize="2rem"
            color="body"
          >
            Create
          </Text>
          <Text fontSize="1.15rem" textAlign="center" color="body">
            Game Masters rejoice! Create sessions and fill out all the vital
            information to entice players.
          </Text>
        </View>
        <View flexFlow="column wrap" justifyContent="center" flex="0 1 400px">
          <Icon size="3rem" color="body">
            <Search style={{ margin: "0 auto" }} />
          </Icon>
          <Text
            textAlign="center"
            fontFamily="Brandon Grotesque"
            fontWeight="700"
            fontSize="2rem"
            color="body"
          >
            Explore
          </Text>
          <Text fontSize="1.15rem" textAlign="center" color="body">
            Players, explore to your heart's content! Filter and sort game
            sessions by what matters most.
          </Text>
        </View>
        <View flexFlow="column wrap" justifyContent="center" flex="0 1 400px">
          <Icon size="3rem" color="body">
            <Users style={{ margin: "0 auto" }} />
          </Icon>
          <Text
            textAlign="center"
            fontFamily="Brandon Grotesque"
            fontWeight="700"
            fontSize="2rem"
            color="body"
          >
            Connect
          </Text>
          <Text fontSize="1.15rem" textAlign="center" color="body">
            The adventure begins! Communicate with your party members and get
            rolling!
          </Text>
        </View>
      </View>

      <View
        padding="5vh"
        background="darkgray"
        id="about"
        flexFlow="column wrap"
      >
        <View justifyContent="center" flex="1 1 1">
          <Text
            textAlign="center"
            color="body"
            fontFamily="Brandon Grotesque"
            fontWeight="700"
            fontSize="3rem"
          >
            About
          </Text>
        </View>

        <View flexFlow={!mobile ? "row wrap" : "column wrap"}>
          <View justifyContent="center" flex="1">
            <Image width="150px" src={cleric} alt="Cleric character." />
          </View>

          <View justifyContent="center" flex="2">
            <View flexFlow="column wrap" justifyContent="center">
              <Text color="body" fontSize="1.15rem" padding="1vh">
                We started this project to solve one problem: it’s too damn
                tough to find other people who enjoy roleplaying games. Even in
                the age of social media, finding a campaign in the genre you
                want with like-minded players is a hassle and a half. You might
                get lucky and stumble upon a good fit, but the odds aren’t in
                your favor. There’s only so much manual searching you can handle
                before giving up.
              </Text>
              <Text color="body" fontSize="1.15rem" padding="1vh">
                We’re a group of tabletop roleplayers who get it. We’ve been
                there. It sucks. Few things are as frustrating as having a
                passion in which you can’t indulge. So, a few cool minds got
                together and decided to take a stab at fixing it.
              </Text>
              <Text color="body" fontSize="1.15rem" padding="1vh">
                Our team believes in making roleplaying games accessible for
                everyone. Gatekeeping isn’t in our DNA, and we want to build a
                community that bridges the gap. CRAWLR is made with everyone in
                mind, from newcomers to veterans.
              </Text>
            </View>
          </View>

          <View justifyContent="center" flex="1">
            <Image width="150px" src={wizard} alt="Wizard character." />
          </View>
        </View>
      </View>

      <View
        padding="5vh"
        background="background"
        id="team"
        flexFlow="column wrap"
      >
        <View justifyContent="center" flex="1">
          <Text
            textAlign="center"
            color="body"
            fontFamily="Brandon Grotesque"
            fontWeight="700"
            fontSize="3rem"
          >
            Team
          </Text>
        </View>

        <View flex="1" flexFlow="row wrap" justifyContent="space-around">
          <View padding="3vh 0 3vh 0" flexFlow="column wrap">
            <Image
              borderRadius="50%"
              width="150px"
              alt="Profile photo for Jackie Zantow-Schwartz"
              src={jackie}
            />
            <Text
              textAlign="center"
              fontFamily="Brandon Grotesque"
              fontWeight="600"
              fontSize="1.5rem"
              color="body"
            >
              CEO & Founder
            </Text>
            <Text textAlign="center" color="body" fontSize="1.25rem">
              Jackie Zantow-Schwartz
            </Text>
          </View>
          <View padding="3vh 0 3vh 0" flexFlow="column wrap">
            <Image
              borderRadius="50%"
              width="150px"
              alt="Profile photo for Marisa Bernard"
              src={marisa}
            />
            <Text
              textAlign="center"
              fontFamily="Brandon Grotesque"
              fontWeight="600"
              fontSize="1.5rem"
              color="body"
            >
              CTO & Software Engineer
            </Text>
            <Text textAlign="center" color="body" fontSize="1.25rem">
              Marisa Bernard
            </Text>
          </View>
        </View>
        {/* <View justifyContent="center" flex="1">
          <Text
            textAlign="center"
            color="body"
            fontFamily="Brandon Grotesque"
            fontWeight="700"
            fontSize="3rem"
          >
            Volunteers
          </Text>
        </View>
        <View flex="1" flexFlow="row wrap" justifyContent="space-around">
          <View padding="3vh 0 3vh 0" flexFlow="column wrap">
            <Image
              borderRadius="50%"
              width="150px"
              alt="Profile photo for Alex Nichols"
              src={alex}
            />
            <Text
              textAlign="center"
              fontFamily="Brandon Grotesque"
              fontWeight="600"
              fontSize="1.5rem"
              color="body"
            >
              Community Manager
            </Text>
            <Text textAlign="center" color="body" fontSize="1.25rem">
              Alex Nichols
            </Text>
          </View>
          <View padding="3vh 0 3vh 0" flexFlow="column wrap">
            <Image
              borderRadius="50%"
              width="150px"
              alt="Profile photo for David Awad"
              src={david}
            />
            <Text
              textAlign="center"
              fontFamily="Brandon Grotesque"
              fontWeight="600"
              fontSize="1.5rem"
              color="body"
            >
              Advisor
            </Text>
            <Text textAlign="center" color="body" fontSize="1.25rem">
              David Awad
            </Text>
          </View>
          <View padding="3vh 0 3vh 0" flexFlow="column wrap">
            <Image
              borderRadius="50%"
              width="150px"
              alt="Profile photo for Jon Parfitt "
              src={jon}
            />
            <Text
              textAlign="center"
              fontFamily="Brandon Grotesque"
              fontWeight="600"
              fontSize="1.5rem"
              color="body"
            >
              Graphic Designer
            </Text>
            <Text textAlign="center" color="body" fontSize="1.25rem">
              Jon Parfitt
            </Text>
          </View>
          <View padding="3vh 0 3vh 0" flexFlow="column wrap">
            <Image
              borderRadius="50%"
              alt="Profile photo for Ben Wooten"
              width="150px"
              src={ben}
            />
            <Text
              textAlign="center"
              fontFamily="Brandon Grotesque"
              fontWeight="600"
              fontSize="1.5rem"
              color="body"
            >
              UI/UX Designer
            </Text>
            <Text textAlign="center" color="body" fontSize="1.25rem">
              Ben Wooten
            </Text>
          </View>
          <View padding="3vh 0 3vh 0" flexFlow="column wrap">
            <Image
              borderRadius="50%"
              width="150px"
              alt="Profile photo for Joshua Loerakker"
              src={joshua}
            />
            <Text
              textAlign="center"
              fontFamily="Brandon Grotesque"
              fontWeight="600"
              fontSize="1.5rem"
              color="body"
            >
              Backend Developer
            </Text>
            <Text textAlign="center" color="body" fontSize="1.25rem">
              Joshua Loerakker
            </Text>
          </View>
        </View> */}
      </View>

    

<View
        padding="5vh"
        background="darkgray"
        id="donate"
        flexFlow="column wrap"
        justifyContent="center"
      >
        <View justifyContent="center">
          <Text
            textAlign="center"
            color="body"
            fontFamily="Brandon Grotesque"
            fontWeight="700"
            fontSize="3rem"
          >
            Donate
          </Text>
        </View>

                  <View flexFlow="row wrap" justifyContent="space-around" height="100%">
            <View flex="1" justifyContent="center">
              <Card margin="0 auto" width="50%" background="background">
                <Text
                  fontFamily="Brandon Grotesque"
                  fontWeight="700"
                  fontSize="2rem"
                  color="body"
                >
                  Donate
                </Text>
                <Text textAlign="center" fontSize="1.25rem" color="body">
                  Want to support the dream? Ko-fi allows one-time or monthly
                  donations. Any support is greatly appreciated!
                </Text>
              </Card>
            </View>

              <View flex="1" flexFlow="column wrap" justifyContent="center" padding="5%" alignItems="center">
            <Iframe 
                url="https://ko-fi.com/crawlrapp/?hidefeed=true&widget=true&embed=true&preview=true"
                display="initial"
                position="relative"
                height="575px"
                width="500px"
                styles={{height: "575px", width:'500px', padding:'0px', border:'none', background:'transparent'}}
                />
              
              </View>
          </View>

     
        </View>

      <View
        padding="5vh"
        background="primaryGradient"
        id="contribute"
        flexFlow="column wrap"
        justifyContent="center"
      >
        <View justifyContent="center">
          <Text
            textAlign="center"
            color="body"
            fontFamily="Brandon Grotesque"
            fontWeight="700"
            fontSize="3rem"
          >
            Contribute
          </Text>
        </View>
        <Carousel
          style={{height:'100%'}}
          wrapAround={true}
          swiping={true}
          dragging={true}
          enableKeyboardControls={true}
          autoplay={true}
          autoplayInterval={5000}
          defaultControlsConfig={{
            nextButtonText: "Custom Next",
            prevButtonText: "Custom Prev",
            pagingDotsStyle: {
              padding: "0 10px 0 10px",
              fill: "black",
              width: "50px !important",
              height: "50px !important",
            },
          }}
          renderCenterLeftControls={({ previousSlide }) => {
            if (!mobile && !tablet) {
              return (
                <CaretLeft
                  onClick={previousSlide}
                  size={25}
                  color="primaryDark"
                />
              );
            } else {
              return null;
            }
          }}
          renderCenterRightControls={({ nextSlide }) => {
            if (!mobile && !tablet) {
              return (
                <CaretRight onClick={nextSlide} size={25} color="primaryDark" />
              );
            } else {
              return null;
            }
          }}
        >

          <View flexFlow="row wrap" justifyContent="space-around" height="100%">
            <View flex="1" justifyContent="center">
              <Card background="background" margin="0 auto" width="50%">
                <Text
                  fontFamily="Brandon Grotesque"
                  fontWeight="700"
                  fontSize="2rem"
                  color="body"
                >
                  Shop
                </Text>
                <Text fontSize="1.25rem" textAlign="center" color="body">
                  Looking for some cool tabletop gaming merch? You’ve found the
                  place.
                </Text>
                <Link
                  textAlign="center"
                  linkType="fill"
                  href="https://ko-fi.com/crawlrapp/shop"
                  background="primary"
                >
                  Visit Shop
                </Link>
              </Card>
            </View>

            {!mobile ? (
              <View
                flex="1"
                flexFlow="column wrap"
                padding="1vh 1vw 1vh 1vw"
                justifyContent="center"
              >
                <Image width="300px" src={shop} alt="Shopping Basket Image" />
              </View>
            ) : null}
          </View>
          <View flexFlow="row wrap" justifyContent="space-around" height="100%">
            <View flex="1" justifyContent="center">
              <Card background="background" margin="0 auto" width="50%">
                <Text
                  fontFamily="Brandon Grotesque"
                  fontWeight="700"
                  fontSize="2rem"
                  color="body"
                >
                  Join
                </Text>
                <Text fontSize="1.25rem" textAlign="center" color="body">
                  Connect with the community, get technical support, and engage
                  in your daily dose of shenanigans.
                </Text>
                <Link
                  textAlign="center"
                  linkType="fill"
                  href="https://discord.gg/hSmtJymtpN"
                  background="primary"
                >
                  Launch Discord
                </Link>
              </Card>
            </View>

            {!mobile ? (
              <View
                flex="1"
                flexFlow="column wrap"
                padding="1vh 1vw 1vh 1vw"
                justifyContent="center"
              >
                <Image width="300px" src={discord} alt="Discord Icon" />
              </View>
            ) : null}
          </View>
          <View flexFlow="row wrap" justifyContent="space-around" height="100%">
            <View flex="1" justifyContent="center">
              <Card background="background" margin="0 auto" width="50%">
                <Text
                  fontFamily="Brandon Grotesque"
                  fontWeight="700"
                  fontSize="2rem"
                  color="body"
                >
                  Watch
                </Text>
                <Text fontSize="1.25rem" textAlign="center" color="body">
                  Get to know our team as we stream campaigns and podcasts with
                  special guest appearances.
                </Text>
                <Link
                  textAlign="center"
                  linkType="fill"
                  href="https://www.twitch.tv/crawlrapp"
                  background="primary"
                >
                  Visit Twitch
                </Link>
              </Card>
            </View>

            {!mobile ? (
              <View
                flex="1"
                flexFlow="column wrap"
                padding="1vh 1vw 1vh 1vw"
                justifyContent="center"
              >
                <Image width="300px" src={twitch} alt="Twitch Icon" />
              </View>
            ) : null}
          </View>
        </Carousel>
      </View>

      <View
        padding="5vh"
        background="background"
        id="contact"
        flexFlow="column wrap"
      >
        <View justifyContent="center" flex="1">
          <Text
            textAlign="center"
            color="body"
            fontFamily="Brandon Grotesque"
            fontWeight="700"
            fontSize="3rem"
          >
            Contact
          </Text>
        </View>
        <View padding="3vh 0 0 0" flexFlow="column wrap" margin="0 auto">
          <View flexFlow="row wrap">
            <View flexFlow="row wrap" justifyContent="center">
              {" "}
              <Link
                linkType="fill"
                textAlign="center"
                href="mailto:support@crawlr.app"
                background="primary"
              >
                support@crawlr.app
              </Link>
            </View>
            <View flexFlow="row wrap" justifyContent="center">
              {" "}
              <Link
                linkType="fill"
                textAlign="center"
                href="mailto:feedback@crawlr.app"
                background="primary"
              >
                feedback@crawlr.app
              </Link>
            </View>
            <View flexFlow="row wrap" justifyContent="center">
              <Link
                linkType="fill"
                textAlign="center"
                href="mailto:business@crawlr.app"
                background="primary"
              >
                business@crawlr.app
              </Link>
            </View>
          </View>

          <View
            padding="3vh 0 3vh 0"
            flex="1"
            flexFlow="row wrap"
            justifyContent="space-around"
          >
            <Icon
              size="2rem"
              color="primary"
              to="https://www.facebook.com/crawlrapp/"
            >
              <Facebook href="https://www.facebook.com/crawlrapp/" />
            </Icon>
            <Icon
              size="2rem"
              color="primary"
              to="https://www.instagram.com/crawlr_app/"
            >
              <Instagram />
            </Icon>
            <Icon
              size="2rem"
              color="primary"
              to="https://twitter.com/joincrawlr"
            >
              <Twitter />
            </Icon>
            <Icon
              size="2rem"
              color="primary"
              to="https://discord.gg/YAvcDUJCpk"
            >
              <Discord />
            </Icon>
            <Icon
              size="2rem"
              color="primary"
              to="https://www.reddit.com/r/CRAWLR/"
            >
              <Reddit />
            </Icon>
            <Icon
              size="2rem"
              color="primary"
              to="https://www.twitch.tv/crawlrapp"
            >
              <Twitch />
            </Icon>
            <Icon
              size="2rem"
              color="primary"
              to="https://www.tiktok.com/@crawlr"
            >
              <Tiktok />
            </Icon>
          </View>
        </View>
      </View>
      <Footer />
    </Page>
  );
}

import React, { createContext, useState, useContext, useEffect, useReducer } from 'react';
import firebase, { auth, firestore, storage, googleProvider } from "../../firebase.config";
import {standardsquarelogo} from '../assets/images'
import {Loading} from '../components/Loading/Loading'
import axios from 'axios'

type AuthContextProps = {
   login:any;
   signup:any;
   loginWithGoogle: any;
   signupWithGoogle: any;
   logout:any;
   currentUser:any;
   authPending:any;
   error:any;
   success:any;
   sendEmailVerification:any;
  sendPasswordResetEmail: any;
     earlyAdopter:any;
  updateEmail: any;
    updatePassword: any;
  setSuccess: any;
  updateAuthUserProfile: any;
  getCurrentUser:any;
}

const AuthContext = createContext<AuthContextProps>({
  logout:null,
  signup:null,
  login:null,
  currentUser:null,
  authPending:null,
  error:null,
  success:null,
  signupWithGoogle: null,
 loginWithGoogle: null,
  sendEmailVerification:null,
 sendPasswordResetEmail: null,
    earlyAdopter:null,
  updateEmail: null,
    updatePassword: null,
  setSuccess: null,
  updateAuthUserProfile: null,
  getCurrentUser:null
})

export const AuthProvider = (params:any) => {
  const [authPending, setAuthPending] = useState<boolean | null>(true);
  const [currentUser, setCurrentUser] = useState<any | null>(null);
  const [error, setError] = useState<any | null>(null);
  const [success, setSuccess] = useState<any | null>(null);
  const [token, setToken] = useState<any | null>(null);
  const [earlyAdopter, setEarlyAdopter] = useState(false);


  useEffect(() => {
    const onAuthStateChanged = auth().onAuthStateChanged(user => {
      if (user) {
        setCurrentUser(user.toJSON());
        setAuthPending(false)
      } else {
        setCurrentUser(null)
        setAuthPending(false)
      }
    });
    return () => onAuthStateChanged();
  }, []);

    useEffect(() => {
    if (currentUser) {
      getEarlyAdopter();
      getToken();
    }
  }, [currentUser]);

const getEarlyAdopter = () =>{
  const userDate = Number(currentUser.createdAt);
  const earlyAdopterDate = new Date(2022, 12, 1, 0, 0, 0);
  const earlyAdopterDateMilliseconds = earlyAdopterDate.getTime();
  if (userDate <= earlyAdopterDateMilliseconds) {
    setEarlyAdopter(true);
  }
  else{
    setEarlyAdopter(false)
  }
}

const getCurrentUser = () =>{
  setCurrentUser(auth().currentUser.toJSON())
}



  const login = async (returningUser:any) => {
    try{
      const result = await auth().signInWithEmailAndPassword(returningUser?.email?.toLowerCase(), returningUser?.password)
      // if(result){
      //   console.log('login result',result)
      // }
    }
    catch(err:any){
      console.log(err)
    }
  }

    const loginWithGoogle = () => {
      try{
        auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .then(() => {
          auth().signInWithPopup(googleProvider)
        })
      }
      catch(err:any){
        console.log(err)
      }
  };


const signup = async (newUser:any) => {
  try{
    const result = await auth().createUserWithEmailAndPassword(newUser?.email.toLowerCase(), newUser?.password)
    if(result){
      console.log(result)
    }
  }
  catch(err:any){
    console.log(err)
  }
}

const signupWithGoogle = () => {
  try{
    auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
    .then(() => {
      auth().signInWithPopup(googleProvider)
    })
  }
  catch(err:any){
    console.log(err)
  }
};


  const getToken = async () => {
      try{
        const result = await auth().currentUser.getIdToken(true)
        if(result){
          setToken(result);
        }
      }
      catch(err:any){
        console.log(err)
      }
  };

  const updateAuthUserProfile = (updatedAuthUser: any) => {
    try{
      const result = auth().currentUser.updateProfile(updatedAuthUser)
      if(result){
        getCurrentUser();
      }
    }
    catch(err:any){
      console.log(err)
    }
   
  };



  const sendEmailVerification = () => {
    setError(null);
    setSuccess(null);
    auth().currentUser
      .sendEmailVerification()
      .then((response) => {
        setSuccess({
          code: "auth/email-sent",
          message: "Verification Email has been sent!",
        });
      })
      .catch((err) => {
        setError(err);
      });
  };

  const sendPasswordResetEmail = (email: any) => {
    setError(null);
    setSuccess(null);
    auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        setSuccess({
          code: "auth/password-reset-email",
          message: "Password Reset Email has been sent!",
        });
      })
      .catch((err) => {
        setError(err);
      });
  };



  const updateEmail = async (email:any, password:any,newEmail:any) => {
    try{
      const result = await auth().signInWithEmailAndPassword(email, password)
      if(result){
        const result2 = result.user.updateEmail(newEmail)
        if(result2){
          setSuccess({
            code: "auth/update-email",
            message: "Email has been updated! Please verify new email!",
          });
        }
      }
    }
    catch(err:any){
      console.log(err)
    }
  };

  const updatePassword = async (email:any, password:any,newPassword:any) => {
    try{
      const result = await auth().signInWithEmailAndPassword(email, password)
      if(result){
        const result2 = result.user.updatePassword(newPassword)
        if(result2){
          setSuccess({
            code: "auth/update-password",
            message: "Password has been updated!",
          });
        }
      }
    }
    catch(err:any){
      console.log(err)
    }
  };



  const logout = async () =>{
    try{
      auth().signOut().then(()=>{
        setCurrentUser(null)
      })
    }
    catch(err:any){
      console.log(err)
    }
  }




  if (authPending) {
    return <Loading/>;
  }

  return (
    <AuthContext.Provider
      value={{
        signup,
        signupWithGoogle,
        login,
        loginWithGoogle,
        logout,
        currentUser,
        authPending,
        error,
        success,
        sendEmailVerification,
        sendPasswordResetEmail,
        earlyAdopter,
        updateEmail,
        setSuccess,
        updatePassword,
        updateAuthUserProfile,
        getCurrentUser
      }}
    >
      {params.children}
    </AuthContext.Provider>
  );
};


export const Auth = () => useContext(AuthContext);
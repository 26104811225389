import {
  CaretDown,
  Pen,
  Rocket,
  Times,
  UserCircle,
} from "@styled-icons/fa-solid";
import imageCompression from "browser-image-compression";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "../components/Button";
import { Card } from "../components/Card";
import { Form } from "../components/Form";
import { Icon } from "../components/Icon";
import { Image } from "../components/Image";
import { Modal } from "../components/Modal";
import { Page } from "../components/Page";
import { Text } from "../components/Text";
import { TextInput } from "../components/TextInput";
import { Toast } from "../components/Toast";
import { View } from "../components/View";
import { Dimensions } from "../contexts";
import { Auth } from "../contexts/AuthProvider";
import { Firestore } from "../contexts/FirestoreProvider";
import { Storage } from "../contexts/StorageProvider";
import {
  validateBirthdate,
  validateEmail,
  validatePassword,
} from "../helpers/Validation";

import {logo} from '../assets/images/index'

export function Settings(props: any) {
  const {
    currentUser,
    updateEmail,
    sendEmailVerification,
    error,
    success,
    setSuccess,
    authPending,
    updatePassword,
    getCurrentUser,
  } = Auth();
  
  const { currentUserProfile,
    accountSetupNeeded,
     updateCurrentUserProfile, 
     validateNickname,
     getCurrentUserProfile
    } =
    Firestore();
  const { uploadProfilePhoto, deleteProfilePhoto, getPhotoURL } = Storage();
  const { width } = Dimensions();
  const { mobile, tablet, desktop, laptop } = Dimensions();
  const history = useHistory();

  const [newEmail, setNewEmail] = useState<string | null>();
  const [currentPasswordEmail, setCurrentPasswordEmail] =
    useState<string | null>();
  const [currentPasswordPassword, setCurrentPasswordPassword] =
    useState<string | null>();
  const [newPassword, setNewPassword] = useState<string | null>();
  const [newNickname, setNewNickname] = useState<string | null>();
  const [newNicknameTimer, setNewNicknameTimer] = useState<any | number>(0);
  const [validNewNickname, setValidNewNickname] = useState({
    valid: false,
    message: null,
  });
  
  const [verificationDisabled, setVerificationDisabled] = useState(true);
  const [emailProvider, setEmailProvider] = useState<boolean>(false);

  const [validNewEmail, setValidNewEmail] = useState(null);
  const [validNewPassword, setValidNewPassword] = useState(null);
  const [validCurrentPasswordEmail, setValidCurrentPasswordEmail] =
    useState(null);
  const [validCurrentPasswordPassword, setValidCurrentPasswordPassword] =
    useState(null);

  const [createUserProfileDisabled, setCreateUserProfileDisabled] =
    useState<boolean>(true);
  const [timer, setTimer] = useState<any | number>(0);
  const [hiddenPassword, setHiddenPassword] = useState<boolean>(true);
  const [hideShowChangeNickname, setHideShowChangeNickname] =
    useState<boolean>(false);

  const [hideShowChangeEmail, setHideShowChangeEmail] =
    useState<boolean>(false);

  const [hideShowChangePassword, setHideShowChangePassword] =
    useState<boolean>(false);

  const [imageAsFile, setImageAsFile] = useState(null);
  const [imageAsFilePreview, setImageAsFilePreview] = useState(null);

  const [showAvatarModal, setShowAvatarModal] = useState(false);

  
  const [newPasswordTimer, setNewPasswordTimer] = useState<any | number>(0);
  const [newEmailTimer, setNewEmailTimer] = useState<any | number>(0);

  const [currentPasswordEmailTimer, setCurrentPasswordEmailTimer] =
    useState<any | number>(0);
  const [currentPasswordPasswordTimer, setCurrentPasswordPasswordTimer] =
    useState<any | number>(0);

  const [currentPasswordEmailValidation, setCurrentPasswordEmailValidation] =
    useState<any | null>({ valid: null, message: null });
  const [
    currentPasswordPasswordValidation,
    setCurrentPasswordPasswordValidation,
  ] = useState<any | null>({ valid: null, message: null });

  const [newEmailValidation, setNewEmailValidation] = useState<any | null>({
    valid: null,
    message: null,
    tooltip: null,
  });

  const [newPasswordValidation, setNewPasswordValidation] = useState<
    any | null
  >({ valid: null, message: null });

  const [newNicknameValidation, setNewNicknameValidation] = useState<
    any | null
  >({ valid: null, message: null });

  const [actionText, setActionText] = useState("Send Verification Email");

  const handleImageAsFile = (event: any) => {
    const imageFile = event.target.files[0];

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 800,
      useWebWorker: true,
    };


    imageCompression(imageFile, options)
      .then(function (compressedFile) {
        setImageAsFilePreview(URL.createObjectURL(compressedFile));
        setImageAsFile((imageFile) => compressedFile);
      })
      .catch(function (error) {
        console.log('error', error)
        console.log(error.message);
      });
  };

  useEffect(() => {
    currentUser?.providerData.map((provider: any) => {
      if (provider?.providerId === "password") {
        setEmailProvider(true);
      }
    });
  }, []);

  useEffect(() => {
    if (currentUser?.emailVerified) {
      setVerificationDisabled(false);
    } else {
      setVerificationDisabled(true);
    }

  }, [currentUser]);


  useEffect(() => {
    if (accountSetupNeeded) {
      history.push("/accountsetup");
    }
  }, [accountSetupNeeded]);

  useEffect(() => {
    if (validNewNickname) {
      setNewNicknameTimer(0);
      setNewNicknameValidation({
        valid: validNewNickname.valid,
        message: validNewNickname.message,
      });
    } else {
      setNewNicknameValidation({ valid: null, message: null });
    }
  }, [validNewNickname]);

  useEffect(() => {
    if (validNewEmail === true) {
      setNewEmailValidation({ valid: validNewEmail, message: null });
    } else if (validNewEmail === false) {
      setNewEmailValidation({ valid: validNewEmail, message: "Invalid" });
    } else {
      setNewEmailValidation({ valid: null, message: null });
    }
  }, [validNewEmail]);

  useEffect(() => {
    if (validNewPassword === true) {
      setNewPasswordValidation({ valid: validNewPassword, message: null });
    } else if (validNewPassword === false) {
      setNewPasswordValidation({
        valid: validNewPassword,
        message: "Invalid",
      });
    } else {
      setNewPasswordValidation({ valid: null, message: null });
    }
  }, [validNewEmail]);

  useEffect(() => {
    if (validCurrentPasswordEmail === true) {
      setCurrentPasswordEmailValidation({
        valid: validCurrentPasswordEmail,
        message: null,
      });
    } else if (validCurrentPasswordEmail === false) {
      setCurrentPasswordEmailValidation({
        valid: validCurrentPasswordEmail,
        message: "Invalid",
      });
    } else {
      setCurrentPasswordEmailValidation({ validity: null, message: null });
    }
  }, [validCurrentPasswordEmail]);

  useEffect(() => {
    if (validCurrentPasswordPassword === true) {
      setCurrentPasswordPasswordValidation({
        valid: validCurrentPasswordPassword,
        message: null,
      });
    } else if (validCurrentPasswordPassword === false) {
      setCurrentPasswordPasswordValidation({
        valid: validCurrentPasswordPassword,
        message: "Invalid",
      });
    } else {
      setCurrentPasswordPasswordValidation({ validity: null, message: null });
    }
  }, [validCurrentPasswordPassword]);

  useEffect(() => {
    if (validNewPassword === true) {
      setNewPasswordValidation({
        valid: validNewPassword,
        message: null,
        tooltip: null,
      });
    } else if (validNewPassword === false) {
      setNewPasswordValidation({
        valid: validNewPassword,
        message: "Password is invalid",
        tooltip: [
          "Password Requirements:",
          "- 8 characters long",
          "- 1 uppercase character",
          "- 1 lowercase character",
          "- 1 special character",
          "- 1 number",
        ],
      });
    } else {
      setNewPasswordValidation({
        valid: null,
        message: null,
        tooltip: null,
      });
    }
  }, [validNewPassword]);


  return (
    <Page pageTitle="Settings | CRAWLR" pageDescription="Settings">
      {success ? (
        <Toast type="success" background="success">
          {success.message}
        </Toast>
      ) : null}
      {error ? (
        <Toast type="error" background="error">
          {error.message}
        </Toast>
      ) : null}
      {!currentUser.emailVerified ? (
        <Toast
          type="Notification"
          actionText={actionText}
          close={false}
          action={true}
          onAction={() => sendEmailVerification()}
          background="primary"
        >
          Your email needs to be verified
        </Toast>
      ) : null}


      {showAvatarModal ? (
        <Modal
          onClick={() => {
            setShowAvatarModal(!showAvatarModal);
          }}
          title="Change Profile Photo"
          background="white"
        >
          <View flexFlow="row wrap" justifyContent="center">
            {!imageAsFile ? (
              <View
                padding="0 1vw 0 1vw"
                flexFlow="column wrap"
                justifyContent="center"
              >
                {currentUserProfile?.photoURL ? (
                  <Image
                    borderRadius="50%"
                    src={currentUserProfile?.photoURL}
                    width="100px"
                    height="100px"
                    alt="User profile photo"
                  />
                ) : (
                  <Icon size="100px" color="placeholder">
                    <UserCircle />
                  </Icon>
                )}
                <Text
                  padding="1rem 0 1rem 0"
                  textAlign="center"
                  fontSize="0.85rem"
                  color="black"
                >
                  Current
                </Text>
                <Text textAlign="center" fontSize="0.85rem" color="black">
                  Please choose a profile image that has equal height and width.
                </Text>
              </View>
            ) : (
              <View
                flexFlow="column wrap"
                justifyContent="center"
                padding="0 1vw 0 1vw"
              >
                {imageAsFilePreview ? (
                  <Image
                    borderRadius="50%"
                    src={imageAsFilePreview}
                    width="100px"
                    height="100px"
                    alt="User profile photo preview"
                  />
                ) : null}
                {imageAsFilePreview ? (
                  <Text textAlign="center" fontSize="0.85rem" color="black">
                    Preview
                  </Text>
                ) : null}
              </View>
            )}
          </View>

          {!currentUserProfile?.photoURL ? (
            <TextInput
              id="file"
              validation={{ validity: null, message: null }}
              onClear={null}
              placeholder="Browse"
              valueLabel={imageAsFile ? imageAsFile.name : "No file selected"}
              type="file"
              onChange={handleImageAsFile}
            />
          ) : null}

          {currentUserProfile?.photoURL ? (
            <Button
              buttonType="text"
              size="large"
              color="error"
              disabled={!currentUserProfile?.photoURL}
              onClick={() => {
                deleteProfilePhoto();
                setShowAvatarModal(!showAvatarModal);
                getCurrentUserProfile()
              }}
            >
              Delete Photo
            </Button>
          ) : (
            <Button
              buttonType="fill"
              size="large"
              background="primary"
              disabled={!imageAsFile}
              onClick={() => {
                uploadProfilePhoto(imageAsFile);
                setImageAsFile(null);
                setImageAsFilePreview(null);
                setShowAvatarModal(!showAvatarModal);
                getCurrentUserProfile()
              }}
            >
              Upload Photo
            </Button>
          )}
        </Modal>
      ) : null}

      <View padding="3vh 0 0 0">
        <Card background="white" minWidth="285px" width="20%" margin="0 auto">
          <View flexFlow="row wrap" justifyContent="center">
            <Icon size="1.5rem" color="primary">
              <Rocket />
            </Icon>
            <Text
              padding="0 .5vw 0 .5vw"
              fontWeight="700"
              fontFamily="Brandon Grotesque"
              color="primary"
              fontSize="1.25rem"
            >
              Early Adopter
            </Text>
          </View>

          <View flexFlow="row wrap" justifyContent="space-around">
            <View flex="1" flexFlow="row wrap" justifyContent="center">
              {currentUserProfile?.photoURL ? 
                <Image
                  borderRadius="50%"
                  src={currentUserProfile?.photoURL}
                  width="100px"
                  height="100px"
                  alt="User profile photo"
                  onError={(e) => {
                    e.target.src !==
                    logo
                      ? (e.target.src =
                          logo)
                      : null;
                  }}
                />
               : 
                <Icon size="100px" color="placeholder">
                  <UserCircle />
                </Icon>
              }

              {!verificationDisabled ? (
                <Icon
                  margin="-25% 0 0 -25px"
                  disabled={verificationDisabled}
                  onClick={() => {
                    setShowAvatarModal(!showAvatarModal);
                  }}
                  width="30px"
                  height="30px"
                  background="body"
                  borderRadius="50%"
                  size={"1rem"}
                  color="gray"
                >
                  <Pen />
                </Icon>
              ) : null}
            </View>
            <View flex="1">
              <View flexFlow="column wrap">
                <Text
                  textAlign={mobile ? "center" : "left"}
                  fontWeight="700"
                  fontFamily="Brandon Grotesque"
                  color="black"
                  fontSize="1.25rem"
                >
                  {currentUserProfile?.nickname}
                </Text>
                <Text
                  textAlign={mobile ? "center" : "left"}
                  fontFamily="Brandon Grotesque"
                  color="black"
                  fontSize="1.25rem"
                >
                  {currentUserProfile?.username}
                </Text>
                <Text
                  textAlign={mobile ? "center" : "left"}
                  fontFamily="Brandon Grotesque"
                  color="black"
                  fontSize="1.25rem"
                >
                  {currentUser?.email}
                </Text>
              </View>
            </View>
          </View>

          <View
            margin="2vh 0 0 0"
            borderRadius="18px"
            background="white"
            borderWidth="1px"
            borderColor="black"
          >
            <Button
              onClick={() => {
                setHideShowChangeNickname(!hideShowChangeNickname);
              }}
              buttonType="fill"
              size="extralarge"
              background="white"
              color="black"
            >
              <View flexFlow="row wrap" justifyContent="space-between">
                <Icon color="black" size="16px"></Icon>
                Change Nickname
                {hideShowChangeNickname ? (
                  <Icon color="black" size="16px">
                    <Times />
                  </Icon>
                ) : (
                  <Icon color="black" size="16px">
                    <CaretDown />
                  </Icon>
                )}
              </View>
            </Button>

            {hideShowChangeNickname ? (
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  updateCurrentUserProfile({ docID:currentUser.uid, nickname: newNickname })
                    .then(() => {
                      setNewNickname(null);
                      setHideShowChangeNickname(!hideShowChangeNickname);
                    })
                    .catch((error: any) => {
                      console.log(error);
                    });
                }}
              >
                <TextInput
                  required
                  onKeyUp={(e) => {
                    if (newNicknameTimer) {
                      clearTimeout(newNicknameTimer);
                    }
                    setNewNicknameTimer(
                      setTimeout(() => {
                        if (newNickname) {
                          validateNickname(newNickname)
                            .then((success: any) => {
                              setValidNewNickname(success);
                            })
                            .catch((error: any) => {
                              console.log(error);
                            });
                        } else {
                          setValidNewNickname(null);
                          setNewNicknameValidation({
                            validity: null,
                            message: null,
                          });
                        }
                      }, 1000)
                    );
                  }}
                  onClear={() => {
                    setNewNickname(null);
                    setNewNicknameValidation({ validity: null, message: null });
                  }}
                  validation={newNicknameValidation}
                  type="text"
                  placeholder="New Nickname"
                  value={newNickname ? newNickname : ""}
                  onChange={(e) => setNewNickname(e.target.value)}
                  disabled={verificationDisabled}
                />


                <Button
                  buttonType="fill"
                  size="large"
                  background="primary"
                  disabled={
                    !newNickname ||
                    !validNewNickname ||
                    newNicknameTimer > 0 ||
                    !validNewNickname?.valid
                  }
                >
                  Update Nickname
                </Button>
              </Form>
            ) : null}
          </View>

          <View
            margin="2vh 0 0 0"
            borderRadius="18px"
            background="white"
            borderWidth="1px"
            borderColor="black"
          >
            <Button
              onClick={() => {
                setHideShowChangeEmail(!hideShowChangeEmail);
              }}
              buttonType="fill"
              size="extralarge"
              background="white"
              color="black"
            >
              <View flexFlow="row wrap" justifyContent="space-between">
                <Icon color="black" size="16px"></Icon>
                Change Email
                {hideShowChangeNickname ? (
                  <Icon color="black" size="16px">
                    <Times />
                  </Icon>
                ) : (
                  <Icon color="black" size="16px">
                    <CaretDown />
                  </Icon>
                )}
              </View>
            </Button>

            {hideShowChangeEmail ? (
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  updateEmail(currentUser.email, currentPasswordEmail, newEmail);
                  setNewEmail(null);
                  setCurrentPasswordEmail(null);
                  setHideShowChangeEmail(!hideShowChangeEmail);
                }}
              >
                <TextInput
                  disabled={verificationDisabled}
                  onKeyUp={(e) => {
                    if (newEmailTimer) {
                      clearTimeout(newEmailTimer);
                    }
                    setNewEmailTimer(
                      setTimeout(() => {
                        if (newEmail) {
                          setValidNewEmail(validateEmail(newEmail));
                        } else {
                          setValidNewEmail(null);
                          setNewEmailValidation({
                            validity: null,
                            message: null,
                          });
                        }
                      }, 2000)
                    );
                  }}
                  onClear={() => {
                    setNewEmail(null);
                    setValidNewEmail(null);
                    setNewEmailValidation({ validity: null, message: null });
                  }}
                  type="email"
                  placeholder="New Email Address"
                  value={newEmail ? newEmail : ""}
                  onChange={(e) => setNewEmail(e.target.value)}
                  required
                  validation={newEmailValidation}
                />

                <TextInput
                  onKeyUp={(e) => {
                    if (currentPasswordEmailTimer) {
                      clearTimeout(currentPasswordEmailTimer);
                    }
                    setCurrentPasswordEmailTimer(
                      setTimeout(() => {
                        if (currentPasswordEmail) {
                          setValidCurrentPasswordEmail(
                            validatePassword(currentPasswordEmail)
                          );
                        } else {
                          setValidCurrentPasswordEmail(null);
                          setCurrentPasswordEmailValidation({
                            validity: null,
                            message: null,
                          });
                        }
                      }, 2000)
                    );
                  }}
                  onClear={() => {
                    setCurrentPasswordEmail(null);
                    setValidCurrentPasswordEmail(null);
                    setCurrentPasswordEmailValidation({
                      validity: null,
                      message: null,
                    });
                  }}
                  onToggle={() => {
                    setHiddenPassword(!hiddenPassword);
                  }}
                  type={hiddenPassword ? "password" : "text"}
                  placeholder="Current Password"
                  value={currentPasswordEmail ? currentPasswordEmail : ""}
                  onChange={(e: any) => setCurrentPasswordEmail(e.target.value)}
                  required
                  validation={currentPasswordEmailValidation}
                  password
                  disabled={verificationDisabled}
                />
                <Button
                  buttonType="fill"
                  size="large"
                  background="primary"
                  disabled={
                    !newEmail || !currentPasswordEmail || !validNewEmail || !currentPasswordEmailValidation.valid
                  }
                >
                  Update Email
                </Button>
              </Form>
            ) : null}
          </View>

          <View
            margin="2vh 0 0 0"
            borderRadius="18px"
            background="white"
            borderWidth="1px"
            borderColor="black"
          >
            <Button
              onClick={() => {
                setHideShowChangePassword(!hideShowChangePassword);
              }}
              buttonType="fill"
              size="extralarge"
              background="white"
              color="black"
            >
              <View flexFlow="row wrap" justifyContent="space-between">
                <Icon color="black" size="16px"></Icon>
                Change Password
                {hideShowChangeNickname ? (
                  <Icon color="black" size="16px">
                    <Times />
                  </Icon>
                ) : (
                  <Icon color="black" size="16px">
                    <CaretDown />
                  </Icon>
                )}
              </View>
            </Button>

            {hideShowChangePassword ? (
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  updatePassword(
                    currentUser.email,
                    currentPasswordPassword,
                    newPassword
                  );
                  setNewPassword(null);
                  setCurrentPasswordPassword(null);
                  setHideShowChangePassword(!hideShowChangePassword);
                }}
              >
                <TextInput
                  disabled={verificationDisabled}
                  onKeyUp={(e) => {
                    if (newPasswordTimer) {
                      clearTimeout(newPasswordTimer);
                    }
                    setNewPasswordTimer(
                      setTimeout(() => {
                        if (newPassword) {
                          setValidNewPassword(validatePassword(newPassword));
                        } else {
                          setValidNewPassword(null);
                          setNewPasswordValidation({
                            validity: null,
                            message: null,
                          });
                        }
                      }, 2000)
                    );
                  }}
                  onClear={() => {
                    setNewPassword(null);
                    setValidNewPassword(null);
                    setNewPasswordValidation({ validity: null, message: null });
                  }}
                  onToggle={() => {
                    setHiddenPassword(!hiddenPassword);
                  }}
                  type={hiddenPassword ? "password" : "text"}
                  placeholder="New Password"
                  value={newPassword}
                  onChange={(e: any) => setNewPassword(e.target.value)}
                  required
                  validation={newPasswordValidation}
                  password
                />

                <TextInput
                  disabled={verificationDisabled}
                  onKeyUp={(e) => {
                    if (currentPasswordPasswordTimer) {
                      clearTimeout(currentPasswordPasswordTimer);
                    }
                    setCurrentPasswordPasswordTimer(
                      setTimeout(() => {
                        if (currentPasswordPassword) {
                          setValidCurrentPasswordPassword(
                            validatePassword(currentPasswordPassword)
                          );
                        } else {
                          setValidCurrentPasswordPassword(null);
                          setCurrentPasswordPasswordValidation({
                            validity: null,
                            message: null,
                          });
                        }
                      }, 2000)
                    );
                  }}
                  onClear={() => {
                    setCurrentPasswordPassword(null);
                    setValidCurrentPasswordPassword(null);
                    setCurrentPasswordPasswordValidation({
                      validity: null,
                      message: null,
                    });
                  }}
                  onToggle={() => {
                    setHiddenPassword(!hiddenPassword);
                  }}
                  type={hiddenPassword ? "password" : "text"}
                  placeholder="Current Password"
                  value={currentPasswordPassword ? currentPasswordPassword : ""}
                  onChange={(e: any) =>
                    setCurrentPasswordPassword(e.target.value)
                  }
                  required
                  validation={currentPasswordPasswordValidation}
                  password
                />

                <Button
                  buttonType="fill"
                  size="large"
                  background="primary"
                  disabled={
                    !newPassword ||
                    !currentPasswordPassword ||
                    !validNewPassword || !currentPasswordPasswordValidation.valid
                  }
                >
                  Update Password
                </Button>
              </Form>
            ) : null}
          </View>
        </Card>
      </View>
    </Page>
  );
}

{
  /* <View>
       <Toggle
          color={"primaryDark"LightFlip[theme]}
          mode={themeMode}
          onClick={toggleTheme}
          theme="theme"
        />
        </View> */
}

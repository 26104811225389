import { DefaultTheme } from "styled-components";

const light = {
  themeColors: {
    background: "#F1F3F4",
    body: "#1B2836",
  },
};

const dark = {
  themeColors: {
    background: "#1B2836",
    body: "#F1F3F4",
  },
};

const defaultTheme = {
  themeColors: {
    background: "null",
    body: "null",
  },
  standardColors: {
    neutralBackground: "#9DAEC1",
    inputBackground: "#E4EBF1",
    placeholder: "#7E92A7",
    primary: "#247FD3",
    secondary: "#2CBE71",
    success: "#2CBE71",
    error: "#DA0707",
    primaryGradient: "linear-gradient(to bottom right, #6CFFB2, #7EC1FF)",
    primaryLight: "#7EC1FF",
    primaryDark: "#004483",
    disabled: "#7E92A7",
    black: "#202020",
    white: "#FAFAFA",
    gray: "#A0A2A6",
    grey: "#A0A2A6",
    darkgray: "#40566F",
    darkgrey: "#40566F",
  },
  buttons: {
    fontWeight: "700",
    textTransform: "uppercase",
    fontFamily: "Brandon Grotesque",
    minHeight: "16px",
    outline: {
      border: "2px solid",
      borderRadius: "18px",
      padding: "17.5px 20px 17.5px 20px",
      margin: "0",
    },
    fill: {
      border: "none",
      borderRadius: "18px",
      padding: "17.5px 20px 17.5px 20px",
      margin: "0",
    },
    text: {
      border: "none",
      borderRadius: "0",
      padding: "0",
      margin: "1vh 1vw 1vh 1vw",
    },
    small: {
      minWidth: "120px",
    },
    large: {
      minWidth: "150px",
    },
    extralarge: {
      minWidth: "275px",
    },
  },
  links: {
    fontWeight: "700",
    textTransform: "uppercase",
    fontFamily: "Brandon Grotesque",
    minWidth: "150px",
    minHeight: "16px",
    outline: {
      border: "2px solid",
      borderRadius: "18px",
      padding: "17.5px 20px 17.5px 20px",
      margin: "1vh 1vw 1vh 1vw",
    },
    fill: {
      border: "none",
      borderRadius: "18px",
      padding: "17.5px 20px 17.5px 20px",
      margin: "1vh 1vw 1vh 1vw",
    },
    text: {
      border: "none",
      borderRadius: "0",
      padding: "0",
      margin: "1vh .5vw 1vh .5vw",
    },
    crawlr:{
      
    }
  },
};

export const lightTheme: DefaultTheme = { ...defaultTheme, ...light };
export const darkTheme: DefaultTheme = { ...defaultTheme, ...dark };

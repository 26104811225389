// global.js
// Source: https://github.com/maximakymenko/react-day-night-toggle-app/blob/master/src/global.js#L23-L41
import React from "react";
import ReactDOM from "react-dom";
import { createGlobalStyle } from "styled-components";
import BrandonGrotesqueRegular from "../assets/fonts/BrandonGrotesque-Regular.otf";
import BrandonGrotesqueLight from "../assets/fonts/BrandonGrotesque-Light.otf";
import BrandonGrotesqueMedium from "../assets/fonts/BrandonGrotesque-Medium.otf";
import BrandonGrotesqueBold from "../assets/fonts/BrandonGrotesque-Bold.otf";
import BrandonGrotesqueBlack from "../assets/fonts/BrandonGrotesque-Black.otf";

import PoppinsRegular from "../assets/fonts/Poppins-Regular.ttf";
import PoppinsLight from "../assets/fonts/Poppins-Light.ttf";
import PoppinsMedium from "../assets/fonts/Poppins-Medium.ttf";
import PoppinsBold from "../assets/fonts/Poppins-Bold.ttf";
import PoppinsBlack from "../assets/fonts/Poppins-Black.ttf";

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-display: block;
    font-family: Brandon Grotesque;
    font-weight: 900;
    src: url(${BrandonGrotesqueBlack}) format('opentype');
  }
  
  @font-face {
    font-display: block;
    font-family: Brandon Grotesque;
    font-weight: 700;
    src: url(${BrandonGrotesqueBold}) format('opentype');
  }
  
  @font-face {
    font-display: block;
    font-family: Brandon Grotesque;
    font-weight: 500;
    src: url(${BrandonGrotesqueMedium}) format('opentype');
  }
  
  @font-face {
    font-display: block;
    font-family: Brandon Grotesque;
    font-weight: 400;
    src: url(${BrandonGrotesqueRegular}) format('opentype');
  }
  
  @font-face {
    font-display: block;
    font-family: Brandon Grotesque;
    font-weight: 300;
    src: url(${BrandonGrotesqueLight}) format('opentype');
  }

  @font-face {
    font-display: block;
    font-family: Poppins;
    font-weight: 900;
    src: url(${PoppinsBlack}) format('truetype');
  }
  
  @font-face {
    font-display: block;
    font-family: Poppins;
    font-weight: 700;
    src: url(${PoppinsBold}) format('truetype');
  }
  
  @font-face {
    font-display: block;
    font-family: Poppins;
    font-weight: 500;
    src: url(${PoppinsMedium}) format('truetype');
  }
  
  @font-face {
    font-display: block;
    font-family: Poppins;
    font-weight: 400;
    src: url(${PoppinsRegular}) format('truetype');
  }
  
  @font-face {
    font-display: block;
    font-family: Poppins;
    font-weight: 300;
    src: url(${PoppinsLight}) format('truetype');
  }
  
  html {
    margin:0;
    padding:0;
    font-family:Poppins;
    font-size:16px;
    font-weight:400;
  }

  body {
    margin:0;
    padding:0;
    font-family:Poppins;
    font-size:16px;
    font-weight:400;
    background:${(props) => props.theme.themeColors.background};
  }


  `;

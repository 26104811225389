import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

type ViewType = {
  flex?: string;
  justifyContent?: string;
  padding?: string;
  margin?: string;
  background?: string;
  id?: string;
  width?: string;
  maxWidth?: string;
  minWidth?: string;
  maxHeight?: string;
  minHeight?: string;
  position?: string;
  borderWidth?: string;
  borderColor?: string;
  cursor?: string;
  to?: string;
  zIndex?: number;
  borderRadius?: string;
  height?: string;
  color?: string;
  alignItems?: string;
  top?: string;
  left?: string;
  right?: string;
  flexFlow?: string;
  border?: string;
  type?: string;
  borderBottom?: string;
  borderTop?: string;
  borderLeft?: string;
  borderRight?: string;
  display?: string;
  disabled?: boolean;
  click?: () => void;
  boxShadow?: string;
  overflow?: string;
};

const ViewContainer = styled.div<ViewType>`
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex;
  flex-flow: ${(props) => props.flexFlow || "column wrap"};
  justify-content: ${(props) => props.justifyContent || null};
  flex: ${(props) => props.flex || "1 1 auto"};
  align-items: ${(props) =>
    props.flexFlow && props.flexFlow.includes("row") ? "center" : null};
  background: ${(props) => (!props.background ? "transparent" : null)};
  background: ${(props) =>
    props.background &&
    props.background !== "body" &&
    props.background !== "background"
      ? props.theme.standardColors[props.background]
      : null};
  background: ${(props) =>
    props.background &&
    (props.background === "body" || props.background === "background")
      ? props.theme.themeColors[props.background]
      : null};
  padding: ${(props) => props.padding || null};
  border-width: ${(props) => props.borderWidth || null};
  border-color: ${(props) =>
    props.borderColor ? props.theme.standardColors[props.borderColor] : null};
  border-style: ${(props) => (props.borderWidth ? "solid" : null)};
  position: ${(props) => props.position || null};
  top: ${(props) => props.top || null};
  left: ${(props) => props.left || null};
  right: ${(props) => props.right || null};
  height: ${(props) => props.height || null};
  width: ${(props) => props.width || null};
  margin: ${(props) => props.margin || null};
  z-index: ${(props) => props.zIndex || null};
  max-height: ${(props) => props.maxHeight || null};
  min-height: ${(props) => props.minHeight || null};
  width: ${(props) => props.width || null};
  max-width: ${(props) => props.maxWidth || null};
  min-width: ${(props) => props.minWidth || null};
  border-radius: ${(props) => props.borderRadius || null};
  cursor: ${(props) => props.cursor || null};
  cursor: ${(props) => (props.click || props.to ? "pointer" : null)};
  box-shadow: ${(props) => props.boxShadow || null};
  overflow: ${(props) => props.overflow || null};
`;

interface ViewProps {
  flex?: string;
  className?: string;
  justifyContent?: string;
  padding?: string;
  margin?: string;
  background?: string;
  id?: string;
  ref?: any;
  width?: string;
  onClick?: () => void;
  maxWidth?: string;
  minWidth?: string;
  maxHeight?: string;
  minHeight?: string;
  zIndex?: number;
  position?: string;
  borderWidth?: string;
  borderColor?: string;
  cursor?: string;
  to?: string;
  borderRadius?: string;
  height?: string;
  color?: string;
  alignItems?: string;
  top?: string;
  left?: string;
  right?: string;
  style?: any;
  flexFlow?: string;
  border?: string;
  type?: string;
  borderBottom?: string;
  borderTop?: string;
  borderLeft?: string;
  borderRight?: string;
  display?: string;
  column?: boolean;
  disabled?: boolean;
  click?: () => void;
  boxShadow?: string;
  overflow?: string;
}

export const View: React.FC<ViewProps> = ({
  to,
  onClick,
  children,
  ...rest
}) => {
  const history = useHistory();

  const checkClickType = () => {
    if (to && to.includes("http")) {
      return (window.location.href = to);
    } else if (to && !to.includes("http")) {
      return history.push(to);
    } else if (onClick) {
      return onClick();
    } else {
      return null;
    }
  };

  return (
    <ViewContainer click={onClick} to={to} onClick={checkClickType} {...rest}>
      {children}
    </ViewContainer>
  );
};

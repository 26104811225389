import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { View } from "./View";
import { Text } from "./Text";

interface FormProps {
  flex?: string;
  flexFlow?: string;
  justifyContent?: string;
  padding?: string;
  margin?: string;
  background?: string;
  id?: string;
  width?: string;
  onSubmit: (e: any) => void;
  children: any;
}

const FormContainer = styled.form<FormProps>`
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex;
  text-align: center;
  flex-flow: column wrap;
  padding: 0 1vw 0 1vw;
`;

export const Form: React.FC<FormProps> = ({ children, onSubmit, ...rest }) => {
  return (
    <FormContainer onSubmit={onSubmit} {...rest}>
      {children.map((child, index) => {
        return (
          <View
            padding="1vh 0 1vh 0"
            flexFlow="row wrap"
            justifyContent="center"
            key={index}
          >
            {child}
          </View>
        );
      })}
    </FormContainer>
  );
};

import React, {
  FC,
  useContext,
  useState,
  createContext,
  useEffect,
} from "react";
import window from "global";

function getDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

type DimensionsContextProps = {
  width: number;
  height: number;
  dimensions: any;
  mobile: any;
  tablet: any;
  laptop: any;
  desktop: any;
};

const DimensionsContext = createContext<DimensionsContextProps>({
  width: null,
  height: null,
  mobile: null,
  tablet: null,
  laptop: null,
  desktop: null,
  dimensions: { width: null, height: null },
});

export const DimensionsProvider: FC = ({ children }) => {
  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);
  const [dimensions, setDimensions] = useState(null);
  const [mobile, setMobile] = useState(false);
  const [tablet, setTablet] = useState(false);
  const [laptop, setLaptop] = useState(false);
  const [desktop, setDesktop] = useState(false);

  const handleResize = () => {
    // console.log('in handle resize', window.innerWidth, window.innerHeight)
    if (window.innerWidth < 700) {
      setMobile(true);
      setTablet(false);
      setLaptop(false);
      setDesktop(false);
    } else if (window.innerWidth < 1024) {
      setTablet(true);
      setMobile(false);
      setLaptop(false);
      setDesktop(false);
    } else if (window.innerWidth < 2048) {
      setLaptop(true);
      setMobile(false);
      setTablet(false);
      setDesktop(false);
    } else if (window.innerWidth > 2048) {
      setDesktop(true);
      setMobile(false);
      setTablet(false);
      setLaptop(false);
    }
  };
  // console.log(
  //   // "window:",
  //   // window,
  //   "screenWidth:",
  //   window.screen.width,
  //   "innerWidth:",
  //   window.innerWidth,
  //   "mobile:",
  //   mobile,
  //   "tablet:",
  //   tablet,
  //   "laptop:",
  //   laptop,
  //   "desktop:",
  //   desktop
  // );

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <DimensionsContext.Provider
      value={{ mobile, tablet, laptop, desktop, dimensions, width, height }}
    >
      {children}
    </DimensionsContext.Provider>
  );
};

export const Dimensions = () => useContext(DimensionsContext);

import React, { useContext, useState, useEffect, useReducer } from "react";
import { View } from "../components/View";
import { Text } from "../components/Text";
import { Link } from "../components/Link";
import { Footer } from "../components/Footer";
import { Image } from "../components/Image";
import { Accordion } from "../components/Accordion";
import { Button } from "../components/Button";
import { Icon } from "../components/Icon";

import { TextInput } from "../components/TextInput";
import { Page } from "../components/Page";
import { Form } from "../components/Form";
import { Toast, Modal } from "../components/index";
import { Auth } from "../contexts/AuthProvider";
import { useHistory, useLocation } from "react-router-dom";
import {
  validateBirthdate,
  validateEmail,
  validatePassword,
} from "../helpers/Validation";
import { Envelope, CaretDown, Times } from "@styled-icons/fa-solid";
import { Google } from "@styled-icons/fa-brands";
import { Googleadsense } from "styled-icons/simple-icons";

export function Login(props: any) {
  const {
    authPending,
    success,
    currentUser,
    login,
    logout,
    loginWithGoogle,
    error,
    sendPasswordResetEmail,
  } = Auth();
  const history = useHistory();
  const location = useLocation();
  const [returningUser, setReturningUser] = useReducer(
    (state:any, newState:any) => ({ ...state, ...newState }),
    {
      email: null,
      password: null
    }
  ); 

  const [hiddenPassword, setHiddenPassword] = useState(true);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [showEmailForm, setShowEmailForm] = useState<boolean>(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] =useState<boolean>(false);
  const [emailValidation, setEmailValidation] = useState<any | null>({
    validity: null,
    message: null,
  });
  const [passwordValidation, setPasswordValidation] = useState<any | null>({
    validity: null,
    message: null,
  });
  const [formValidation, setFormValidation] = useState<any | null>(null);
  const [validEmail, setValidEmail] = useState(null);
  const [validPassword, setValidPassword] = useState(null);
  const [emailTimer, setEmailTimer] = useState<any | number>(0);
  const [passwordTimer, setPasswordTimer] = useState<any | number>(0);


  const handleEmailOnBlur = (e) => {
    console.log(e);
    //setValidEmail(validateEmail(e))
  };

  useEffect(() => {
    if (validEmail === true) {
      setEmailValidation({ validity: validEmail, message: null });
    } else if (validEmail === false) {
      setEmailValidation({ validity: validEmail, message: "Email is invalid" });
    } else {
      setEmailValidation({ validity: null, message: null });
    }
  }, [validEmail]);

  useEffect(() => {
    if (validPassword === true) {
      setPasswordValidation({ validity: validPassword, message: null });
    } else if (validPassword === false) {
      setPasswordValidation({ validity: validPassword, message: null });
    } else {
      setPasswordValidation({ validity: null, message: null });
    }
  }, [validPassword]);

  useEffect(() => {
    if (validEmail === true) {
      setEmailValidation({ validity: validEmail, message: null });
    } else if (validEmail === false) {
      setEmailValidation({ validity: validEmail, message: "Email is invalid" });
    } else {
      setEmailValidation({ validity: null, message: null });
    }
  }, [validEmail]);

  return (
    <Page pageTitle="Log In | CRAWLR" pageDescription="Log In">
      {success ? (
        <Toast type="success" background="success">
          {success.message}
        </Toast>
      ) : null}
      {error ? (
        <Toast type="error" background="error">
          {error.message}
        </Toast>
      ) : null}
      <Toast type="reminder" background="disabled">
        All members of the previous iteration of CRAWLR must make new accounts.
      </Toast>

      {showForgotPasswordModal ? (
        <Modal
          onClick={() => {
            setShowForgotPasswordModal(!showForgotPasswordModal);
          }}
          title="Forgot Password"
          background="white"
        >
          <View>
            <Text textAlign="center" color="background">
              Please enter your email address
            </Text>
          </View>
          <View>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                setDisabled(true);
                sendPasswordResetEmail(returningUser.email);
                setReturningUser(null);
                setShowForgotPasswordModal(!showForgotPasswordModal);
              }}
            >
              <TextInput
                onKeyUp={(e) => {
                  if (emailTimer) {
                    clearTimeout(emailTimer);
                  }
                  setEmailTimer(
                    setTimeout(() => {
                      if (returningUser.email) {
                        setValidEmail(validateEmail(returningUser.email));
                      } else {
                        setValidEmail(null);
                        setEmailValidation({ validity: null, message: null });
                      }
                    }, 2000)
                  );
                }}
                validationColor={emailValidation.color}
                validationMessage={emailValidation.message}
                type="email"
                placeholder="Email Address"
                value={returningUser.email ? returningUser.email : ""}
                onChange={(e) => setReturningUser({['email']:e.target.value})}
                required
                validation={emailValidation}
              />

              <Button
                buttonType="fill"
                type="submit"
                size="large"
                disabled={!validEmail || !returningUser.email}
                background="primary"
              >
                Submit
              </Button>
            </Form>
          </View>
        </Modal>
      ) : null}
      <View justifyContent="center" flexFlow="row wrap" padding="3vh 3vw 0 3vw">
        <Text textAlign="center" fontSize="1.5rem" color="body">
          Welcome Back
        </Text>
      </View>
      <View padding="3vh 3vw 0 3vw" flexFlow="row wrap" justifyContent="center">
        <View flex="0 0 auto" borderRadius="18px" background="white">
          <View flexFlow="row wrap" justifyContent="center">
            <Button
              onClick={() => {
                setShowEmailForm(!showEmailForm);
              }}
              buttonType="fill"
              size="extralarge"
              background="white"
              color="black"
            >
              <View flexFlow="row wrap" justifyContent="space-between">
                <Icon color="black" size="16px">
                  <Envelope />
                </Icon>
                Log In With Email
                {showEmailForm ? (
                  <Icon color="black" size="16px">
                    <Times />
                  </Icon>
                ) : (
                  <Icon color="black" size="16px">
                    <CaretDown />
                  </Icon>
                )}
              </View>
            </Button>
          </View>

          {showEmailForm ? (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                login(returningUser);
              }}
            >
              <TextInput
                onKeyUp={(e) => {
                  if (emailTimer) {
                    clearTimeout(emailTimer);
                  }
                  setEmailTimer(
                    setTimeout(() => {
                      if (returningUser.email) {
                        setValidEmail(validateEmail(returningUser.email));
                      } else {
                        setValidEmail(null);
                        setEmailValidation({ validity: null, message: null });
                      }
                    }, 1000)
                  );
                }}
                onClear={() => {
                  setReturningUser(null);
                  setValidEmail(null);
                  setEmailValidation({ validity: null, message: null });
                }}
                type="email"
                placeholder="Email Address"
                value={returningUser.email ? returningUser.email : ""}
                onChange={(e) => setReturningUser({["email"]:e.target.value})}
                required
                validation={emailValidation}
              />

              <TextInput
                onKeyUp={(e) => {
                  if (passwordTimer) {
                    clearTimeout(passwordTimer);
                  }
                  setPasswordTimer(
                    setTimeout(() => {
                      if (returningUser.password) {
                        setValidPassword(validatePassword(returningUser.password));
                      } else {
                        setValidPassword(null);
                        setPasswordValidation({
                          validity: null,
                          message: null,
                        });
                      }
                    }, 1000)
                  );
                }}
                onClear={() => {
                  setReturningUser(null);
                  setValidPassword(null);
                  setPasswordValidation({ validity: null, message: null });
                }}
                onToggle={() => {
                  setHiddenPassword(!hiddenPassword);
                }}
                type={hiddenPassword ? "password" : "text"}
                placeholder="Password"
                value={returningUser.password ? returningUser.password : ""}
                onChange={(e: any) => setReturningUser({["password"]:e.target.value})}
                required
                validation={passwordValidation}
                password
              />

              <Button
                size="large"
                buttonType="text"
                type="button"
                onClick={() => {
                  setShowForgotPasswordModal(!showForgotPasswordModal);
                }}
                color="primary"
              >
                Forgot Password?
              </Button>

              <Button
                size="large"
                buttonType="fill"
                type="submit"
                onClick={() => {}}
                disabled={!returningUser.email || !validEmail || !returningUser.password}
                background="primary"
              >
                Submit
              </Button>
            </Form>
          ) : null}
        </View>
      </View>

      <View justifyContent="center" flexFlow="row wrap" padding="3vh 3vw 0 3vw">
        <View flexFlow="row wrap" justifyContent="center">
          <Button
            onClick={loginWithGoogle}
            buttonType="fill"
            size="extralarge"
            background="white"
            color="black"
          >
            <View flexFlow="row wrap" justifyContent="space-between">
              <Icon color="black" size="16px">
                <Google />
              </Icon>
              Log In With Google
              <Icon color="black" size="16px"></Icon>
            </View>
          </Button>
        </View>
      </View>

      <View justifyContent="center" flexFlow="row wrap" padding="3vh 3vw 0 3vw">
        <Text color="body">Don't have an account?</Text>
        <Link linkType="text" color="primary" href="/signup">
          Sign Up
        </Link>
      </View>
    </Page>
  );
}

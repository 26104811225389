import React from "react";
import { hero, scifi, zombie } from "../assets/images";
import { Image } from "../components/Image";
import { Link } from "../components/Link";
import { Page } from "../components/Page";
import { Text } from "../components/Text";
import { View } from "../components/View";
import { Dimensions } from "../contexts";
import { Auth } from "../contexts/AuthProvider";

export function GetStarted(props: any) {
  const { currentUser, 
    earlyAdopter, 
    success, error } = Auth();
  const { mobile, tablet, desktop, laptop } = Dimensions();

  return (
    <Page pageTitle="Get Started | CRAWLR" pageDescription="Get Started">
      <View
        padding="3vh 3vw 3vh 3vw"
        background="primaryGradient"
        justifyContent="space-around"
        flexFlow="row wrap"
      >
        <View></View>
        <View flexFlow="column wrap" justifyContent="center">
          <Image
            width="175px"
            src={hero}
            alt="Hero image. Little cartoon characters surrounding a phone under construction."
          />

          <Text
            fontFamily="Brandon Grotesque"
            fontWeight="700"
            fontSize="1.75rem"
            color="primaryDark"
            textAlign="center"
          >
            Mobile Apps Coming Soon!
          </Text>
          <Text
            fontFamily="Brandon Grotesque"
            fontWeight="700"
            fontSize="1rem"
            color="primaryDark"
            textAlign="center"
          >
            (Currently In Beta Test)
          </Text>
        </View>
        <View flex="1" flexFlow="row wrap">
          <Link
            textAlign="center"
            linkType="fill"
            background="secondary"
            href="/signup"
          >
            Sign Up
          </Link>
        </View>
      </View>
      <View padding="5vh" background="darkgray" flexFlow="column wrap">
        <View justifyContent="center" flex="1">
          <Text
            color="body"
            textAlign="center"
            fontFamily="Brandon Grotesque"
            fontWeight="700"
            fontSize="3rem"
          >
            Early Adoption
          </Text>
        </View>

        <View flexFlow={!mobile ? "row wrap" : "column wrap"}>
          <View flex="1" flexFlow="row wrap" justifyContent="center">
            <Image width="150px" src={zombie} alt="Zombie character." />
          </View>

          <View justifyContent="center" flex="2">
            {earlyAdopter ? (
              <View>
                <Text
                  color="body"
                  fontSize="2rem"
                  padding="1vh"
                  textAlign="center"
                >
                  You're an early adopter!
                </Text>
              </View>
            ) : (
              <View flexFlow="column wrap" justifyContent="center">
                <Text color="body" padding="1vh">
                  Our goal is simple: Help others play more roleplaying games by
                  connecting players to campaigns, one-shots and the like.
                  Starting out, we’re allowing users to create their account.
                  Later, you’ll receive email updates when additional features
                  become available.
                </Text>
                <Text color="body" fontSize="1.25rem" padding="1vh">
                  Pre-Launch Perks
                </Text>
                <Text color="body" padding="1vh">
                  * Enjoy one year of ad-free app usage once we launch in the
                  app store
                  <br />* Receive a nifty badge identifying you as an early
                  adopter (a little razzle dazzle)
                  <br />* Be eligible for early app testing
                  <br />* We’ll think you’re the bees’ knees
                </Text>
                <Text color="body" padding="1vh">
                  We’re slated to arrive in the app store soon. Sign
                  up today to be a part of our journey!
                </Text>
                <View
                  flexFlow="row wrap"
                  padding="1vh 0 1vh 0"
                  justifyContent="center"
                >
                  {" "}
                  <Link
                    textAlign="center"
                    linkType="fill"
                    background="secondary"
                    href="/signup"
                  >
                    Sign Up
                  </Link>
                </View>
              </View>
             )}
          </View>

          <View flex="1" flexFlow="row wrap" justifyContent="center">
            <Image width="150px" src={scifi} alt="Scifi character." />
          </View>
        </View>
      </View>
    </Page>
  );
}

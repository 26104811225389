import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { View } from "../View";
import { Image } from "../Image";

import {logo} from '../../assets/images/index'

const size = {
  mobile: "300px",
  tablet: "700px",
  laptop: "1024px",
};

const device = {
  mobile: `(min-width: ${size.mobile})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
};

type ViewType = {
  width?: number;
  height?: number;
};

const ViewContainer = styled.div<ViewType>`
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
  width: 100%;
  height: 100%;
`;

interface ViewProps {
  width?: number;
  height?: number;
}

export const Loading: React.FC<ViewProps> = (props) => {
  return (
    <View
      zIndex={100000}
      justifyContent="center"
      flexFlow="row wrap"
      padding="1vh 1vw 1vh 1vw"
    >
      <Image
        // className="rotate"
        src={logo}
        height="50px"
        alt="Logo Loading symbol"
      />
    </View>
  );
};

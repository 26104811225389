import styled from "styled-components";
import { NavHashLink } from "react-router-hash-link";

interface LinkProps {
  color?: string;
  linkType: string;
  href?: string;
  background?: string;
  borderColor?: string;
  disabled?: boolean;
  padding?: string;
  margin?: string;
  flex?: string;
  textAlign?: string;
}

export const Link = styled.a<LinkProps>`
  outline: none;
  cursor: pointer;
  text-decoration: none;
  font-weight: ${(props) => props.theme.links.fontWeight};
  text-transform: ${(props) =>
    props.linkType !== "text" ? props.theme.buttons.textTransform : null};
  font-family: ${(props) =>
    props.linkType !== "text" ? props.theme.buttons.fontFamily : "Poppins"};
  font-size: 1rem;

  padding: ${(props) =>
    props.padding || props.theme.links[props.linkType].padding};
  margin: ${(props) =>
    props.margin || props.theme.links[props.linkType].margin};
  border-radius: ${(props) => props.theme.links[props.linkType].borderRadius};

  min-width: ${(props) =>
    props.background ? props.theme.links.minWidth : null};
  min-height: ${(props) => props.theme.links.minHeight};

  border: ${(props) => (!props.borderColor ? "none" : null)};
  border: ${(props) =>
    props.borderColor &&
    props.borderColor !== "body" &&
    props.borderColor !== "background"
      ? `2px solid ${props.theme.standardColors[props.borderColor]}`
      : null};
  border: ${(props) =>
    props.borderColor &&
    (props.borderColor === "body" || props.borderColor === "background")
      ? `2px solid ${props.theme.themeColors[props.borderColor]}`
      : null};

  background: ${(props) => (!props.background ? "transparent" : null)};
  background: ${(props) =>
    props.background &&
    props.background !== "body" &&
    props.background !== "background"
      ? props.theme.standardColors[props.background]
      : null};
  background: ${(props) =>
    props.background &&
    (props.background === "body" || props.background === "background")
      ? props.theme.themeColors[props.background]
      : null};

  color: ${(props) => (!props.color ? props.theme.standardColors.white : null)};
  color: ${(props) =>
    props.color && props.color !== "body" && props.color !== "background"
      ? props.theme.standardColors[props.color]
      : null};
  color: ${(props) =>
    props.color && (props.color === "body" || props.color === "background")
      ? props.theme.themeColors[props.color]
      : null};

  :disabled {
    filter: grayscale(20%) opacity(50%);
    cursor: not-allowed;
  }

  :hover {
    filter: brightness(80%);
    text-decoration: ${(props) => (!props.background ? "underline" : "none")};
  }

  text-align: ${(props) => props.textAlign || null};
`;

import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { View } from "./View";
import { Dimensions } from "../contexts";

interface CardProps {
  background?: string;
  flex?: string;
  width?: string;
  margin?: string;
  minWidth?: string;
  children?: any;
}

export const Card: React.FC<CardProps> = ({ minWidth, children, ...rest }) => {
  const history = useHistory();

  const { mobile } = Dimensions();

  return (
    <View
      flexFlow="column wrap"
      borderRadius="18px"
      justifyContent="center"
      boxShadow="1px 1px 3px gray"
      minWidth={minWidth || "250px"}
      padding="1vh 3vw 3vh 3vw"
      {...rest}
    >
      {children?.length
        ? children?.map((value: any, index: any) => {
            return (
              <View
                padding="1vh 0 0 0"
                flexFlow="row wrap"
                justifyContent="center"
                key={index}
              >
                {value}
              </View>
            );
          })
        : children}
    </View>
  );
};

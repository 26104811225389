import React, { useContext } from "react";
import { View } from "../components/View";
import { Text } from "../components/Text";
import { Link } from "../components/Link";
import { Image } from "../components/Image";
import { Card } from "../components/Card";
import { Button } from "../components/Button";
import { Page } from "../components/Page";
import { Toast } from "../components/Toast";
import { Auth } from "../contexts/AuthProvider";

export function TermsConditions(props: any) {
  return (
    <Page pageTitle="Terms | CRAWLR" pageDescription="Terms & Conditions">
      <View
        padding="5vh"
        background="background"
        flexFlow="column wrap"
        justifyContent="center"
      >
        <Text
          padding="1vh"
          textAlign="left"
          color="body"
          fontFamily="Brandon Grotesque"
          fontSize="1.5rem"
        >
          Please read these Terms of Use (“Terms”, “Terms of Use”) carefully
          before using the https://crawlr.app website and the CRAWLR mobile
          application (together, or individually, the “Service”) operated by
          CRAWLR (“us”, “we”, or “our”). Your access to and use of the Service
          is conditioned upon your acceptance of and compliance with these
          Terms. These Terms apply to all visitors, users and others who wish to
          access or use the Service. By accessing or using the Service you agree
          to be bound by these Terms. If you disagree with any part of the terms
          then you do not have permission to access the Service.
        </Text>

        <Text
          padding="1vh"
          textAlign="left"
          color="body"
          fontFamily="Brandon Grotesque"
          fontSize="1.5rem"
        >
          Communications
        </Text>

        <Text padding="1vh" textAlign="left" color="body">
          By creating an Account on our service, you agree to subscribe to
          newsletters, marketing or promotional materials and other information
          we may send. However, you may opt out of receiving any, or all, of
          these communications from us by following the unsubscribe link or
          instructions provided in any email we send.
        </Text>

        <Text
          padding="1vh"
          textAlign="left"
          color="body"
          fontFamily="Brandon Grotesque"
          fontSize="1.5rem"
        >
          Purchases
        </Text>

        <Text padding="1vh" textAlign="left" color="body">
          If you wish to purchase any product or service made available through
          the Service (“Purchase”), you may be asked to supply certain
          information relevant to your Purchase including, without limitation,
          your credit card number, the expiration date of your credit card, your
          billing address, and your shipping information. You represent and
          warrant that: (i) you have the legal right to use any credit card(s)
          or other payment method(s) in connection with any Purchase; and that
          (ii) the information you supply to us is true, correct and complete.
          The service may employ the use of third party services for the purpose
          of facilitating payment and the completion of Purchases. By submitting
          your information, you grant us the right to provide the information to
          these third parties subject to our Privacy Policy. We reserve the
          right to refuse or cancel your order at any time for reasons including
          but not limited to: product or service availability, errors in the
          description or price of the product or service, error in your order or
          other reasons. We reserve the right to refuse or cancel your order if
          fraud or an unauthorized or illegal transaction is suspected.
        </Text>

        <Text
          padding="1vh"
          textAlign="left"
          color="body"
          fontFamily="Brandon Grotesque"
          fontSize="1.5rem"
        >
          Availability, Errors and Inaccuracies
        </Text>

        <Text padding="1vh" textAlign="left" color="body">
          We are constantly updating product and service offerings on the
          Service. We may experience delays in updating information on the
          Service and in our advertising on other web sites. The information
          found on the Service may contain errors or inaccuracies and may not be
          complete or current. Products or services may be mispriced, described
          inaccurately, or unavailable on the Service and we cannot guarantee
          the accuracy or completeness of any information found on the Service.
          We therefore reserve the right to change or update information and to
          correct errors, inaccuracies, or omissions at any time without prior
          notice.
        </Text>

        <Text
          padding="1vh"
          textAlign="left"
          color="body"
          fontFamily="Brandon Grotesque"
          fontSize="1.5rem"
        >
          Contests, Sweepstakes and Promotions
        </Text>

        <Text padding="1vh" textAlign="left" color="body">
          Any contests, sweepstakes or other promotions (collectively,
          “Promotions”) made available through the Service may be governed by
          rules that are separate from these Terms Conditions. If you
          participate in any Promotions, please review the applicable rules as
          well as our Privacy Policy. If the rules for a Promotion conflict with
          these Terms of Use, the Promotion rules will apply.
        </Text>

        <Text
          padding="1vh"
          textAlign="left"
          color="body"
          fontFamily="Brandon Grotesque"
          fontSize="1.5rem"
        >
          Subscriptions
        </Text>

        <Text padding="1vh" textAlign="left" color="body">
          Some parts of the Service are billed on a subscription basis
          (“Subscription(s)”). You will be billed in advance on a recurring and
          periodic basis (“Billing Cycle”). Billing cycles are set either on a
          monthly or annual basis, depending on the type of subscription plan
          you select when purchasing a Subscription. At the end of each Billing
          Cycle, your Subscription will automatically renew under the exact same
          conditions unless you cancel it or CRAWLR cancels it. You may cancel
          your Subscription renewal either through your online account
          management page or by contacting CRAWLR customer support team. A valid
          payment method, including credit card or PayPal, is required to
          process the payment for your Subscription. You shall provide CRAWLR
          with accurate and complete billing information including full name,
          address, state, zip code, telephone number, and a valid payment method
          information. By submitting such payment information, you automatically
          authorize CRAWLR to charge all Subscription fees incurred through your
          account to any such payment instruments. Should automatic billing fail
          to occur for any reason, CRAWLR will issue an electronic invoice
          indicating that you must proceed manually, within a certain deadline
          date, with the full payment corresponding to the billing period as
          indicated on the invoice.
        </Text>

        <Text
          padding="1vh"
          textAlign="left"
          color="body"
          fontFamily="Brandon Grotesque"
          fontSize="1.5rem"
        >
          Fee Changes
        </Text>

        <Text padding="1vh" textAlign="left" color="body">
          CRAWLR, in its sole discretion and at any time, may modify the
          Subscription fees for the Subscriptions. Any Subscription fee change
          will become effective at the end of the then-current Billing Cycle.
          CRAWLR will provide you with a reasonable prior notice of any change
          in Subscription fees to give you an opportunity to terminate your
          Subscription before such change becomes effective. Your continued use
          of the Service after the Subscription fee change comes into effect
          constitutes your agreement to pay the modified Subscription fee
          amount.
        </Text>

        <Text
          padding="1vh"
          textAlign="left"
          color="body"
          fontFamily="Brandon Grotesque"
          fontSize="1.5rem"
        >
          Refunds
        </Text>

        <Text padding="1vh" textAlign="left" color="body">
          Certain refund requests for Subscriptions may be considered by CRAWLR
          on a case-by-case basis and granted in sole discretion of CRAWLR.
        </Text>

        <Text
          padding="1vh"
          textAlign="left"
          color="body"
          fontFamily="Brandon Grotesque"
          fontSize="1.5rem"
        >
          Content
        </Text>

        <Text padding="1vh" textAlign="left" color="body">
          Our Service allows you to post, link, store, share and otherwise make
          available certain information, text, graphics, videos, or other
          material (“Content”). You are responsible for the Content that you
          post on or through the Service, including its legality, reliability,
          and appropriateness. By posting Content on or through the Service, You
          represent and warrant that: (i) the Content is yours (you own it)
          and/or you have the right to use it and the right to grant us the
          rights and license as provided in these Terms, and (ii) that the
          posting of your Content on or through the Service does not violate the
          privacy rights, publicity rights, copyrights, contract rights or any
          other rights of any person or entity. We reserve the right to
          terminate the account of anyone found to be infringing on a copyright.
          You retain any and all of your rights to any Content you submit, post
          or display on or through the Service and you are responsible for
          protecting those rights. We take no responsibility and assume no
          liability for Content you or any third party posts on or through the
          Service. However, by posting Content using the Service you grant us
          the right and license to use, modify, publicly perform, publicly
          display, reproduce, and distribute such Content on and through the
          Service. You agree that this license includes the right for us to make
          your Content available to other users of the Service, who may also use
          your Content subject to these Terms. There are three types of content
          (“Content”) that you will be able to access on CRAWLR: Content that
          you upload and provide (“Your Content”); Content that members provide
          (“Member Content”); and Content that CRAWLR provides (“Our Content”).
          This includes, but is not limited to: Private messages; Comments;
          Forum posts; Group posts; Profile information; CRAWLR does not allow
          the following content on our Service: Content that contains language
          or imagery that is likely to harass, upset, embarrass, alarm, doxx, or
          annoy any other person; Content that is obscene, pornographic, or
          violent; Content that is abusive, insulting, threatening, or
          discriminatory; Content that promotes or encourages racism, sexism,
          hatred, or bigotry; Content that encourages illegal activity
          including, without limitation, terrorism, inciting racial hatred, or
          the submission of which in itself constitutes committing a criminal
          offense; Content that is defamatory or deemed libel; Content related
          to commercial activities, unless it is approved by admins; Content
          that involves the transmission of spam; Content that contains any
          spyware, adware, viruses, corrupt files, worm programs, or other
          malicious code designed to interrupt, damage or limit the
          functionality or disrupt any software, hardware, telecommunications,
          networks, servers, or other equipment; Content that contains a Trojan
          horse or any other material designed to damage, interfere with,
          wrongly intercept, or extract any data or personal information,
          whether from CRAWLR or otherwise; Content that shows another person
          which was created or distributed without that person’s consent. CRAWLR
          has a zero-tolerance policy for this kind of content. CRAWLR has the
          right but not the obligation to monitor and edit all Content provided
          by users. You may not distribute, modify, transmit, reuse, download,
          repost, copy, or use Content uploaded and owned by CRAWLR, whether in
          whole or in part, for commercial purposes or for personal gain,
          without express advance written permission from us.
        </Text>

        <Text
          padding="1vh"
          textAlign="left"
          color="body"
          fontFamily="Brandon Grotesque"
          fontSize="1.5rem"
        >
          Accounts
        </Text>

        <Text padding="1vh" textAlign="left" color="body">
          When you create an account with us, you guarantee that you are above
          the age of 18, and that the information you provide us is accurate,
          complete, and current at all times. Inaccurate, incomplete, or
          obsolete information may result in the immediate termination of your
          account on the Service. You are responsible for maintaining the
          confidentiality of your account and password, including but not
          limited to the restriction of access to your computer and/or account.
          You agree to accept responsibility for any and all activities or
          actions that occur under your account and/or password, whether your
          password is with our Service or a third-party service. You must notify
          us immediately upon becoming aware of any breach of security or
          unauthorized use of your account. You may not use as a username the
          name of another person or entity or that is not lawfully available for
          use, a name or trademark that is subject to any rights of another
          person or entity other than you, without appropriate authorization.
          You may not use as a username any name that is offensive, vulgar or
          obscene. We reserve the right to refuse service, terminate accounts,
          remove or edit content, or cancel orders in our sole discretion.
        </Text>

        <Text
          padding="1vh"
          textAlign="left"
          color="body"
          fontFamily="Brandon Grotesque"
          fontSize="1.5rem"
        >
          Intellectual Property
        </Text>

        <Text padding="1vh" textAlign="left" color="body">
          The Service and its original content (excluding Content provided by
          users), features and functionality are and will remain the exclusive
          property of CRAWLR and its licensors. The Service is protected by
          copyright, trademark, and other laws of both the United States and
          foreign countries. Our trademarks and trade dress may not be used in
          connection with any product or service without the prior written
          consent of CRAWLR.
        </Text>

        <Text
          padding="1vh"
          textAlign="left"
          color="body"
          fontFamily="Brandon Grotesque"
          fontSize="1.5rem"
        >
          Links To Other Web Sites
        </Text>

        <Text padding="1vh" textAlign="left" color="body">
          Our Service may contain links to third party web sites or services
          that are not owned or controlled by CRAWLR. CRAWLR has no control
          over, and assumes no responsibility for the content, privacy policies,
          or practices of any third party web sites or services. We do not
          warrant the offerings of any of these entities/individuals or their
          websites. You acknowledge and agree that CRAWLR shall not be
          responsible or liable, directly or indirectly, for any damage or loss
          caused or alleged to be caused by or in connection with use of or
          reliance on any such content, goods or services available on or
          through any such third party web sites or services. We strongly advise
          you to read the terms and conditions and privacy policies of any third
          party web sites or services that you visit.
        </Text>

        <Text
          padding="1vh"
          textAlign="left"
          color="body"
          fontFamily="Brandon Grotesque"
          fontSize="1.5rem"
        >
          Termination
        </Text>

        <Text padding="1vh" textAlign="left" color="body">
          We may terminate or suspend your account and bar access to the Service
          immediately, without prior notice or liability, under our sole
          discretion, for any reason whatsoever and without limitation,
          including but not limited to a breach of the Terms. If you wish to
          terminate your account, you may simply discontinue using the Service.
          All provisions of the Terms which by their nature should survive
          termination shall survive termination, including, without limitation,
          ownership provisions, warranty disclaimers, indemnity and limitations
          of liability.
        </Text>

        <Text
          padding="1vh"
          textAlign="left"
          color="body"
          fontFamily="Brandon Grotesque"
          fontSize="1.5rem"
        >
          Indemnification
        </Text>

        <Text padding="1vh" textAlign="left" color="body">
          You agree to defend, indemnify and hold harmless CRAWLR and its
          licensee and licensors, and their employees, contractors, agents,
          officers and directors, from and against any and all claims, damages,
          obligations, losses, liabilities, costs or debt, and expenses
          (including but not limited to attorney’s fees), resulting from or
          arising out of a) your use and access of the Service, by you or any
          person using your account and password; b) a breach of these Terms, or
          c) Content posted on the Service.
        </Text>

        <Text
          padding="1vh"
          textAlign="left"
          color="body"
          fontFamily="Brandon Grotesque"
          fontSize="1.5rem"
        >
          Limitation Of Liability
        </Text>

        <Text padding="1vh" textAlign="left" color="body">
          In no event shall CRAWLR, nor its directors, employees, partners,
          agents, suppliers, or affiliates, be liable for any indirect,
          incidental, special, consequential or punitive damages, including
          without limitation, loss of profits, data, use, goodwill, or other
          intangible losses, resulting from (i) your access to or use of or
          inability to access or use the Service; (ii) any conduct or content of
          any third party on the Service; (iii) any content obtained from the
          Service; and (iv) unauthorized access, use or alteration of your
          transmissions or content, whether based on warranty, contract, tort
          (including negligence) or any other legal theory, whether or not we
          have been informed of the possibility of such damage, and even if a
          remedy set forth herein is found to have failed of its essential
          purpose.
        </Text>

        <Text
          padding="1vh"
          textAlign="left"
          color="body"
          fontFamily="Brandon Grotesque"
          fontSize="1.5rem"
        >
          Disclaimer
        </Text>

        <Text padding="1vh" textAlign="left" color="body">
          Your use of the Service is at your sole risk. The Service is provided
          on an “AS IS” and “AS AVAILABLE” basis. The Service is provided
          without warranties of any kind, whether express or implied, including,
          but not limited to, implied warranties of merchantability, fitness for
          a particular purpose, non-infringement or course of performance.
          CRAWLR its subsidiaries, affiliates, and its licensors do not warrant
          that a) the Service will function uninterrupted, secure or available
          at any particular time or location; b) any errors or defects will be
          corrected; c) the Service is free of viruses or other harmful
          components; or d) the results of using the Service will meet your
          requirements.
        </Text>

        <Text
          padding="1vh"
          textAlign="left"
          color="body"
          fontFamily="Brandon Grotesque"
          fontSize="1.5rem"
        >
          Exclusions
        </Text>

        <Text padding="1vh" textAlign="left" color="body">
          Some jurisdictions do not allow the exclusion of certain warranties or
          the exclusion or limitation of liability for consequential or
          incidental damages, so the limitations above may not apply to you.
        </Text>

        <Text
          padding="1vh"
          textAlign="left"
          color="body"
          fontFamily="Brandon Grotesque"
          fontSize="1.5rem"
        >
          Governing Law
        </Text>

        <Text padding="1vh" textAlign="left" color="body">
          These Terms shall be governed and construed in accordance with the
          laws of Georgia, United States, without regard to its conflict of law
          provisions. Our failure to enforce any right or provision of these
          Terms will not be considered a waiver of those rights. If any
          provision of these Terms is held to be invalid or unenforceable by a
          court, the remaining provisions of these Terms will remain in effect.
          These Terms constitute the entire agreement between us regarding our
          Service, and supersede and replace any prior agreements we might have
          had between us regarding the Service.
        </Text>

        <Text
          padding="1vh"
          textAlign="left"
          color="body"
          fontFamily="Brandon Grotesque"
          fontSize="1.5rem"
        >
          Changes
        </Text>

        <Text padding="1vh" textAlign="left" color="body">
          We reserve the right, at our sole discretion, to modify or replace
          these Terms at any time. If a revision is material we will provide at
          least 30 days notice prior to any new terms taking effect. What
          constitutes a material change will be determined at our sole
          discretion. By continuing to access or use our Service after any
          revisions become effective, you agree to be bound by the revised
          terms. If you do not agree to the new terms, you are no longer
          authorized to use the Service.
        </Text>

        <Text
          padding="1vh"
          textAlign="left"
          color="body"
          fontFamily="Brandon Grotesque"
          fontSize="1.5rem"
        >
          Contact Us
        </Text>

        <Text padding="1vh" textAlign="left" color="body">
          If you have any questions about these Terms, please contact us. By
          email: jackie@crawlr.app Or online: https://crawlr.app/#contact
        </Text>
      </View>
    </Page>
  );
}

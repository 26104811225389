import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import styled from "styled-components";

type TextType = {
  color?: string;
  padding?: string;
  fontFamily?: string;
  fontWeight?: string;
  disabled?: boolean;
  fontSize?: string;
  textAlign?: string;
  childType?: string;
  textTransform?: string;
  margin?: string;
  gradient?: boolean;
};

export const Text = styled.p<TextType>`
  color: ${(props) =>
    props.color === "background" || props.color === "body"
      ? props.theme.themeColors[props.color]
      : props.theme.standardColors[props.color]};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "1rem")};
  font-family: ${(props) => (props.fontFamily ? props.fontFamily : "Poppins")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "400")};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
  margin: ${(props) => props.margin || "0"};
  padding: ${(props) => props.padding || "0"};
  text-transform: ${(props) =>
    props.textTransform ? props.textTransform : null};

  background: ${(props) =>
    props.gradient ? props.theme.standardColors[props.color] : null};
  -webkit-background-clip: ${(props) => (props.gradient ? "text" : null)};
  -webkit-text-fill-color: ${(props) =>
    props.gradient ? "transparent" : null};
`;

// interface TextProps {
//   color?:string;
//   background?:string;
//   fontSize?:string;
//   fontFamily?:string
//   fontWeight?:string
//   padding?:string;
//   textAlign?:string;
//   childType?:string;
//   textTransform?:string;
//   margin?:string;

// }

// export const Text: React.FC<TextProps> = props => {

//   const history = useHistory();

//   return (
//       <TextItem margin={props.margin} padding={props.padding} fontFamily={props.fontFamily} textAlign={props.textAlign} color={props.color} fontSize={props.fontSize} fontWeight={props.fontWeight} textTransform={props.textTransform}>
//       {props.children}
//       </TextItem>
//   );
// };


import React, { useEffect, useState, useReducer } from "react";
import { Button } from "../components/Button";
import { Checkbox } from "../components/Checkbox";
import { Form } from "../components/Form";
import { Icon } from "../components/Icon";
import { Link } from "../components/Link";
import { Page } from "../components/Page";
import { Text } from "../components/Text";
import { TextInput } from "../components/TextInput";
import { Toast } from "../components/Toast";
import { View } from "../components/View";
import { Auth } from "../contexts/AuthProvider";
import {
  validateEmail,
  validatePassword,
} from "../helpers/Validation";
import { Google } from "@styled-icons/fa-brands";
import { CaretDown, Envelope, Times } from "@styled-icons/fa-solid";

export function Signup(props: any) {
  const {
    signup, 
    signupWithGoogle, 
    error, 
    success
   } = Auth();

   const [newUser, setNewUser] = useReducer(
    (state:any, newState:any) => ({ ...state, ...newState }),
    {
      email: null,
      password: null
    }
  ); 
  const [hiddenPassword, setHiddenPassword] = useState<boolean>(true);


  const [showEmailForm, setShowEmailForm] = useState<boolean>(false);

  const [emailValidation, setEmailValidation] = useState<any | null>({
    validity: null,
    message: null,
  });
  const [passwordValidation, setPasswordValidation] = useState<any | null>({
    validity: null,
    message: null,
    tooltip: null,
  });


  const [validEmail, setValidEmail] = useState(null);
  const [validPassword, setValidPassword] = useState(null);
  const [emailTimer, setEmailTimer] = useState<any | number>(0);
  const [passwordTimer, setPasswordTimer] = useState<any | number>(0);

  useEffect(() => {
    if (validPassword === true) {
      setPasswordValidation({
        validity: validPassword,
        message: null,
        tooltip: null,
      });
    } else if (validPassword === false) {
      setPasswordValidation({
        validity: validPassword,
        message: "Password is invalid",
        tooltip: [
          "Password Requirements:",
          "- 8 characters long",
          "- 1 uppercase character",
          "- 1 lowercase character",
          "- 1 special character",
          "- 1 number",
        ],
      });
    } else {
      setPasswordValidation({ validity: null, message: null, tooltip: null });
    }
  }, [validPassword]);

  useEffect(() => {
    if (validEmail === true) {
      setEmailValidation({ validity: validEmail, message: null });
    } else if (validEmail === false) {
      setEmailValidation({ validity: validEmail, message: "Email is invalid" });
    } else {
      setEmailValidation({ validity: null, message: null });
    }
  }, [validEmail]);


  return (
    <Page pageTitle="Sign Up | CRAWLR" pageDescription="Sign Up">
      {success ? (
        <Toast type="success" background="success">
          {success.message}
        </Toast>
      ) : null}
      {error ? (
        <Toast type="error" background="error">
          {error.message}
        </Toast>
      ) : null}
      <Toast type="reminder" background="disabled">
        All members of the previous iteration of CRAWLR must make new accounts.
      </Toast>


      <View justifyContent="center" flexFlow="row wrap" padding="3vh 3vw 0 3vw">
        <Text textAlign="center" fontSize="1.5rem" color="body">
          Welcome to CRAWLR
        </Text>
      </View>





      <View padding="3vh 3vw 0 3vw" flexFlow="row wrap" justifyContent="center">
        <View
          borderWidth="1px"
          borderColor="black"
          flex="0 0 auto"
          borderRadius="18px"
          background="white"
        >
          <View flexFlow="row wrap" justifyContent="center">
            <Button
              onClick={() => {
                setShowEmailForm(!showEmailForm);
              }}
              buttonType="fill"
              size="extralarge"
              background="white"
              color="black"
            >
              <View flexFlow="row wrap" justifyContent="space-between">
                <Icon color="black" size="16px">
                  <Envelope />
                </Icon>
                Sign Up With Email
                {showEmailForm ? (
                  <Icon color="black" size="16px">
                    <Times />
                  </Icon>
                ) : (
                  <Icon color="black" size="16px">
                    <CaretDown />
                  </Icon>
                )}
              </View>
            </Button>
          </View>

          {showEmailForm ? (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                signup(newUser);
              }}
            >
              <TextInput
                onKeyUp={(e) => {
                  if (emailTimer) {
                    clearTimeout(emailTimer);
                  }
                  setEmailTimer(
                    setTimeout(() => {
                      if (newUser.email) {
                        setValidEmail(validateEmail(newUser.email));
                      } else {
                        setValidEmail(null);
                        setEmailValidation({ validity: null, message: null });
                      }
                    }, 1000)
                  );
                }}
                onClear={() => {
                  setNewUser({['email']:null})
                  setValidEmail(null);
                  setEmailValidation({ validity: null, message: null });
                }}
                type="email"
                placeholder="Email Address"
                value={newUser.email ? newUser.email : ""}
                onChange={(e) =>   setNewUser({['email']:e.target.value})}
                required
                validation={emailValidation}
              />

              <TextInput
                onKeyUp={(e) => {
                  if (passwordTimer) {
                    clearTimeout(passwordTimer);
                  }
                  setPasswordTimer(
                    setTimeout(() => {
                      if (newUser.password) {
                        setValidPassword(validatePassword(newUser.password));
                      } else {
                        setValidPassword(null);
                        setPasswordValidation({
                          validity: null,
                          message: null,
                        });
                      }
                    }, 1000)
                  );
                }}
                onClear={() => {
                  setNewUser({['password']:null})
                  setValidPassword(null);
                  setPasswordValidation({ validity: null, message: null });
                }}
                onToggle={() => {
                  setHiddenPassword(!hiddenPassword);
                }}
                type={hiddenPassword ? "password" : "text"}
                placeholder="Password"
                value={newUser.password ? newUser.password : ""}
                onChange={(e: any) => setNewUser({['password']:e.target.value})}
                required
                validation={passwordValidation}
                password
              />

              <Button
                size="large"
                buttonType="fill"
                type="submit"
                onClick={() => {}}
                disabled={!newUser.email || !validEmail || !newUser.password || !validPassword}
                background="primary"
              >
                Submit
              </Button>
            </Form>
          ) : null}
        </View>
      </View>
      <View justifyContent="center" flexFlow="row wrap" padding="3vh 3vw 0 3vw">
        <View flexFlow="row wrap" justifyContent="center">
          <Button
            onClick={signupWithGoogle}
            buttonType="fill"
            size="extralarge"
            background="white"
            color="black"
          >
            <View flexFlow="row wrap" justifyContent="space-between">
              <Icon color="black" size="16px">
                <Google />
              </Icon>
              Sign Up With Google
              <Icon color="black" size="16px"></Icon>
            </View>
          </Button>
        </View>
      </View>

      <View justifyContent="center" flexFlow="row wrap" padding="3vh 3vw 0 3vw">
                <Text textAlign="center" color="white" fontSize="1rem">
                  By signing up, you acknowledge that you are 18 years or older
                  and agree to our
                </Text>
                <Link
                  href="/terms"
                  color="black"
                  linkType="text"
                  target="_blank"
                >
                  <Text textAlign="center" color="primary" fontSize="1rem">
                    Terms & Conditions
                  </Text>
                </Link>
      </View>



      <View justifyContent="center" flexFlow="row wrap" padding="3vh 3vw 0 3vw">
        <Text color="body">Already have an account?</Text>
        <Link linkType="text" color="primary" href="/login">
          Log In
        </Link>
      </View>
    </Page>
  );
}

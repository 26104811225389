// src/components/Navbar/index.tsx
import React, {
  useState,
  useContext,
  useEffect,
  MouseEventHandler,
} from "react";
import { View } from "./View";
import { Text } from "./Text";
import { Link } from "./Link";
import { Image } from "./Image";
import { Icon } from "./Icon";
import { Auth } from "../contexts/AuthProvider";
import { Firestore } from "../contexts/FirestoreProvider";
import { Dimensions } from "../contexts";
import { useLocation } from "react-router-dom";
import { Bars, UserCircle, Times } from "@styled-icons/fa-solid";
import { logo } from "../assets/images";
import { ScrollTo } from "../helpers/ScrollTo";

interface NavbarProps {
  pageTitle?: string;
  setShowMenu?: any;
  showMenu?: boolean;
}

export const Navbar: React.FC<NavbarProps> = (props) => {
  const { currentUser, logout } = Auth();
  const { currentUserProfile } = Firestore();

  const { mobile, laptop, desktop, tablet } = Dimensions();
  const location = useLocation();

  const handleClick = (props) => {
    ScrollTo(props);
  };

  // const toggleTheme = () => {
  //   if (theme === 'darkmode') {
  //     changeThemeMode(false);
  //     changeTheme('lightmode');
  //   }
  //   if (theme === 'lightmode') {
  //     changeThemeMode(true);
  //     changeTheme('darkmode');
  //   }
  // };

  return (
    <View
      zIndex={100000000000000}
      position="fixed"
      top="0"
      left="0"
      right="0"
      background="background"
      width="100%"
      height="70px"
      borderColor="primaryLight"
      borderWidth="0 0 5px 0"
      flexFlow="row nowrap"
      flex="1"
    >
      <View flex="1" flexFlow="row nowrap">
        <View flex="1" flexFlow="row nowrap" justifyContent="flex-start" to="/">
          <Image
            padding="0 1vw 0 1vw"
            height="3rem"
            to="/"
            src={logo}
            alt="Logo"
          />
          {!mobile && !tablet ? (
            <Text
              fontFamily="Brandon Grotesque"
              fontSize="2rem"
              fontWeight="900"
              color="body"
            >
              CRAWLR
            </Text>
          ) : null}
        </View>

        <View flex="1" flexFlow="row nowrap" justifyContent="space-around">
          {mobile || tablet || location.pathname !== "/" ? (
            <Link
              linkType={props.pageTitle==="CRAWLR" ? "crawlr" :"text"}
              padding="0 2vw 0 2vw"
              color="body"
              href={window.location.href}
            >
              {props.pageTitle}
            </Link>
          ) : null}
          {!mobile && !tablet && location.pathname === "/" ? (
            <Link
              padding="0 2vw 0 2vw"
              linkType="text"
              color="body"
              onClick={() => handleClick("about")}
            >
              About
            </Link>
          ) : null}
          {!mobile && !tablet && location.pathname === "/" ? (
            <Link
              padding="0 2vw 0 2vw"
              linkType="text"
              color="body"
              onClick={() => handleClick("team")}
            >
              Team
            </Link>
          ) : null}
             {!mobile && !tablet && location.pathname === "/" ? (
            <Link
              padding="0 2vw 0 2vw"
              linkType="text"
              color="body"
              onClick={() => handleClick("donate")}
            >
              Donate
            </Link>
          ) : null}
          {!mobile && !tablet && location.pathname === "/" ? (
            <Link
              padding="0 2vw 0 2vw"
              linkType="text"
              color="body"
              onClick={() => handleClick("contribute")}
            >
              Contribute
            </Link>
          ) : null}
          {!mobile && !tablet && location.pathname === "/" ? (
            <Link
              padding="0 2vw 0 2vw"
              linkType="text"
              color="body"
              onClick={() => handleClick("contact")}
            >
              Contact
            </Link>
          ) : null}
        </View>

        <View flex="1" flexFlow="row nowrap" justifyContent="flex-end">
          {!currentUser && !mobile && !tablet && location.pathname !== "/login" ? (
            <Link
              padding="0 2vw 0 2vw"
              href="/login"
              linkType="text"
              color="body"
            >
              Log In
            </Link>
          ) : null}
          {!currentUser && !mobile && !tablet && location.pathname !== "/signup" ? (
            <Link
              padding="0 2vw 0 2vw"
              href="/signup"
              linkType="text"
              color="body"
            >
              Sign Up
            </Link>
          ) : null}

          {/* 
            {user && userProfile && user.photoURL && !mobile ? 
              <Image borderRadius="50%" src={user.photoURL} height="30px" alt="logo"/>
              : 
              null
            }

            {user && userProfile && !user.photoURL && !mobile ? 
              <Icon size="30px" color="body"><UserCircle/></Icon>
              : 
              null }
      

        {user && userProfile && !mobile ? <Text padding="0 0 0 1vw" color="body">{userProfile.nickname}</Text> : null} */}

          {(currentUser || mobile || tablet) && !props.showMenu ? (
            <Icon
              padding="0 1vw 0 1vw"
              size="2rem"
              color="body"
              onClick={() => {
                props.setShowMenu(!props.showMenu);
              }}
            >
              <Bars />
            </Icon>
          ) : null}
          {(currentUser || mobile || tablet) && props.showMenu ? (
            <Icon
              padding="0 1vw 0 1vw"
              size="2rem"
              color="body"
              onClick={() => {
                props.setShowMenu(!props.showMenu);
              }}
            >
              <Times />
            </Icon>
          ) : null}
        </View>
      </View>
    </View>
  );
};

import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { View } from "./View";
import { Dimensions } from "../contexts";

type TooltipType = {
  flex?: string;
  flexFlow?: string;
  justifyContent?: string;
  alignContent?: string;
  padding?: string;
  margin?: string;
  background?: string;
  id?: string;
  wrap?: boolean;
  onClick?: () => void;
  maxWidth?: string;
  minWidth?: string;
  zIndex?: number;
  position?: string;
  borderWidth?: string;
  borderColor?: string;
  cursor?: string;
  to?: string;
  borderRadius?: string;
  height?: string;
  color?: string;
  alignItems?: string;
  top?: number;
  children: any;
};

const TooltipContainer = styled.div<TooltipType>`
  display: inline-block;
  position: relative;
`;

const TooltipTip = styled.div<TooltipType>`
  position: absolute;
  border-radius: 4px;
  right: 0%;
  left: 0%;
  right: 0%;
  transform: translateY(50%);
  color: black;
  font-size: 1rem;
  font-family: Poppins;
  position: absolute;
  background: ${(props) => props.background || null};
  filter: drop-shadow(1px 1px 5px #000000);
  z-index: 100;
  padding: 2vh 1vw 2vh 1vw;
`;

interface TooltipProps {
  flex?: string;
  flexFlow?: string;
  justifyContent?: string;
  alignContent?: string;
  padding?: string;
  margin?: string;
  background?: string;
  id?: string;
  wrap?: boolean;
  onClick?: () => void;
  maxWidth?: string;
  minWidth?: string;
  zIndex?: number;
  position?: string;
  borderWidth?: string;
  borderColor?: string;
  cursor?: string;
  to?: string;
  borderRadius?: string;
  height?: string;
  color?: string;
  alignItems?: string;
  top?: number;
  children: any;
}

export const Tooltip: React.FC<TooltipProps> = ({
  background,
  children,
  ...rest
}) => {
  const history = useHistory();

  const { mobile } = Dimensions();

  const type = typeof children;

  return (
    <TooltipContainer>
      <TooltipTip background={background}>
        {type === "string" ? (
          <View
            flex="1"
            flexFlow="row wrap"
            justifyContent="center"
            padding="0 0 0 0"
          >
            {children}
          </View>
        ) : null}
        {type !== "string" ? (
          <View
            flex="1"
            flexFlow="column wrap"
            justifyContent="center"
            padding="0 0 0 0"
          >
            {children.map((child) => {
              return (
                <View
                  flex="1"
                  flexFlow="row wrap"
                  justifyContent="flex-start"
                  padding="0 0 0 0"
                >
                  {child}
                </View>
              );
            })}
          </View>
        ) : null}
      </TooltipTip>
    </TooltipContainer>
  );
};

import React, {
  FC,
  useContext,
  useState,
  createContext,
  useEffect,
} from "react";
import {
  getLocalStorage,
  setLocalStorage,
  clearLocalStorage,
} from "../helpers/Storage";
import firebase, {
  auth,
  firestore,
  storage,
  googleProvider,
} from "../../firebase.config";
import { Redirect, useHistory } from "react-router-dom";
import { Auth } from "./AuthProvider";
import { Firestore } from "./FirestoreProvider";

type StorageContextProps = {
  deleteProfilePhoto: any;
  uploadProfilePhoto: any;
  getPhotoURL: any;
};

const StorageContext = createContext<StorageContextProps>({
  deleteProfilePhoto: null,
  uploadProfilePhoto: null,
  getPhotoURL: null
});

export const StorageProvider: FC = ({ children }) => {
  const { currentUser, 
    updateAuthUserProfile , getCurrentUser
  } = Auth();

  const { currentUserProfile, updateCurrentUserProfile, getCurrentUserProfile
  } = Firestore();

  const deleteProfilePhoto = () => {
    const profilePhoto = storage().ref("users").child(currentUser.uid).child('profilePhoto');
    if (profilePhoto) {
      profilePhoto
        .delete()
        .then((data:any) => {
          updateCurrentUserProfile({docID:currentUser.uid, photoURL: null });
          getCurrentUserProfile()
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const uploadProfilePhoto = (photo: any) => {
    storage()
      .ref(`users/${currentUser.uid}/profilePhoto`)
      .put(photo)
      .then(() => {
        storage()
        .ref("users")
        .child(currentUser.uid)
        .child('profilePhoto')
        .getDownloadURL()
        .then((url:any) => {
          updateCurrentUserProfile({docID:currentUser.uid, photoURL: url });
          getCurrentUserProfile()
        })
        .catch((e) => {
          console.log(e);
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getPhotoURL = () => {
        return storage()
        .ref("users")
        .child(currentUser?.uid)
        .child('profilePhoto')
        .getDownloadURL()
        .then((url:any) => {
          return url
        })
        .catch((e) => {
          console.log(e);
          return null
        });
  };



  return (
    <StorageContext.Provider
      value={{
        deleteProfilePhoto,
        uploadProfilePhoto,
        getPhotoURL
      }}
    >
      {children}
    </StorageContext.Provider>
  );
};

export const Storage = () => useContext(StorageContext);

import React, { useContext } from "react";
import { View } from "../components/View";
import { Text } from "../components/Text";
import { Link } from "../components/Link";
import { Footer } from "../components/Footer";
import { Image } from "../components/Image";
import { Card } from "../components/Card";
import { Button } from "../components/Button";
import { Page } from "../components/Page";
import { Toast } from "../components/Toast";

export function NotFound(props: any) {
  return (
    <Page pageTitle="Not Found | CRAWLR" pageDescription="Not Found">
      <View background="background">
        <View flexFlow="row wrap" justifyContent="center">
          <Text color="body" textAlign="center" fontSize="3rem">
            Page Not Found
          </Text>
        </View>
      </View>
    </Page>
  );
}

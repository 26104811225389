// src/components/Navbar/index.tsx
import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { View } from "./View";

type IconType = {
  src?: any;
  width?: string;
  height?: string;
  minWidth?: string;
  alt?: string;
  maxWidth?: string;
  onClick?: () => void;
  to?: string;
  borderRadius?: string;
  padding?: string;
  background?: string;
  disabled?: boolean;
  margin?: string;
  size: string;
  color: string;
  click?: () => void;
  cursor?: string;
};

const IconContainer = styled.i<IconType>`
  background: ${(props) => (!props.background ? "transparent" : null)};
  background: ${(props) =>
    props.background &&
    props.background !== "body" &&
    props.background !== "background"
      ? props.theme.standardColors[props.background]
      : null};
  background: ${(props) =>
    props.background &&
    (props.background === "body" || props.background === "background")
      ? props.theme.themeColors[props.background]
      : null};
  height: ${(props) => props.height || null};
  width: ${(props) => props.width || null};
  border-radius: ${(props) => props.borderRadius || null};
  margin: ${(props) => props.margin || null};
  padding: ${(props) => props.padding || null};

  text-align: center;

  :disabled {
    cursor: not-allowed;
  }

  :hover {
    filter: ${(props) => (props.click || props.to ? "brightness(80%)" : null)};
  }

  svg {
    color: ${(props) =>
      props.color === "background" || props.color === "body"
        ? props.theme.themeColors[props.color]
        : props.theme.standardColors[props.color]};
    width: ${(props) => props.size};
    height: ${(props) => props.size};
  }

  cursor: ${(props) => props.cursor || "default"};
  cursor: ${(props) => (props.click || props.to ? "pointer" : "default")};
`;

interface IconProps {
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  onClick?: () => void;
  click?: () => void;
  to?: string;
  padding?: string;
  height?: string;
  borderRadius?: string;
  children?: any;
  background?: string;
  disabled?: boolean;
  margin?: string;
  color: string;
  size: string;
  cursor?: string;
}

export const Icon: React.FC<IconProps> = (props) => {
  const history = useHistory();

  const checkClickType = () => {
    if (props.to && props.to.includes("http")) {
      return (window.location.href = props.to);
    } else if (props.to && !props.to.includes("http")) {
      return history.push(props.to);
    } else if (!props.to && props.onClick) {
      return props.onClick();
    }
  };

  return (
    <IconContainer
      to={props.to}
      cursor={props.cursor}
      click={props.onClick}
      size={props.size}
      color={props.color}
      margin={props.margin}
      borderRadius={props.borderRadius}
      height={props.height}
      width={props.width}
      disabled={props.disabled}
      background={props.background}
      onClick={checkClickType}
      padding={props.padding}
    >
      {props.children}
    </IconContainer>
  );
};

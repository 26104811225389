import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { View } from "./View";
import { Button } from "./Button";
import { Text } from "./Text";
import { Icon } from "./Icon";

import { Dimensions } from "../contexts";

import { Times } from "@styled-icons/fa-solid";

interface ToastProps {
  flex?: number;
  flexFlow?: string;
  justifyContent?: string;
  alignContent?: string;
  padding?: string;
  margin?: string;
  background?: string;
  id?: string;
  width?: string;
  type: string;
  onClose?: () => void;
  onClick?: () => void;
  onAction?: () => void;
  actionText?: string;
  close?: boolean;
  action?: boolean;
}

export const Toast: React.FC<ToastProps> = (props) => {
  const { mobile, tablet, laptop, desktop } = Dimensions();

  return (
    <View
      padding="2vh 0 2vh 0"
      flexFlow="row wrap"
      background={props.background}
    >
      <View flexFlow="row wrap" justifyContent="center">
        <Text
          textAlign="center"
          color="white"
          fontWeight="900"
          fontFamily="Brandon Grotesque"
        >
          {props.type.toUpperCase()}
        </Text>
      </View>
      <View flexFlow="row wrap" justifyContent="center">
        <Text textAlign="center" padding="0 1vw 0 1vw" color="white">
          {props.children}
        </Text>
        {props.action ? (
          <Button
            size="small"
            buttonType="outline"
            onClick={props.onAction}
            color="white"
            borderColor="white"
          >
            {props.actionText}
          </Button>
        ) : null}
      </View>
      <View flexFlow="row wrap" justifyContent="center">
        <Icon size="1rem" color={props.background}>
          <Times />
        </Icon>
      </View>
    </View>
  );
};

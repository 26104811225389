// src/components/Navbar/index.tsx
import React, { useState, useEffect, forwardRef } from "react";
import styled from "styled-components";
import { View } from "./View";
import { Tooltip } from "./Tooltip";
import PasswordStrengthBar from "react-password-strength-bar";
import DatePicker from "react-datepicker";
import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";

import { Times, Eye, EyeSlash } from "@styled-icons/fa-solid";

type InputType = {
  flex?: number;
  flexFlow?: string;
  justifyContent?: string;
  alignContent?: string;
  padding?: string;
  margin?: string;
  background?: string;
  id?: string;
  width?: string;
  border?: string;
  disabled?: boolean;
  validity?: any;
  type?: string;
};

const Container = styled.div<InputType>`
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex;
  -ms-flex: ${(props) => props.flex || null};
  -webkit-flex: ${(props) => props.flex || null};
  flex: ${(props) => props.flex || null};
  flex-flow: column nowrap;
  justify-content: space-around;
  background: ${(props) =>
    props.type !== "file"
      ? props.theme.standardColors.inputBackground
      : "transparent"};
  height: 55px;
  width: 275px;
  border-radius: 18px;
  margin: 0 auto;
`;

const InputContainer = styled.div<InputType>`
  color: ${(props) => props.theme.standardColors.placeholder};
  width: 100%;

  input[type="file"] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
`;

const Placeholder = styled.p<InputType>`
  margin: 0;
  padding: 0;
  color: ${(props) => props.theme.standardColors.placeholder};
  font-size: 0.85rem;
  text-align: left;
  height: 40%;
`;

const DatePlaceholder = styled.p<InputType>`
  margin: 0;
  padding: 0;
  color: ${(props) => props.theme.standardColors.placeholder};
  font-size: 1rem;
  text-align: left;
  height: 100%;
`;

const Validation = styled.p<InputType>`
  margin: 0;
  padding: 0;
  color: ${(props) =>
    props.validity === true
      ? props.theme.standardColors.success
      : props.theme.standardColors.error};
  font-size: 0.85rem;
  text-align: left;
  padding: 0 20px 0 20px;
  height: 0.85rem;
`;

const Input = styled.input<InputType>`
  background: transparent;
  height: 60%;
  font-size: 1rem;
  width: 100%;
  border: none;

  :disabled {
    cursor: not-allowed;
  }

  :focus {
    outline: none;
  }

  :active {
    border: none !important;
  }

  font-family: Poppins;

  color: ${(props) =>
    props.value ? "black" : props.theme.standardColors.placeholder};

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${(props) => props.theme.standardColors.placeholder};
    font-size: 1rem;
    font-family: Poppins;
    opacity: 1;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${(props) => props.theme.standardColors.placeholder};
    font-size: 1rem;
    font-family: Poppins;
    opacity: 1;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${(props) => props.theme.standardColors.placeholder};
    font-size: 1rem;
    font-family: Poppins;
    opacity: 1;
  }
`;

const CustomLabel = styled.label<InputType>`
  display: inline-flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100px;
  text-align: center;
  color: ${(props) =>
    props.disabled
      ? props.theme.standardColors.gray
      : props.theme.standardColors.primary};
  border: ${(props) =>
    `2px solid ${
      props.disabled
        ? props.theme.standardColors.gray
        : props.theme.standardColors.primary
    }`};
  padding: 12.5px 10px 12.5px 10px;
  border-radius: 18px;
  font-weight: 700;
  font-family: Brandon Grotesque;
  text-transform: uppercase;
  font-size: 0.85rem;
  margin: 0 auto;

  :hover {
    color: ${(props) =>
      props.disabled
        ? props.theme.standardColors.gray
        : props.theme.standardColors.primaryLight};
    border: ${(props) =>
      `2px solid ${
        props.disabled
          ? props.theme.standardColors.gray
          : props.theme.standardColors.primaryLight
      }`};
  }

  cursor: ${(props) => (props.disabled ? "not-allowed" : null)};
`;

const CustomText = styled.p<InputType>`
  display: inline-flex;
  flex: 2;
  align-items: center;
  justify-content: center;
  color: ${(props) =>
    props.disabled
      ? props.theme.standardColors.gray
      : props.theme.standardColors.black};
  padding: 0 0 0 5px;

  cursor: ${(props) => (props.disabled ? "not-allowed" : null)};
`;

interface InputProps {
  type: string;
  placeholder: string;
  value?: any;
  onChange: (e: any) => void;
  disabled?: boolean;
  required?: boolean;
  validationColor?: string;
  validationMessage?: any;
  color?: string;
  onBlur?: (e: any) => void;
  onFocus?: (e: any) => void;
  onKeyUp?: (e: any) => void;
  password?: boolean;
  onToggle?: () => void;
  onClear?: () => void;
  max?: string;
  min?: string;
  link?: boolean;
  showTooltip?: boolean;
  tooltipContent?: any;
  valueLabel?: string;
  validation: any;
  id?: string;
}


export const TextInput: React.FC<InputProps> = (props: any) => {
  return (
    <View>
      {props?.validation?.tooltip ? (
        <Tooltip background="white">{props?.validation?.tooltip}</Tooltip>
      ) : null}
        <Container type={props.type}>
          {props.value || props.type === "date" ? (
            <View
              padding="1px 15px 1px 15px"
              flex="1"
              flexFlow="row nowrap"
              justifyContent="space-between"
            >
              <View flex="5" flexFlow="row nowrap" justifyContent="flex-start">
                <Placeholder>
                  {props.required
                    ? `* ${props.placeholder}`
                    : props.placeholder}
                </Placeholder>
              </View>
              <View flex=".5" flexFlow="row nowrap" justifyContent="center">
                {props.password && props.type === "password" ? (
                  <Eye size={"1rem"} color="gray" onClick={props.onToggle} />
                ) : null}
                {props.password && props.type === "text" ? (
                  <EyeSlash
                    size={"1rem"}
                    color="gray"
                    onClick={props.onToggle}
                  />
                ) : null}
              </View>
            </View>
          ) : null}
          <View
            padding="1px 15px 1px 15px"
            flex="1"
            flexFlow="row nowrap"
            justifyContent="space-between"
          >
            <View flex="5" flexFlow="row nowrap" justifyContent="flex-start">
              <InputContainer>
                <Input
                  disabled={props.disabled}
                  id={props.id}
                  max={props.type === "date" ? "3000-01-01" : null}
                  type={props.type}
                  onKeyUp={props.onKeyUp}
                  value={props.value}
                  onChange={props.onChange}
                  placeholder={
                    props.required
                      ? `* ${props.placeholder}`
                      : props.placeholder
                  }
                />
                {props.type === "file" ? (
                  <View flexFlow="row nowrap" justifyContent="center">
                    <CustomLabel disabled={props.disabled} htmlFor="file">
                      {props.placeholder}
                    </CustomLabel>
                    <CustomText disabled={props.disabled}>
                      {props.valueLabel}
                    </CustomText>
                  </View>
                ) : null}
              </InputContainer>
            </View>
            {/* <View flex=".5" flexFlow="row nowrap" justifyContent="center">
        {props.value ? <Times size={"1rem"} color="gray" onClick={props.onClear}/>: null}</View>   */}
          </View>
        </Container>
      <View height="1rem" width="100%" flexFlow="row nowrap">
        {props.value ? (
          <Validation validity={props?.validation?.valid}>
            {props?.validation?.message}
          </Validation>
        ) : null}
      </View>
    </View>

  );
};
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useReducer,
} from 'react';
//import auth, {firebase} from '@react-native-firebase/auth';
//import firestore from '@react-native-firebase/firestore';
import {Auth} from './AuthProvider';
//import {View, Animated, Easing} from 'react-native';
import {standardsquarelogo} from '../assets/images';
import {Loading} from '../components/Loading/Loading';
//import {RESULTS} from 'react-native-permissions';
import firebase, { auth, firestore, storage } from "../../firebase.config";
// import {View, Animated, Easing} from 'react-native' 
import axios from 'axios'

type FirestoreContextProps = {
  updateCurrentUserProfile: any;
  currentUserProfile: any;
  getCurrentUserProfile: any;
  updateGameSession: any;
  accountSetupNeeded: any;
  queryDocuments: any;
  createDocument: any;
  queryMoreDocuments: any;
  getDocumentByID: any;
  querySubCollectionDocuments: any;
  createSubCollectionDocument: any;
  queryMoreSubCollectionDocuments: any;
  batchSubCollectionDocuments: any;
  queryMostRecentSubCollectionDocument: any;
  batchDocuments: any;
  validateUsername:any;
  validateNickname:any;
};

const FirestoreContext = createContext<FirestoreContextProps>({
  updateCurrentUserProfile: null,
  currentUserProfile: null,
  getCurrentUserProfile: null,
  updateGameSession: null,
  accountSetupNeeded: null,
  queryDocuments: null,
  createDocument: null,
  queryMoreDocuments: null,
  getDocumentByID: null,
  querySubCollectionDocuments: null,
  createSubCollectionDocument: null,
  queryMoreSubCollectionDocuments: null,
  batchSubCollectionDocuments: null,
  queryMostRecentSubCollectionDocument: null,
  batchDocuments: null,
  validateUsername:null,
  validateNickname:null
});

export const FirestoreProvider = (params: any) => {
  const {currentUser} = Auth();
  const [currentUserProfile, setCurrentUserProfile] = useState<any | null>(
    null,
  );
  const [accountSetupNeeded, setAccountSetupNeeded] = useState<any | null>(
    false,
  );


  useEffect(() => {
    if(!currentUser && !currentUserProfile){
      setCurrentUserProfile(null)
      setAccountSetupNeeded(null)
    }
    else if (currentUser && !currentUserProfile) {
      getCurrentUserProfile();
    }
    else if (currentUser && currentUserProfile) {
      checkAccountSetup();
    } 
  }, [currentUser, currentUserProfile]);

  const checkAccountSetup = () =>{
    var validated = true
    if(currentUserProfile.email && currentUserProfile.username && currentUserProfile.birthdate && currentUserProfile.nickname){
      validated = false;
    }
    setAccountSetupNeeded(validated)
  }


  const getCurrentUserProfile = async () => {
    try {
      const result = await getDocumentByID('userProfiles', currentUser.uid);
      if (result) {
        if(result){
         storage()
         .ref("users")
         .child(currentUser?.uid).list().then((listedstuff)=>{
           if(listedstuff?.items?.length === 0){
            result.photoURL = null
            updateCurrentUserProfile({docID:currentUser.uid, photoURL: null });
           }
           setCurrentUserProfile(result)
         }).catch(()=>{
          setCurrentUserProfile(result)
         })
        }
      } else {
        const result2 = await createDocument('userProfiles', {
          docID: currentUser.uid,
          email: currentUser.email,
          terms:true
        });
        setCurrentUserProfile(result2);
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  const updateCurrentUserProfile = async (updatedData: any) => {
    try {
        const result = await updateDocument(
          'userProfiles',
          updatedData,
        );

        if (result && updatedData.nickname) {
          var authCurrentUser = auth().currentUser;
          if (authCurrentUser) {
            var displayNameResult = await authCurrentUser.updateProfile({
              displayName: updatedData?.nickname,
            });
          }
        }

        if (result && updatedData.photoURL) {
          var authCurrentUser = auth().currentUser;
          if (authCurrentUser) {
            var photoURLResult = await authCurrentUser.updateProfile({
              photoURL: updatedData?.photoURL,
            });
          }
        }
    } catch (err: any) {
      console.log(err);
    }
  };

  const updateGameSession = async (gameSessionData: any) => {
    try {
      var result = await updateDocument('gameSessions', gameSessionData);
      return result;
    } catch (err: any) {
      console.log(err);
    }
  };


  const validateUsername = async (username: string) => {
    try{
      const result = await axios.get(`https://www.purgomalum.com/service/containsprofanity?text=${username}`)
      if(result){
        if(result.data === false){
          const result2 = await queryDocuments("userProfiles", [{field:'username', operator:'==', value:username}], {field:'createdAt', sort:'desc'})
          if(result2){
            if(result2.data.length === 0 ){
              return {message:'Looking good!', valid:true}
            }
            else{
              return {message:'This username already exists', valid:false}
            }
          }
        }
        else{
          return {message:'This username contains profanity', valid:false}
        }
      }
    }
    catch(err:any){
      console.log(err)
    }
  };



    const validateNickname = async (nickname: string) => {
    try{
      const result = await axios.get(`https://www.purgomalum.com/service/containsprofanity?text=${nickname}`)
      if(result){
        if(result.data === false){
          return {message:'Looking good!', valid:true}
        }
        else{
          return {message:'This nickname contains profanity', valid:false}
        }
      }
    }
    catch(err:any){
      console.log(err)
    }

  };


  // API SETUP NEEDED
  //---------------------------------------------------------------------------------------------------------------------

  const getDocumentByID = async (collectionID: string, docID: any) => {
    try {
      const result = await firestore()
        .collection(collectionID)
        .doc(docID)
        .get();
      if (result.exists) {
        return result.data();
      } else {
        return null;
      }
    } catch (err: any) {
      console.log(err);
    }
  };


  const getSubCollectionDocumentByID = async (
    collectionID: string,
    docID: any,
    subCollectionID: any,
    subDocID: any,
  ) => {
    try {
      const result = await firestore()
        .collection(collectionID)
        .doc(docID)
        .collection(subCollectionID)
        .doc(subDocID)
        .get();
      if (result.exists) {
        return result.data();
      } else {
        return null;
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  const createSubCollectionDocument = async (
    collectionID: string,
    docID: any,
    subCollectionID: any,
    docData: any,
  ) => {
    try {
      docData.createdAt = new Date();
      docData.updatedAt = docData.createdAt;
      if (!docData.docID) {
        docData.docID = firestore()
          .collection(collectionID)
          .doc(docID)
          .collection(subCollectionID)
          .doc().id;
      }
      const result = await firestore()
        .collection(collectionID)
        .doc(docID)
        .collection(subCollectionID)
        .doc(docData.docID)
        .set(docData);
      const result2 = await getSubCollectionDocumentByID(
        collectionID,
        docID,
        subCollectionID,
        docData.docID,
      );
      return result2;
    } catch (err: any) {
      console.log(err);
    }
  };

  const createDocument = async (collectionID: string, docData: any) => {
    try {
      docData.createdAt = new Date();
      docData.updatedAt = docData.createdAt;
      if (!docData.docID) {
        docData.docID = firestore().collection(collectionID).doc().id;
      }
      const result = await firestore()
        .collection(collectionID)
        .doc(docData.docID)
        .set(docData);
      const result2 = await getDocumentByID(collectionID, docData.docID);
      return result2;
    } catch (err: any) {
      console.log(err);
    }
  };

  const updateDocument = async (collectionID: string, docData: any) => {
    try {
      docData.updatedAt = new Date();
      const result = await firestore()
        .collection(collectionID)
        .doc(docData.docID)
        .update(docData);
      const result2 = await getDocumentByID(collectionID, docData.docID);
      return result2;
    } catch (err: any) {
      console.log(err);
    }
  };

  const queryDocuments = async (
    collectionID: string,
    filters: any,
    orderBy: any,
  ) => {
    try {
      var initialQuery: any = null;
      if (filters && filters.length > 0) {
        initialQuery = firestore()
          .collection(collectionID)
          .where(filters[0].field, filters[0].operator, filters[0].value);
        filters.map((filter: any, index: any) => {
          if (index !== 0) {
            initialQuery = initialQuery.where(
              filter.field,
              filter.operator,
              filter.value,
            );
          }
        });
      } else {
        initialQuery = firestore().collection(collectionID);
      }

      const documentSnapshots = await initialQuery
        .orderBy(orderBy.field, orderBy.sort)
        .limit(5)
        .get();
      let documentData = documentSnapshots.docs.map((document: any) =>
        document.data(),
      );
      let lastVisible = documentData[documentData.length - 1]?.id;
      if (documentData) {
        return {data: documentData, lastVisible: lastVisible};
      } else {
        return null;
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  const querySubCollectionDocuments = async (
    collectionID: string,
    docID: any,
    subCollectionID: any,
    filters: any,
    orderBy: any,
  ) => {
    try {
      var initialQuery: any = null;
      if (filters && filters.length > 0) {
        initialQuery = firestore()
          .collection(collectionID)
          .doc(docID)
          .collection(subCollectionID)
          .where(filters[0].field, filters[0].operator, filters[0].value);
        filters.map((filter: any, index: any) => {
          if (index !== 0) {
            initialQuery = initialQuery.where(
              filter.field,
              filter.operator,
              filter.value,
            );
          }
        });
      } else {
        initialQuery = firestore()
          .collection(collectionID)
          .doc(docID)
          .collection(subCollectionID);
      }

      const documentSnapshots = await initialQuery
        .orderBy(orderBy.field, orderBy.sort)
        .limit(5)
        .get();
      let documentData = documentSnapshots.docs.map((document: any) =>
        document.data(),
      );
      let lastVisible = documentData[documentData.length - 1]?.id;
      if (documentData) {
        return {data: documentData, lastVisible: lastVisible};
      } else {
        return null;
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  const queryMostRecentSubCollectionDocument = async (
    collectionID: string,
    docID: any,
    subCollectionID: any,
    filters: any,
    orderBy: any,
  ) => {
    try {
      var initialQuery = firestore()
        .collection(collectionID)
        .doc(docID)
        .collection(subCollectionID)
        .where(filters[0].field, filters[0].operator, filters[0].value);
      filters.map((filter: any, index: any) => {
        if (index !== 0) {
          initialQuery = initialQuery.where(
            filter.field,
            filter.operator,
            filter.value,
          );
        }
      });
      const documentSnapshots = await initialQuery
        .orderBy(orderBy.field, orderBy.sort)
        .limit(1)
        .get();
      let documentData = documentSnapshots.docs.map((document: any) =>
        document.data(),
      );
      let lastVisible = documentData[documentData.length - 1]?.id;
      if (documentData) {
        return {data: documentData, lastVisible: lastVisible};
      } else {
        return null;
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  const queryMoreDocuments = async (
    collectionID: string,
    filters: any,
    orderBy: any,
    lastVisible: any,
  ) => {
    try {
      var initialQuery = firestore()
        .collection(collectionID)
        .where(filters[0].field, filters[0].operator, filters[0].value);
      filters.map((filter: any, index: any) => {
        if (index !== 0) {
          initialQuery = initialQuery.where(
            filter.field,
            filter.operator,
            filter.value,
          );
        }
      });
      const documentSnapshots = await initialQuery
        .orderBy(orderBy.field, orderBy.sort)
        .limit(5)
        .startAfter(lastVisible)
        .get();
      let documentData = documentSnapshots.docs.map((document: any) =>
        document.data(),
      );
      let newLastVisible = documentData[documentData.length - 1].id;
      if (documentData) {
        return {data: documentData, lastVisible: newLastVisible};
      } else {
        return null;
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  const queryMoreSubCollectionDocuments = async (
    collectionID: string,
    docID: any,
    subCollectionID: any,
    filters: any,
    orderBy: any,
    lastVisible: any,
  ) => {
    try {
      var initialQuery = firestore()
        .collection(collectionID)
        .doc(docID)
        .collection(subCollectionID)
        .where(filters[0].field, filters[0].operator, filters[0].value);
      filters.map((filter: any, index: any) => {
        if (index !== 0) {
          initialQuery = initialQuery.where(
            filter.field,
            filter.operator,
            filter.value,
          );
        }
      });
      const documentSnapshots = await initialQuery
        .orderBy(orderBy.field, orderBy.sort)
        .limit(5)
        .startAfter(lastVisible)
        .get();
      let documentData = documentSnapshots.docs.map((document: any) =>
        document.data(),
      );
      let newLastVisible = documentData[documentData.length - 1].id;
      if (documentData) {
        return {data: documentData, lastVisible: newLastVisible};
      } else {
        return null;
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  const batchDocuments = async (docArray: any) => {
    try {
      var batch = firestore().batch();
      docArray.map((data: any) => {
        if (data.function === 'set') {
          if (!data?.docData?.docID) {
            data.docData.docID = firestore()
              .collection(data.collectionID)
              .doc().id;
          }
          batch.set(
            firestore().collection(data.collectionID).doc(data.docData.docID),
            data.docData,
          );
        } else if (data.function === 'update') {
          batch.update(
            firestore().collection(data.collectionID).doc(data.docData.docID),
            data.docData,
          );
        } else if (data.function === 'delete') {
          batch.delete(
            firestore().collection(data.collectionID).doc(data.docData.docID),
          );
        }
      });
      const result = batch.commit();
      return 'success';
    } catch (err: any) {
      console.log(err);
    }
  };

  const batchSubCollectionDocuments = async (docArray: any) => {
    try {
      var batch = firestore().batch();
      docArray.map((data: any) => {
        if (data.function === 'set') {
          if (!data?.docData?.docID) {
            data.docData.docID = firestore()
              .collection(data.collectionID)
              .doc(data.docID)
              .collection(data.subCollectionID)
              .doc().id;
          }
          batch.set(
            firestore()
              .collection(data.collectionID)
              .doc(data.docID)
              .collection(data.subCollectionID)
              .doc(data.docData.docID),
            data.docData,
          );
        } else if (data.function === 'update') {
          batch.update(
            firestore()
              .collection(data.collectionID)
              .doc(data.docID)
              .collection(data.subCollectionID)
              .doc(data.docData.docID),
            data.docData,
          );
        } else if (data.function === 'delete') {
          batch.delete(
            firestore()
              .collection(data.collectionID)
              .doc(data.docID)
              .collection(data.subCollectionID)
              .doc(data.docData.docID),
          );
        }
      });
      const result = batch.commit();
      return 'success';
    } catch (err: any) {
      console.log(err);
    }
  };

  const deleteDocumentByID = async (collectionID: string, docID: string) => {
    try {
      const result = await firestore()
        .collection(collectionID)
        .doc(docID)
        .delete();
      const result2 = await firestore()
        .collection(collectionID)
        .doc(docID)
        .get();
      if (!result2.exists) {
        return 'success';
      } else {
        return result2.data();
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  //------------------------------------------------------------------------------------------------------
  return (
    <FirestoreContext.Provider
      value={{
        updateCurrentUserProfile,
        currentUserProfile,
        getCurrentUserProfile,
        updateGameSession,
        accountSetupNeeded,
        queryDocuments,
        createDocument,
        queryMoreDocuments,
        getDocumentByID,
        querySubCollectionDocuments,
        createSubCollectionDocument,
        queryMoreSubCollectionDocuments,
        batchSubCollectionDocuments,
        queryMostRecentSubCollectionDocument,
        batchDocuments,
        validateUsername,
        validateNickname
      }}>
      {params.children}
    </FirestoreContext.Provider>
  );
};

export const Firestore = () => useContext(FirestoreContext);


// import React, { createContext, useState, useContext, useEffect, useReducer } from 'react';
// import firebase, { auth, firestore, storage } from "../../firebase.config";
// import {Auth} from './AuthProvider'
// // import {View, Animated, Easing} from 'react-native' 
// import {standardsquarelogo} from '../assets/images'
// import {Loading} from '../components/Loading/Loading'
// import axios from 'axios'

// type DataContextProps = {
//    updateCurrentUserProfile:any;
//     currentUserProfile:any;
//     getCurrentUserProfile:any;
//     accountSetupNeeded:any;
//     dataPending:any;
//     queryDocuments:any
//     createDocument:any;
//     getDocumentByID:any
//     validateUsername:any
//     validateNickname:any;
// }

// const DataContext = createContext<DataContextProps>({
//   updateCurrentUserProfile:null,
//   currentUserProfile:null,
//   getCurrentUserProfile:null,
//   accountSetupNeeded:null,
//   dataPending:null,
//   queryDocuments:null,
//   createDocument:null,
//   getDocumentByID:null,
//   validateUsername:null,
//   validateNickname:null

// })

// export const FirestoreProvider = (params:any) => {
//   const {currentUser} = Auth();
//   const [currentUserProfile, setCurrentUserProfile] = useState<any | null>(null);
//   const [dataPending, setDataPending] = useState<boolean | null>(true);
//   const [accountSetupNeeded, setAccountSetupNeeded] = useState<any | null>(false);


//   useEffect(()=>{
//     if(currentUser){
//       getCurrentUserProfile()
//     }
//     else{
//       setCurrentUserProfile(null)
//       setDataPending(false)
//     }
//   },[currentUser])


//   useEffect(()=>{
//     if(currentUser && currentUserProfile){
//       checkAccountSetup()
//     }
//     else if(currentUser && !currentUserProfile){
//       getCurrentUserProfile()
//     }
//   },[currentUser, currentUserProfile])

  

//   const checkAccountSetup = () => {
//     if(!currentUserProfile?.username || !currentUserProfile?.nickname || !currentUserProfile?.birthdate){
//       setAccountSetupNeeded(true)
//       setDataPending(false)
//     }
//     else{
//       setAccountSetupNeeded(false)
//       setDataPending(false)
//     }
//   }



//   const getCurrentUserProfile = async () => {
//     try {
//       const result = await getDocumentByID('userProfiles',currentUser.uid);
//       if(result){
//         setCurrentUserProfile(result)
//       }
//       else{
//         const result2 = await createDocument('userProfiles', {docID:currentUser.uid, email:currentUser.email, terms:true})
//         setCurrentUserProfile(result2)
//       }
//     }
//     catch(err:any) {
//       console.log(err)
//     }
//   }





//   const updateCurrentUserProfile = async (updatedData:any) =>{
//       try{
//         const result = await updateDocument("userProfiles", updatedData)


//         if(result && updatedData.nickname){
//           var authCurrentUser = auth().currentUser
//           if(authCurrentUser){
//            var displayNameResult = await authCurrentUser.updateProfile({displayName:updatedData?.nickname})
//           }
//         }

//         if(result && updatedData.photoURL){
//           var authCurrentUser = auth().currentUser
//           if(authCurrentUser){
//            var photoURLResult = await authCurrentUser.updateProfile({photoURL:updatedData?.photoURL})
//           }
//         }

//         if(result){
//           console.log(result)
//           getCurrentUserProfile()
//         }

//         return result
//       }
//       catch(err:any){
//         console.log(err)
//       }
//   }









// // API SETUP NEEDED
// //---------------------------------------------------------------------------------------------------------------------



//   const getDocumentByID = async (collectionID:string, docID:any) => {
//     try{
//       const result = await firestore().collection(collectionID).doc(docID).get()
//       if(result.exists){
//         return result.data();
//       }
//       else{
//         return null
//       }
//     }
//     catch(err:any){
//       console.log(err)
//     }
//   }

  


//   const createDocument = async (collectionID:string, docData:any) => {
//     try{
//       docData.createdAt = new Date()
//       docData.updatedAt = docData.createdAt
//       if(!docData.docID){
//         docData.docID = firestore().collection(collectionID).doc().id;
//       }
//       const result = await firestore().collection(collectionID).doc(docData.docID).set(docData)
//       const result2 = await getDocumentByID(collectionID, docData.docID)
//       return result2
//     }
//     catch(err:any){
//       console.log(err)
//     }
//   }


//   const updateDocument =  async(collectionID:string, docData:any) => {
//     try{
//       docData.updatedAt = new Date()
//       const result = await firestore().collection(collectionID).doc(docData.docID).update(docData)
//       const result2 = await getDocumentByID(collectionID, docData.docID)
//       return result2
//     }
//     catch(err:any){
//       console.log(err)
//     }
//   }


//   const queryDocuments = async (collectionID:string, filters:any, orderBy:any) =>{
//     try{
//       var initialQuery = firestore().collection(collectionID).where(filters[0].field, filters[0].operator, filters[0].value)
//       filters.map((filter:any, index:any)=>{
//         if(index !== 0){
//           initialQuery = initialQuery.where(filter.field, filter.operator, filter.value)
//         }
//       })
//       const documentSnapshots =  await initialQuery.orderBy(orderBy.field, orderBy.sort).limit(5).get()
//       let documentData = documentSnapshots.docs.map((document:any) => document.data());
//       let lastVisible = documentData[documentData.length - 1]?.docID;
//       if(documentData){
//         return {data:documentData, lastVisible:lastVisible}
//       }
//       else{
//         return null
//       }
//     }
//     catch(err:any){
//       console.log(err)
//     }
//   }

  


  
  



// //---------------------------------------------------------------------------------------------------------------------






//   if (dataPending) {
//     return <Loading/>;
//   }


//   return (
//     <DataContext.Provider
//       value={{
//         updateCurrentUserProfile,
//         currentUserProfile,
//         getCurrentUserProfile,
//         accountSetupNeeded,
//         dataPending,
//         queryDocuments,
//         createDocument,
//         getDocumentByID,
//         validateUsername,
//         validateNickname
//       }}
//     >
//       {params.children}
//     </DataContext.Provider>
//   );
// };


// export const Data = () => useContext(DataContext);

import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import {
  AuthProvider,
  Auth,
  FirestoreProvider,
  StorageProvider,
  Storage,
  DimensionsProvider,
} from "./contexts/index";
import { Navbar, Navmenu, Toast, Page, View, Text } from "./components/index";
import { ThemeProvider } from "styled-components";
import { darkTheme, lightTheme } from "./styles/theme";
import { GlobalStyles } from "./styles/global";
import {
  Home,
  Login,
  Signup,
  GetStarted,
  TermsConditions,
  PrivacyPolicy,
  NotFound,
  Dashboard,
  Settings,
  AccountSetup
} from "./pages/index";

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  const { currentUser } = Auth();
  return (
    <Route
      {...rest}
      render={(routeProps) =>
        currentUser ? <RouteComponent {...routeProps} /> : <Redirect to={"/login"} />
      }
    />
  );
};

const PublicRoute = ({ component: RouteComponent, ...rest }) => {
  const { currentUser } = Auth();
  return (
    <Route
      {...rest}
      render={(routeProps) =>
        currentUser ? (
          <Redirect to={"/dashboard"} />
        ) : (
          <RouteComponent {...routeProps} />
        )
      }
    />
  );
};

const App = (props: any) => {
  const [showMenu, setShowMenu] = useState(false);
  const [pageTitle, setPageTitle] = useState(null);
  const location = useLocation();
  const [theme, setTheme] = useState(darkTheme);
  const [maintenanceMode, setMaintenanceMode] = useState(false);

  // const updateTheme = () => {
  //   setTheme(lightTheme);
  // }

  const pages = {
    "/": "CRAWLR",
    "/getstarted": "Get Started",
    "/terms": "Terms",
    "/privacy": "Privacy Policy",
    "/login": "Log In",
    "/signup": "Sign Up",
    "/dashboard": "Dashboard",
    "/settings": "Settings",
    "/action": "Action",
    "/accountsetup":"Account Setup"
  };

  useEffect(() => {
    if (pages[location.pathname]) {
      setPageTitle(pages[location.pathname]);
    } else {
      setPageTitle("404");
    }
    setShowMenu(false);
  }, []);

  useEffect(() => {
    if (pages[location.pathname]) {
      setPageTitle(pages[location.pathname]);
    } else {
      setPageTitle("Page Not Found");
    }
    setShowMenu(false);
  }, [location.pathname]);

  return (
    <>
      {maintenanceMode ? (
        <ThemeProvider theme={theme}>
          <Page pageTitle="Maintenance | CRAWLR" pageDescription="Maintenance">
            <Text
              fontFamily="Brandon Grotesque"
              fontWeight="700"
              padding="3vh 0 3vh 0"
              textAlign="center"
              fontSize="3rem"
              color="background"
            >
              CRAWLR is undergoing maintenance...
            </Text>
            <Text
              fontFamily="Brandon Grotesque"
              fontWeight="700"
              padding="3vh 0 3vh 0"
              textAlign="center"
              fontSize="2rem"
              color="background"
            >
              Apologies for the inconvenience.
            </Text>
          </Page>
        </ThemeProvider>
      ) : (
        <AuthProvider>
          <FirestoreProvider>
            <StorageProvider>
              <ThemeProvider theme={theme}>
                <GlobalStyles />
                <DimensionsProvider>
                  <Navbar
                    setShowMenu={setShowMenu}
                    showMenu={showMenu}
                    pageTitle={pageTitle}
                  />
                  <Navmenu setShowMenu={setShowMenu} showMenu={showMenu} />
                  <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/privacy" component={PrivacyPolicy} />
                    <Route exact path="/terms" component={TermsConditions} />
                    <PublicRoute exact path="/getstarted" component={GetStarted} />
                    <PublicRoute exact path="/login" component={Login} />
                    <PublicRoute exact path="/signup" component={Signup} />
                    <PrivateRoute
                      exact
                      path="/dashboard"
                      component={Dashboard}
                    />
                    <PrivateRoute exact path="/settings" component={Settings} />
                    <PrivateRoute exact path="/accountsetup" component={AccountSetup} />
                    <Route component={NotFound} />
                  </Switch>
                </DimensionsProvider>
              </ThemeProvider>
            </StorageProvider>
          </FirestoreProvider>
        </AuthProvider>
      )}
    </>
  );
};

export default App;

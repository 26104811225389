import {
    Discord,
    Facebook,
    Instagram,
    Reddit,
    Tiktok,
    Twitch,
    Twitter,
  } from "@styled-icons/fa-brands";
  import moment from "moment";
  import React, { useEffect, useState, useReducer } from "react";
  import { useHistory } from "react-router-dom";
  import { rocket } from "../assets/images";
  import { Button } from "../components/Button";
  import { Card } from "../components/Card";
  import { Checkbox } from "../components/Checkbox";
  import { Form } from "../components/Form";
  import { Icon } from "../components/Icon";
  import { Image } from "../components/Image";
  import { Link } from "../components/Link";
  import { Page } from "../components/Page";
  import { Text } from "../components/Text";
  import { TextInput } from "../components/TextInput";
  import { DateInput } from "../components/DateInput";

  import { Toast } from "../components/Toast";
  import { View } from "../components/View";
  import { Dimensions } from "../contexts";
  import { Auth } from "../contexts/AuthProvider";
  import { Firestore } from "../contexts/FirestoreProvider";
  import { validateBirthdate } from "../helpers/Validation";
  
  export function AccountSetup(props: any) {
    const {
      currentUser,
      updateEmail,
      sendEmailVerification,
      error,
      success,
      setSuccess,
    } = Auth();
    const {
      accountSetupNeeded,
      currentUserProfile,
      updateCurrentUserProfile,
      validateUsername,
      validateNickname,
      getCurrentUserProfile,createSubCollectionDocument
    } = Firestore();
    const { width } = Dimensions();
    const history = useHistory();
    const [newUserProfile, setNewUserProfile] = useReducer(
      (state: any, newState: any) => ({...state, ...newState}),
      {
        docID: currentUser?.uid,
        email: currentUser?.email || null,
        username: currentUserProfile?.username || null,
        nickname: currentUserProfile?.nickname || null,
        birthdate: currentUserProfile?.birthdate || null,
        terms: currentUserProfile?.terms || true,
      }
    );

    const [showSuccess, setShowSuccess] = useState<boolean | null>(false);
    const [validBirthdate, setValidBirthdate] = useState({valid: false,
      message: null,
    });
    const [validUsername, setValidUsername] = useState({
      valid: false,
      message: null,
    });
    const [validNickname, setValidNickname] = useState({
      valid: false,
      message: null,
    });
    const [validNewProfile, setValidNewProfile] = useState<boolean>(true);
    const [formattedBirthdate, setFormattedBirthdate] = useState(null);
  

    const [actionText, setActionText] = useState("Send Verification Email");
  

  
    const closeSuccess = () => {
      setShowSuccess(false);
      setSuccess(null);
    };
  

    useEffect(() => {
      if (!accountSetupNeeded) {
        history.push("/dashboard");
      }
    }, [accountSetupNeeded]);
  

  
    useEffect(() => {
      if (
        success &&
        success.code === "auth/email-sent" &&
        actionText === "Send Verification Email"
      ) {
        setActionText("Resend Verification Email");
      }
    }, [success]);
  

    useEffect(() => {
      if (newUserProfile) {
        var profileValues = Object.values(newUserProfile);
        var nullExists = profileValues.includes(null);
        if (nullExists){
          setValidNewProfile(false);
        } else {
          setValidNewProfile(true);
          validationOfUsername(newUserProfile.username)
          validationOfNickname(newUserProfile.nickname)
          validationOfBirthdate(newUserProfile.birthdate)
        }
      }
    }, [newUserProfile]);
  

    const validationOfUsername = async (username:any) =>{
      try{
        if(!currentUserProfile.username){
          const result = await validateUsername(username)
          if(result){
            setValidUsername({message: result.message, valid: result.valid});
          }
        }
        else{
          setValidUsername({message: '', valid: true});
        }
      }
      catch(err:any){
        console.log(err)
      }
    }
  
    const validationOfNickname = async (nickname:any) =>{
      try{
        const result = await validateNickname(nickname)
        if(result){
          setValidNickname({message: result.message, valid: result.valid});
        }
      }
      catch(err:any){
        console.log(err)
      }
    }
  
  
    const validationOfBirthdate = (birthdate:any) =>{
      const yearDifference = moment().diff(
        moment(birthdate, 'YYYYMMDD'),
        'years',
      );
      if(yearDifference >= 18) {
        setValidBirthdate({message: 'Looking good!', valid: true});
      } else {
        setValidBirthdate({
          message: 'You must be at least 18 years old.',
          valid: false,
        });
      }
    }



    const update = async () =>{
      try{
        const result = await updateCurrentUserProfile(newUserProfile)
        if(result){
          getCurrentUserProfile()
        }
      }
      catch(err:any){
        console.log(err)
      }
    }
  
    return (
      <Page pageTitle="Dashboard | CRAWLR" pageDescription="Dashboard">
        {success ? (
          <Toast type="success" background="success">
            {success.message}
          </Toast>
        ) : null}
        {error ? (
          <Toast type="error" background="error">
            {error.message}
          </Toast>
        ) : null}
        {!currentUser.emailVerified ? (
          <Toast
            type="Notification"
            actionText={actionText}
            close={false}
            action={true}
            onAction={() => sendEmailVerification()}
            background="primary"
          >
            Email Verification Required
          </Toast>
        ) : null}


          <View>
            <View
              margin="3vh 0 0 0"
              flexFlow="column wrap"
              justifyContent="center"
            >
              <Text
                padding="3vh 0 3vh 0"
                textAlign="center"
                fontSize="1.5rem"
                color="body"
              >
                Please Complete Your Profile
              </Text>
              <Card
                background="white"
                minWidth="285px"
                width="20%"
                margin="0 auto"
              >
                <Form
                  onSubmit={(e: any) => {
                    e.preventDefault();
                 update()
                  }}
                >

                  <TextInput
                    type="email"
                    placeholder="Email Address"
                    value={newUserProfile.email || ""}
                    onChange={(e) =>  setNewUserProfile({['email']: e.target.value})}
                    required
                    validation={null}
                    disabled={currentUser.email}
                  />
                  <TextInput
                    onClear={() => {
                      setNewUserProfile({['username']: null});
                      setValidUsername({ valid: null, message: null });
                    }}
                    type="text"
                    placeholder="Username"
                    value={newUserProfile.username || ""}
                    onChange={(e) =>  setNewUserProfile({['username']: e.target.value})}
                    required
                    validation={validUsername}
                    disabled={!currentUser.emailVerified || currentUserProfile.username}
                  />
  
                  <TextInput
                    required
                    onClear={() => {
                      setNewUserProfile({['nickname']: null})
                      setValidNickname({ valid: null, message: null });
                    }}
                    validation={validNickname}
                    type="text"
                    placeholder="Nickname"
                    value={newUserProfile.nickname || ""}
                    onChange={(e) => setNewUserProfile({['nickname']: e.target.value})}
                    disabled={!currentUser.emailVerified}
                  />
  
                  <DateInput
                    type="date"
                    placeholder="Birthdate"
                    value={newUserProfile.birthdate}
                    onChange={(e)=>setNewUserProfile({['birthdate']: e})}
                    required
                    validation={validBirthdate}
                    disabled={!currentUser.emailVerified || currentUserProfile.birthdate}
                  />
  
                  <Button
                    size="large"
                    buttonType="fill"
                    type="submit"
                    onClick={() => {}}
                    disabled={
                      !validNewProfile
                      || !validUsername.valid
                      || !validNickname.valid
                      || !validBirthdate.valid
                      || !currentUser.emailVerified
                    }
                    background="primary"
                  >
                    Submit
                  </Button>
                </Form>
              </Card>
            </View>
          </View>
      </Page>
    );
  }
// src/components/Navbar/index.tsx
import React, { useState, useContext } from "react";
import { View } from "./View";
import { Text } from "./Text";
import { Image } from "./Image";
import { Link } from "./Link";
import { Dimensions } from "../contexts";

interface FooterProps {}

export const Footer: React.FC<FooterProps> = (props) => {
  const { laptop, mobile, tablet, desktop } = Dimensions();

  return (
    <View
      padding="1vh 0 1vh 0"
      background="background"
      borderWidth="5px 0 0 0"
      borderColor="primaryLight"
      flexFlow={mobile ? "column wrap" : "row wrap"}
      justifyContent="space-around"
    >
      <Link textAlign="center" linkType="text" href="/terms" color="body">
        Terms & Conditions
      </Link>
      <Link textAlign="center" linkType="text" href="/privacy" color="body">
        Privacy Policy
      </Link>
      <Link textAlign="center" color="body" href="/" linkType="text">
        &copy; CRAWLR 2019 - 2021
      </Link>
    </View>
  );
};

// src/components/Navbar/index.tsx
import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { View } from "./View";

type ImageType = {
  src?: any;
  width?: string;
  height?: string;
  minWidth?: string;
  alt?: string;
  maxWidth?: string;
  onClick?: () => void;
  to?: string;
  borderRadius?: string;
  padding?: string;
  click?: any;
};

const ImageContainer = styled.div<ImageType>`
  cursor: ${(props) => (props.click ? "pointer" : null)};
  margin: 0;
  padding: 0;
  text-align: center;
  padding: ${(props) => props.padding || null};
`;

const ImageContent = styled.img<ImageType>`
  height: ${(props) => (props.height ? props.height : "auto")};
  width: ${(props) => (props.width ? props.width : "auto")};
  min-width: ${(props) => props.minWidth || null};
  max-width: ${(props) => props.maxWidth || null};
  border-radius: ${(props) => props.borderRadius || null};
  text-align: center;
  margin: 0;
  padding: 0;
`;

interface ImageProps {
  src: any;
  width?: string;
  minWidth?: string;
  alt: string;
  maxWidth?: string;
  onClick?: () => void;
  to?: string;
  padding?: string;
  height?: string;
  borderRadius?: string;
  onError?: (e: any) => void;
}

export const Image: React.FC<ImageProps> = (props) => {
  const history = useHistory();

  const checkClickType = () => {
    if (props.to && props.to.includes("http")) {
      return (window.location.href = props.to);
    } else if (props.to && !props.to.includes("http")) {
      return history.push(props.to);
    } else if (props.onClick) {
      return props.onClick();
    } else {
      return null;
    }
  };

  return (
    <ImageContainer
      click={props.to || props.onClick ? true : false}
      onClick={checkClickType}
      padding={props.padding}
    >
      <ImageContent
        onError={props.onError}
        borderRadius={props.borderRadius}
        height={props.height}
        width={props.width}
        src={props.src}
        alt={props.alt}
        minWidth={props.minWidth}
        maxWidth={props.maxWidth}
      />
    </ImageContainer>
  );
};

import { Spinner } from "@styled-icons/fa-solid";
import React from "react";
import styled from "styled-components";

interface ButtonProps {
  color?: string;
  size: string;
  buttonType: string;
  href?: string;
  icon?: boolean;
  background?: string;
  borderColor?: string;
  loading?: boolean;
  disabled?: boolean;
  type?: any;
  onClick?: () => void;
  flex?: string;
  padding?: string;
  margin?: string;
}

const ButtonItem = styled.button<ButtonProps>`
  outline: none;
  cursor: pointer;
  font-weight: ${(props) => props.theme.buttons.fontWeight};
  min-height: ${(props) => props.theme.buttons.minHeight};
  text-transform: ${(props) =>
    props.buttonType !== "text" ? props.theme.buttons.textTransform : null};
  font-family: ${(props) =>
    props.buttonType !== "text" ? props.theme.buttons.fontFamily : "Poppins"};
  font-size: 1rem;
  border-radius: ${(props) =>
    props.theme.buttons[props.buttonType].borderRadius};
  min-width: ${(props) => props.theme.buttons[props.size].minWidth};
  padding: ${(props) =>
    props.padding || props.theme.buttons[props.buttonType].padding};
  margin: ${(props) =>
    props.margin || props.theme.buttons[props.buttonType].margin};

  border: ${(props) => (!props.borderColor ? "none" : null)};
  border: ${(props) =>
    props.borderColor &&
    props.borderColor !== "body" &&
    props.borderColor !== "background"
      ? `2px solid ${props.theme.standardColors[props.borderColor]}`
      : null};
  border: ${(props) =>
    props.borderColor &&
    (props.borderColor === "body" || props.borderColor === "background")
      ? `2px solid ${props.theme.themeColors[props.borderColor]}`
      : null};

  background: ${(props) => (!props.background ? "transparent" : null)};
  background: ${(props) =>
    props.background &&
    props.background !== "body" &&
    props.background !== "background"
      ? props.theme.standardColors[props.background]
      : null};
  background: ${(props) =>
    props.background &&
    (props.background === "body" || props.background === "background")
      ? props.theme.themeColors[props.background]
      : null};

  color: ${(props) => (!props.color ? props.theme.standardColors.white : null)};
  color: ${(props) =>
    props.color && props.color !== "body" && props.color !== "background"
      ? props.theme.standardColors[props.color]
      : null};
  color: ${(props) =>
    props.color && (props.color === "body" || props.color === "background")
      ? props.theme.themeColors[props.color]
      : null};
  text-align: center;

  i {
    display: inline-flex;
    margin: 0 15px 0 15px;
  }

  :disabled {
    filter: grayscale(20%) opacity(50%);
    cursor: not-allowed;
  }

  :hover:enabled {
    filter: brightness(80%);
  }

  svg {
    color: ${(props) => props.color || "white"};
    width: 1rem;
    height: 1rem;
    text-align: center;
    margin: 0 auto;
  }
`;

interface ButtonProps {
  color?: string;
  size: string;
  buttonType: string;
  href?: string;
  icon?: boolean;
  background?: string;
  borderColor?: string;
  loading?: boolean;
  disabled?: boolean;
  type?: any;
  onClick?: () => void;
}

export const Button: React.FC<ButtonProps> = ({
  loading,
  children,
  color,
  ...rest
}) => {
  return (
    <ButtonItem color={color} {...rest}>
      {loading ? <Spinner /> : children}
    </ButtonItem>
  );
};

// src/components/Navbar/index.tsx
import React, { useContext } from "react";
import styled from "styled-components";
import { View } from "./View";

const ToggleContainer = styled.div`
  display: flex;
  justify-content: ${(props) => (props.slot ? props.slot : null)};
  height: 35px;
  width: 70px;
  border-radius: 20px;
  background: ${(props) => (props.color ? props.color : null)};
  border: ${(props) => (props.color ? `3px solid ${props.color}` : null)};
  margin: 0 0 0 0;
`;

const ToggleFalse = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  background: white;
  height: 35px;
  width: 35px;
  border-radius: 20px;
`;

const ToggleTrue = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  background: white;
  height: 35px;
  width: 35px;
  border-radius: 20px;
`;

const ToggleFalseTheme = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  background: transparent;
  height: 30px;
  width: 30px;
  border-radius: 20px;
`;

const ToggleTrueTheme = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  background: transparent;
  height: 30px;
  width: 30px;
  border-radius: 20px;
`;

interface ToggleProps {
  color?: string;
  mode: boolean;
  onClick: () => void;
  theme?: string;
}

export const Toggle: React.FC<ToggleProps> = (props) => {
  const position: any = {
    true: "flex-end",
    false: "flex-start",
  };

  const positionType: string = "" + props.mode;

  return (
    <div style={{ padding: "10px 0 10px 0" }}>
      {/* <ToggleContainer
        color={"primaryDark"LightFlip[theme]}
        slot={position[positionType]}
        onClick={props.onClick}>
        {props.theme && props.theme === 'theme' ? (
          <>
            {!props.mode ? (
              <ToggleFalseTheme>
                <Icon icon="sun" size={25} color={colors.warning} />
              </ToggleFalseTheme>
            ) : (
              <ToggleTrueTheme>
                <Icon icon="moon" size={25} color="white" />
              </ToggleTrueTheme>
            )}
          </>
        ) : (
          <>{!props.mode ? <ToggleFalse /> : <ToggleTrue />}</>
        )}
      </ToggleContainer> */}
    </div>
  );
};

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

//CRAWLR-APP-DEVELOPMENT
const devConfig = {
  apiKey: "AIzaSyB2IoK_znYUEnEVGIPGiocLap4ERwC1R6Q",
  authDomain: "crawlr-app-development.firebaseapp.com",
  projectId: "crawlr-app-development",
  storageBucket: "crawlr-app-development.appspot.com",
  messagingSenderId: "119558937029",
  appId: "1:119558937029:web:6b074c177602cc95fb13ac",
  measurementId: "G-B6E4SM9TMG"
};

//CRAWLR-APP
const prodConfig = {
  apiKey: "AIzaSyAIeJCl2sSsH__IPibLKYriGj_Wrxun9jU",
  authDomain: "crawlr-app.firebaseapp.com",
  projectId: "crawlr-app",
  storageBucket: "crawlr-app.appspot.com",
  messagingSenderId: "239748493011",
  appId: "1:239748493011:web:f182fdec6a2df6c7da6e26",
  measurementId: "G-XNL1C5HEJC"
};


var config = prodConfig;


if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

export const auth = firebase.auth;
export const firestore = firebase.firestore;
export const storage = firebase.storage;
export const googleProvider = new firebase.auth.GoogleAuthProvider();

export default firebase;

//1/3/22

//firebase auth:export users.json --project crawlr-app

// hash_config {
//   algorithm: SCRYPT,
//   base64_signer_key: PhfWY157zO44Fclsg1/G8o1qolztQckb0RKkDbendVDgKfdTzf3H7GgSJXU0gFuJ4TbhywckmqRb53Fr5QRVPg==,
//   base64_salt_separator: Bw==,
//   rounds: 8,
//   mem_cost: 14,
// }
// src/components/Navbar/index.tsx
import React, { useState, useContext, useEffect } from "react";
import { View } from "./View";
import { Text } from "./Text";
import { Image } from "./Image";
import { Icon } from "./Icon";
import { Link } from "./Link";
import { Auth } from "../contexts/AuthProvider";
import { Firestore } from "../contexts/FirestoreProvider";

import { Dimensions } from "../contexts";
import { useLocation } from "react-router-dom";
import { Bars, UserCircle, Times } from "@styled-icons/fa-solid";
import { logo } from "../assets/images";
import { ScrollTo } from "../helpers/ScrollTo";
import "../styles/nav-menu.css";

interface NavbarProps {
  pageTitle?: string;
  setShowMenu?: any;
  showMenu?: boolean;
  className?: string;
}

export const Navmenu: React.FC<NavbarProps> = (props: NavbarProps) => {
  const { currentUser, logout } = Auth();
  const { currentUserProfile } = Firestore();
  const { mobile, laptop, desktop, tablet } = Dimensions();
  const location = useLocation();

  const toggleMenu = () => {
    props.setShowMenu(!props.showMenu);
  };
  const handleClick = (props) => {
    ScrollTo(props);
  };

  return (
    <>
    {!props.showMenu ? null :
    <View
      zIndex={10000000000}
      position="fixed"
      top="75px"
      right="0"
      borderColor="primaryLight"
      borderWidth="0 0 5px 0"
      height="100%"
      id="flyoutMenu"
      className={props.showMenu ? "show" : "hide"}
    >
      <View id="1" flex="1" flexFlow="row nowrap" padding="0 0 75px 0">
        {laptop || desktop ? (
          <View flex="3" height="100%" onClick={toggleMenu}></View>
        ) : null}

        {tablet ? (
          <View flex="1" height="100%" onClick={toggleMenu}></View>
        ) : null}

        <View
          borderColor="primaryLight"
          borderWidth="0 0 0 5px"
          flex="1"
          background="background"
          height="100%"
          width="300px"
        >
          <View justifyContent="flex-start" width="300px">
            {!currentUser && location.pathname !== "/login" ? (
              <Link
                textAlign="center"
                href="/login"
                linkType="text"
                color="body"
              >
                Log In
              </Link>
            ) : null}
            {!currentUser && location.pathname !== "/signup" ? (
              <Link
                textAlign="center"
                href="/signup"
                linkType="text"
                color="body"
              >
                Sign Up
              </Link>
            ) : null}

            {currentUser ? (
              <Link textAlign="center" href="" linkType="text" color="body">
                {" "}
                <View flexFlow="row wrap" justifyContent="center">
                  {currentUserProfile.photoURL ? (
                    <Image
                      padding="2vh 2vw 0 2vw"
                      borderRadius="50%"
                      src={currentUserProfile.photoURL}
                      width="100px"
                      height="100px"
                      alt="logo"
                    />
                  ) : (
                    <Icon padding="2vh 2vw 0 2vw" size="100px" color="body">
                      <UserCircle />
                    </Icon>
                  )}
                </View>{" "}
              </Link>
            ) : null}

            {currentUser ? (
              <Text
                padding="0vh 0 1vh 0"
                fontSize="1.5rem"
                textAlign="center"
                fontWeight="700"
                color="body"
              >
                {currentUserProfile?.nickname}{" "}
              </Text>
            ) : null}

            {currentUser ? (
              <Link
                textAlign="center"
                href="/dashboard"
                linkType="text"
                color="body"
              >
                {" "}
                Dashboard{" "}
              </Link>
            ) : null}
            {location.pathname === "/" ? (
              <Link
                linkType="text"
                textAlign="center"
                color="body"
                onClick={() => handleClick("about")}
              >
                About
              </Link>
            ) : null}
            {location.pathname === "/" ? (
              <Link
                textAlign="center"
                onClick={() => handleClick("team")}
                linkType="text"
                color="body"
              >
                Team
              </Link>
            ) : null}
                {location.pathname === "/" ? (
              <Link
                textAlign="center"
                onClick={() => handleClick("donate")}
                linkType="text"
                color="body"
              >
                Donate
              </Link>
            ) : null}
            {location.pathname === "/" ? (
              <Link
                textAlign="center"
                onClick={() => handleClick("contribute")}
                linkType="text"
                color="body"
              >
                Contribute
              </Link>
            ) : null}
            {location.pathname === "/" ? (
              <Link
                textAlign="center"
                onClick={() => handleClick("contact")}
                linkType="text"
                color="body"
              >
                Contact
              </Link>
            ) : null}
            {currentUser ? (
              <Link
                textAlign="center"
                href="/settings"
                linkType="text"
                color="body"
              >
                {" "}
                Settings
              </Link>
            ) : null}
            {currentUser ? (
              <Link
                textAlign="center"
                href=""
                onClick={logout}
                linkType="text"
                color="body"
              >
                Logout
              </Link>
            ) : null}
          </View>
        </View>
      </View>
    </View> }
    </>
  );
};
